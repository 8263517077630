import React from 'react';
import { Box, Grid } from '@chakra-ui/layout';
import DownloadBox from './DownloadBox';
import LoginBox from './LoginBox';
import LogoArtwork from './LogoArtwork';
import MenuBox from './MenuBox';

const MainMenu = () => {
  return (
    <Grid gridArea="navigation" className="main-menu">
      <Box
        id="MenuColumn"
        position="relative"
        width="180px"
        top="160px"
        marginTop="-155px"
        marginRight="15px"
        marginLeft="15px"
        padding={0}
        fontFamily="Arial, sans-serif"
        fontSize="10pt"
        fontWeight="700"
        lineHeight="12pt"
        textAlign="left"
        display="flex"
        flexDirection="column"
      >
        <LogoArtwork />

        <LoginBox />

        <DownloadBox />

        <MenuBox />
      </Box>
    </Grid>
  );
};

export default MainMenu;
