import React from 'react';
import { BoxProps, Text } from '@chakra-ui/react';

interface ErrorProps extends BoxProps {
  message: string;
}

const ErrorMessage = ({ message, ...props }: ErrorProps) => {
  return (
    <Text color="red" fontSize="8pt" {...props}>
      {message}
    </Text>
  );
};

export default ErrorMessage;
