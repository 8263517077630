import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface TableContentContainerProps extends BoxProps {
  children: React.ReactNode;
}

const TableContentContainer = ({ children, ...props }: TableContentContainerProps) => {
  return (
    <Box
      border="1px solid #5f4d41"
      position="relative"
      height="100%"
      backgroundColor="#d4c0a1"
      boxShadow="3px 3px 2px #875f3e"
      {...props}
    >
      <Box>{children}</Box>
    </Box>
  );
};

export default TableContentContainer;
