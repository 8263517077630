import { extendTheme } from '@chakra-ui/react';
import Background from 'src/assets/images/header/background-artwork.webp';
import { checkboxTheme } from './chakra/components/checkbox';
import { radioTheme } from './chakra/components/radio';
import { popoverTheme } from './chakra/components/popover';

export const theme = extendTheme({
  fonts: {
    heading: 'Verdana,Arial,Times New Roman,sans-serif',
    body: 'Verdana,Arial,Times New Roman,sans-serif'
  },
  colors: {
    blue: {
      500: '#0075ff'
    }
  },
  styles: {
    global: {
      body: {
        margin: 0,
        padding: 0,
        backgroundColor: '#051122',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundImage: Background
      },
      a: {
        color: '#004294'
      },
      p: {
        lineHeight: 1.2
      },
      h1: {
        display: 'block',
        fontSize: '2em',
        marginBlockStart: '0.67em',
        marginBlockEnd: '0.67em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
        unicodeBidi: 'isolate'
      }
    }
  },
  components: {
    Link: {
      baseStyle: {
        color: '#004294',
        fontWeight: '600'
      }
    },
    Checkbox: checkboxTheme,
    Radio: radioTheme,
    Popover: popoverTheme,
    Table: {
      variants: {
        simple: {
          table: {
            borderSpacing: '2px',
            border: '1px solid #faf0d7'
          },
          td: {
            border: 'none',
            padding: '2px 5px',
            fontSize: '10pt',
            lineHeight: 'normal'
          }
        }
      }
    }
  }
});
