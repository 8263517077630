import React from 'react';
import { Box, Grid } from '@chakra-ui/react';
import headerBG from 'src/assets/images/header/mobile/header-background.png';

const MainHeader = () => {
  return (
    <Grid
      gridArea="header"
      position="relative"
      height="155px"
      visibility="hidden"
      className="main-header"
    >
      <Box
        as="header"
        top="0"
        display="none"
        height="70px"
        marginTop={0}
        color="white"
        pos="fixed"
        width="100%"
        fontSize="11pt"
        zIndex={1000}
        lineHeight="11pt"
      >
        <Box
          as="nav"
          minHeight="70px"
          backgroundRepeat="no-repeat"
          backgroundSize="100% 70px"
          bgImage={headerBG}
        />
      </Box>
    </Grid>
  );
};

export default MainHeader;
