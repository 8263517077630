/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Image, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-premiumfeatures.gif';
import Button from 'src/components/Button';
import TableContainer from 'src/components/TableContainer/TableContainer';
import Stamina from 'src/assets/images/premiumfeatures/PremiumIcon-Stamina.png';
import Trainingstatues from 'src/assets/images/premiumfeatures/PremiumIcon-Trainingstatues.png';
import QuickLoot from 'src/assets/images/premiumfeatures/PremiumIcon-QuickLoot.png';
import NewAreas from 'src/assets/images/premiumfeatures/PremiumIcon-NewAreas.png';
import SupplyStash from 'src/assets/images/premiumfeatures/PremiumIcon-SupplyStash.png';
import Spells from 'src/assets/images/premiumfeatures/PremiumIcon-Spells.png';
import Travel from 'src/assets/images/premiumfeatures/PremiumIcon-Travel.png';
import Outfit from 'src/assets/images/premiumfeatures/PremiumIcon-Outfit.png';
import Mount from 'src/assets/images/premiumfeatures/PremiumIcon-Mount.png';
import Promotion from 'src/assets/images/premiumfeatures/PremiumIcon-Promotion.png';
import Death from 'src/assets/images/premiumfeatures/PremiumIcon-Death.png';
import Market from 'src/assets/images/premiumfeatures/PremiumIcon-Market.png';
import House from 'src/assets/images/premiumfeatures/PremiumIcon-House.png';
import VIP from 'src/assets/images/premiumfeatures/PremiumIcon-VIP.png';
import Depot from 'src/assets/images/premiumfeatures/PremiumIcon-Depot.png';
import Chat from 'src/assets/images/premiumfeatures/PremiumIcon-Chat.png';
import VIPGroups from 'src/assets/images/premiumfeatures/PremiumIcon-VIPGroups.png';
import Prey from 'src/assets/images/premiumfeatures/PremiumIcon-Prey.png';
import Imbuing from 'src/assets/images/premiumfeatures/PremiumIcon-Imbuing.png';
import DailyReward from 'src/assets/images/premiumfeatures/PremiumIcon-DailyReward.png';
import Analytics from 'src/assets/images/premiumfeatures/PremiumIcon-Analytics.png';
import Quests from 'src/assets/images/premiumfeatures/PremiumIcon-Quests.png';
import Login from 'src/assets/images/premiumfeatures/PremiumIcon-Login.png';
import Summons from 'src/assets/images/premiumfeatures/PremiumIcon-Summons.png';
import Guild from 'src/assets/images/premiumfeatures/PremiumIcon-Guild.png';
import Addons from 'src/assets/images/premiumfeatures/PremiumIcon-Addons.png';
import Worlds from 'src/assets/images/premiumfeatures/PremiumIcon-Worlds.png';
import Regeneration from 'src/assets/images/premiumfeatures/PremiumIcon-Regeneration.png';
import Loyalty from 'src/assets/images/premiumfeatures/PremiumIcon-Loyalty.png';
import TrackLoot from 'src/assets/images/premiumfeatures/PremiumIcon-TrackLoot.png';
import Soulpoints from 'src/assets/images/premiumfeatures/PremiumIcon-Soulpoints.png';
import TrackQuests from 'src/assets/images/premiumfeatures/PremiumIcon-TrackQuests.png';
import Charms from 'src/assets/images/premiumfeatures/PremiumIcon-Charms.png';
import PartyWidget from 'src/assets/images/premiumfeatures/PremiumIcon-PartyWidget.png';
import SecondaryBattlelists from 'src/assets/images/premiumfeatures/PremiumIcon-SecondaryBattlelists.png';
import PreyHuntingTask from 'src/assets/images/premiumfeatures/PremiumIcon-PreyHuntingTask.png';
import PartyFinder from 'src/assets/images/premiumfeatures/PremiumIcon-PartyFinder.png';
import Skillwheel from 'src/assets/images/premiumfeatures/PremiumIcon-Skillwheel.png';
import PvPArena from 'src/assets/images/premiumfeatures/PremiumIcon-PvPArena.png';
import IconNo from 'src/assets/images/premiumfeatures/icon_no.png';
import IconYes from 'src/assets/images/premiumfeatures/icon_yes.png';
import PopOverPremium from './Components/Popover';

const Premium = () => {
  return (
    <Content headline={Title}>
      <Flex alignContent="center" alignItems="center" mt={3} mb={8}>
        <Box>
          <Text mb={4} fontSize="12pt" fontWeight={700}>
            Why Should You Make Your Account Premium?
          </Text>
          <Text mb={4}>
            Tibia is free, and players are welcome to play free of charge for as long as they like.
            However, if you enjoy Tibia, you may consider to take the game further by buying Premium
            Time. As a Premium player you will have additional abilities and advantages inside and
            outside the game. Buy Premium Time today to make Tibia even more fun!
          </Text>
          <Flex justifyContent="center" mb={6}>
            <Button variant="success">Get Premium</Button>
          </Flex>

          <Text>The following benefits are available to Premium players:</Text>
        </Box>
      </Flex>
      <TableContainer title="Tibia Features" tableBgColor="#f1e0c5" tablePadding="5px">
        <Box border="1px solid #5f4d41" boxShadow="3px 3px 2px #875f3e" mb={2}>
          <Table>
            <Tbody>
              <Tr fontWeight={700} whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="5px">
                  Benefit
                </Td>
                <Td border="1px solid #faf0d7" padding="5px" textAlign="center">
                  Premium Details
                </Td>
                <Td border="1px solid #faf0d7" padding="5px" textAlign="center">
                  Free
                </Td>
                <Td border="1px solid #faf0d7" padding="5px" textAlign="center">
                  Premium
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  XP boost
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Stamina}
                    title="50% XP boost for 3 hours every day"
                    description="How about gaining 50% more XP when hunting? As a Premium player, you can enjoy this valuable boost during your first three hours of stamina."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  0
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  +50%
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Offline training
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Trainingstatues}
                    title="Train your skills while offline"
                    description="Offline training is a real time saver, exclusively available to Premium players! Vital actions of your character are determined by your skills so hone them even while you are offline!"
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Refined quick looting
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={QuickLoot}
                    title="Customise quick looting to your liking"
                    description="Refine the Loot All option by excluding items or set up a list to only loot specific items. Premium status also allows you to loot a single item with a simple click and to assign categories to your containers to automatically sort looted items."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Areas
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={NewAreas}
                    title="Access Premium areas"
                    description="Discover new mysterious places, visit distant continents full of exotic creatures and explore the whole world of Tibia. Exciting adventures await you!"
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  5 areas and cities
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  17+ areas and cities
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Efficient supply stash
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={SupplyStash}
                    title="Enjoy the supply stash to its full extent"
                    description="As a Premium player, you can enjoy all benefits of the supply stash. Stow an unlimited number of resources and creature products neatly, quickly and efficiently instead of just being able to retrieve them from the stash."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  retrieve
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  stow and retrieve
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Spells
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Spells}
                    title="Cast all powerful spells"
                    description="Harness the full magical potential of your vocation! Cast powerful attack, support, and healing spells, and summon mighty creatures to vanquish the enemy."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  55
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  139+
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Instant travelling
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Travel}
                    title="Use instant travel system"
                    description="No matter where you are heading, travel via the Tibian transport system and you will always be there in a jiffy! Boats, magic carpets, steamships, astral bridges, elemental shrines - travelling has never been that convenient."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Outfits
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Outfit}
                    title="Wear Premium outfits"
                    description="Dress up and wear one of the popular outfits that are exclusively available to Premium players."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  4 + 1 unlockable
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  32+
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Mounts
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Mount}
                    title="Tame mounts to travel faster"
                    description="Tame majestic wild creatures and get yourself faithful travel companions. They will carry you swiftly to your destination. Impress others by riding into battle on rare mounts!"
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  3 rentable
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  41+
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Death penalty
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Death}
                    title="Lose 30% less on death with a promotion"
                    description="Death in Tibia hurts! Lose 30% less XP and skills on death by promoting your character. Premium status allows you to enjoy this and all other benefits of a promotion."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  0% reduction
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  30% reduction
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Market offers
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Market}
                    title="Create Market offers"
                    description="Create Market offers to buy items you fancy or to sell your loot in a convenient way."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  House ownership
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={House}
                    title="Own and decorate houses"
                    description="Enjoy the exclusive ability to rent a house where you can regenerate and also train your skills while sleeping in your bed. Make yourself at home and choose from the vast assortment of furniture and decorations, put your treasures on display, and invite your friends over."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} mr={2} /> 900+ houses
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  VIP list entries
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={VIP}
                    title="Add up to 100 players to your VIP list"
                    description="Add up to 100 characters instead of only 20 to your VIP list to find out which of your friends or enemies are online."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  20
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  100
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Depot space
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Depot}
                    title="Store up to 15,000 items in your depot"
                    description="Store up to 15,000 items instead of only 2,000 in your depot chest which you can access from every city depot!"
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  2000
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  15000
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Private chat channels
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Chat}
                    title="Invite friends to private chats"
                    description="Create your own private chat channel and invite anyone you like to join your chat."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Character promotion
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Promotion}
                    title="Get stronger with a promotion"
                    description="Promote your character to a superior rank with further advantages such as faster regeneration, reduced death penalty, and special vocation-specific spells."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  VIP groups
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={VIPGroups}
                    title="Add groups to organise your VIP list"
                    description="Organise and sort characters on your VIP list into groups. As a Premium player, you can create up to 5 groups in addition to the 3 predefined groups."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  3
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  3+5
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Challenging arenas
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={PvPArena}
                    title="Fight in PvP and PvE arenas"
                    description="Fight for fun without death penalty in Tibia's arenas! Face other players or stand your ground against waves of monster hordes."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Free prey slots
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Prey}
                    title="Use two prey slots for free"
                    description="Benefit of two free prey slots to select monsters from which you gain a bonus when hunting them."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  1
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  2
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Imbuements
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Imbuing}
                    title="Imbue items with stronger effects"
                    description="Enhance your equipment with useful temporary effects through intricate and powerful imbuements which are considerably stronger than the basic ones."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  basic
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  basic,
                  <br />
                  intricate,
                  <br />
                  powerful
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Daily rewards
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={DailyReward}
                    title="Get better daily rewards"
                    description="Get better daily rewards and enjoy further useful resting area bonuses such as stamina regeneration, for example."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  basic
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  extended
                  <br />
                  (more than twice)
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Advanced analytics features
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Analytics}
                    title="Use advanced analytics features"
                    description="Optimise your hunting sessions by using advanced analytics features. Premium status also allows you to track more items and quests."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Quests
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Quests}
                    title="Solve exclusive quests for the best rewards"
                    description="Unravel mysteries and face evil masterminds as you solve the abundance of thrilling quests Tibia has to offer. Get your hands on the most precious rewards and rare treasures!"
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  ~ 80
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  280+
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Priority login
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Login}
                    title="Benefit of priority login"
                    description="Especially on new game worlds, the number of players online can quickly get close to a world's maximum capacity. As a Premium player, you will have login priority while free accounts have to wait."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Mighty summons
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Summons}
                    title="Use mighty summons"
                    description="Summon a powerful companion to come to your aid in battle. Each vocation can call a unique creature with special abilities."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Guild leadership
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Guild}
                    title="Create and lead powerful guilds"
                    description="You have what it takes to be a leader? Found your own guild, recruit followers and rent one of the impressive guildhalls. Bring like-minded players together under your banner and form strong bonds of friendship and camaraderie."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Exclusive outfit addons
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Addons}
                    title="Earn addons to spice up outfits"
                    description="Because basic outfits alone are not dashing enough! Earn addons to make your character stand out and prove your skill as an adventurer."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Premium worlds
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Worlds}
                    title="Be the first on new game worlds"
                    description="Be among the first to play on new game worlds which can only be accessed with Premium status. In addition, Premia is an old world exclusively reserved for Premium players."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Mana and HP regeneration
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Regeneration}
                    title="Regenerate faster with a promotion"
                    description="Regenerate your mana and hit points considerably faster. Premium status allows you to enjoy this and all other benefits of a promotion."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  slow regeneration
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  fast regeneration
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Loyalty system
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Loyalty}
                    title="Get a skill bonus through loyalty"
                    description="Accumulate loyalty points through Premium status to benefit from a skill bonus, earn special titles and rise in the loyalty highscores."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Trackable items
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={TrackLoot}
                    title="Track up to 50 items"
                    description="Keep track of up to 50 items instead of only 5 in the drop tracker. Whenever a creature drops one of your tracked items, you will be notified about it."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  5
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  50
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Soul points
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Soulpoints}
                    title="Enhance your soul points with a promotion"
                    description="Raise your soul points to 200 instead of only 100 and regenerate them 8 times faster by promoting your character. Premium status allows you to enjoy this and all other benefits of a promotion."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  100, slow regeneration
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  200, fast regeneration
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Trackable quests
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={TrackQuests}
                    title="Track up to 20 quests"
                    description="Keep track of your progress on up to 20 quests or tasks instead of only 5 in the quest tracker."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  5
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  20
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  More active charms
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Charms}
                    title="Use up to 6 charms at the same time"
                    description="Use charms on up to 6 different Bestiary creatures instead of 2 at the same time to benefit of offensive, defensive or passive bonus effects against them."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  2
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  6
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Party List
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={PartyWidget}
                    title="Watch your party in an exclusive list"
                    description="Open a party list to have all characters that are in your party displayed, no matter where they are in Tibia. If they are no more than 30 fields away from you, you can even see their mana, health points and skulls."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Secondary Battle Lists
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={SecondaryBattlelists}
                    title="Organise characters and creatures nearby in secondary battle lists"
                    description="As a Premium player, you can open secondary battle lists. They allow you to use the battle list's sorting features more efficiently. Open an own battle list for each filter you have applied."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Free Hunting Task slots
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={PreyHuntingTask}
                    title="Use two Hunting Task slots for free"
                    description="Benefit of two free Hunting Task slots to select monsters and earn special rewards for hunting them down."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  1
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  2
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Team Finder
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={PartyFinder}
                    title="Find a team more easily"
                    description="Whenever you are looking for teammates to go on a big hunt or to solve a tricky quest, use the Team Finder to join a team or to assemble an own one."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
              <Tr whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" padding="20px 5px" width="30%" fontWeight={700}>
                  Wheel of Destiny
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="15%">
                  <PopOverPremium
                    image={Skillwheel}
                    title="Use the Wheel of Destiny to increase your power"
                    description="From level 51 onwards, you receive one promotion point with each level. You can also earn bonus promotion points by using certain rare items or by enhancing a mod in the Fragment Workshop."
                    description2="Use these points to increase your mana, hit points, mitigation or capacity. If you are strong enough, you can also use promotion points to gain even more powerful bonuses, learn new spells, enhance already known ones or unlock the powers of your revealed gems."
                  />
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconNo} />
                  </Flex>
                </Td>
                <Td border="1px solid #faf0d7" padding="20px 0" width="25%" textAlign="center">
                  <Flex justifyContent="center">
                    <Image src={IconYes} />
                  </Flex>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Box border="1px solid #5f4d41" boxShadow="3px 3px 2px #875f3e">
          <Table>
            <Tbody>
              <Tr bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" padding="5px">
                  <Text>
                    Please note this is not an exhaustive list. Other benefits are sure to follow,
                    but it is also possible that existing ones are changed or even removed in
                    future.
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </TableContainer>
    </Content>
  );
};

export default Premium;
