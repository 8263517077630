import React from 'react';
import { Box } from '@chakra-ui/react';
import Pedestal from 'src/assets/images/header/pedestal.gif';
import { useQuery } from 'react-query';
import { getBoosted } from 'src/services/boostedService/BoostedService';
import { BoostedBoss, BoostedCreature } from 'src/utils/interfaces/boosted.interface';

const RightArtwork = () => {
  const { data: boosted } = useQuery({
    queryFn: getBoosted,
    queryKey: ['boosted'],
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false
  });

  const getImageUrl = (entity: BoostedCreature | BoostedBoss) =>
    `https://outfit-images-oracle.ots.me/latest_walk/animoutfit.php?id=${entity.looktype}&addons=${entity.lookaddons}&head=${entity.lookhead}&body=${entity.lookbody}&legs=${entity.looklegs}&feet=${entity.lookfeet}&mount=${entity.lookmount}&direction=3`;

  const Monster = boosted ? getImageUrl(boosted.data.creature) : '';
  const Boss = boosted ? getImageUrl(boosted.data.boss) : '';

  return (
    <Box
      id="RightArtwork"
      top="41px"
      right="-165px"
      zIndex="1"
      textAlign="left"
      position="absolute"
      width="132px"
      backgroundRepeat="no-repeat"
    >
      <Box
        id="Pedestal"
        bgImage={Pedestal}
        position="absolute"
        top="-121px"
        right="-16px"
        width="161px"
        height="85px"
      />
      {boosted && (
        <>
          <Box
            id="Monster"
            title={`Today's boosted creature: ${boosted.data.creature.boostname}`}
            bgImage={Monster}
            position="absolute"
            top="-132px"
            left="-9px"
            width="64px"
            height="64px"
            cursor="pointer"
          />
          <Box
            id="Boss"
            title={`Today's boosted boss: ${boosted.data.boss.boostname}`}
            bgImage={Boss}
            position="absolute"
            top="-132px"
            right="20px"
            width="64px"
            height="64px"
            zIndex={15}
            cursor="pointer"
          />
        </>
      )}
    </Box>
  );
};

export default RightArtwork;
