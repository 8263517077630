import { Flex, Image, Link, Text } from '@chakra-ui/react';
import TableContainer from 'src/components/TableContainer/TableContainer';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import DownloadIcon from 'src/assets/images/account/download_windows.gif';

const DownloadClient = () => {
  return (
    <TableContainer title="Download Client" marginBottom="16px">
      <TableContentContainer>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          padding="2px 5px"
          border="1px solid #faf0d7"
        >
          <Text>
            Click <Link href="/downloads/ironman.zip">here</Link> to download the latest Ironman
            client!
          </Text>
          <Flex
            as={Link}
            cursor="pointer"
            justifyContent="center"
            flexDir="column"
            alignItems="center"
            href="/downloads"
          >
            <Image src={DownloadIcon} alt="Download Client" boxSize="45px" textAlign="center" />
            <Text textAlign="center">Download</Text>
          </Flex>
        </Flex>
      </TableContentContainer>
    </TableContainer>
  );
};

export default DownloadClient;
