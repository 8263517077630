/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Image, Link, Text } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-quickstart.gif';
import Bullet from 'src/assets/images/content/bullet.gif';

const SecurityHints = () => {
  return (
    <Content headline={Title}>
      <Text>
        Please follow the security hints carefully to avoid losing access to your Tibia account and
        to protect it from hacking attempts. This page also offers help if you have already lost
        your account to a hacker.
        <br />
        <br />
        <br />
        <h3>Security Hints</h3>
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Do not give your account data to anybody!</b>
          <br />
        </Flex>
        People pretending to be members of the CipSoft Team might contact you and ask for your
        password, or your recovery key. They might tell you that they have to fix an error, or that
        they want to give you free items or Premium Time. This is nonsense. Please always keep in
        mind that members of the CipSoft Team will never ask you for your password or your recovery
        key. Never give away your password or your recovery key to other people, not even to the
        CipSoft Team. Also be very careful about what you are saying in chats, emails, messaging
        services, social networks or VoIP services such as Ventrilo, TeamSpeak or Skype!
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Do not enter your account data on any website except for https://www.tibia.com!</b>
        </Flex>
        <br />
        There are websites which claim to do surveys, promise you free items or offer cheat tools
        etc. You are usually asked to enter your password there, but the only goal of the website is
        to steal your account. An all-time favourite are ingame letters informing you about a
        punishment and ask you to enter your data on the Tibia website. The link there gets you to a
        fake site. If you have been punished for a rule violation, we will never contact you about
        it in an ingame letter!
        <br />
        Always watch out for websites that fake the official Tibia website. Be wary if you look for
        Tibia using a search engine! The entries your get there might not always lead to the
        official website. There are fake sites trying to imitate the Tibia website that appear in
        the search results from time to time. To be on the safe side, bookmark the original website
        or type in the address www.tibia.com yourself. Only the address www.tibia.com will bring you
        to the official website of Tibia! If you look left of the web address and there is no little
        lock and the address does not start with &lsquo;https&lsquo;, you can be sure that you are
        on a fake website.
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Memorise your password well!</b>
        </Flex>
        <br />
        Never store your password unencrypted on your computer. Also, delete any emails containing
        account data. If somebody gets access to your computer or email account, he must not find
        your password or recovery key there. If you have problems to memorise your account data, use
        a safe password manager, e.g. <Link href="https://keepass.info">KeePass</Link>.
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Use secure passwords!</b>
        </Flex>
        <br />
        Your password should contain a mix of upper and lower case letters, numbers and special
        characters. Never choose parts of your email address as your password and do not use common
        words because hackers can guess them easily. The same is true for passwords that refer to
        your person, e.g. the name of your pet, or your date of birth. A good strategy to find a
        secure password for example is to think of a sentence and pick the first letters of every
        word. The sentence will help you to remember your password at all times, and nobody will be
        able to guess it. Finally, make sure that you do not use the same password for different
        services. It is important that you use different passwords for your Tibia account and your
        email service!
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Watch out for suspicious files that can be used to hack your computer!</b>
        </Flex>
        <br />
        Some people are distributing files that contain spy software such as Trojan horses or
        keyloggers. Dangerous software like that is frequently spread through websites, emails or
        messaging services. Carefully examine any link before clicking on it. Any email attachments
        and downloads should be handled with caution. Most of all, cheat tools and clients that have
        not been released by CipSoft are often designed to hack your Tibia account!
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Be aware of phishing emails!</b>
        </Flex>
        <br />
        As it is possible to fake the sender of an email, it can happen that you receive an email
        that has Tibia or CipSoft as sender which was not sent by us. Such emails usually contain
        links or attachments and are designed in a way to look like an official email from us. Do
        not simply click on links in such emails. To make sure that you are entering the official
        website, we strongly recommend you manually type www.tibia.com. Also, please note that we
        will never send you emails with attachments, so never open them!
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Update the software of your computer regularly!</b>
        </Flex>
        <br />
        Old versions of your operating system or other software frequently contains security holes
        that have been removed in newer versions. For example, a hacker can already spy out data
        from your computer if you visit a website with an old browser. Ideally, all programs on your
        computer should be updated to the latest available version. Most importantly, update your
        browser and your operating system regularly to ensure the best possible protection of your
        computer.
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Protect your computer using up-to-date security programs!</b>
        </Flex>
        <br />
        We recommend you use anti-virus programs as well as a firewall to prevent unwanted
        intrusions into your computer system. Make sure that you update your firewall regularly and
        your anti-virus program at least once a week.
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Do not play Tibia in public networks!</b>
        </Flex>
        <br />
        If you play Tibia on a computer to which many people have access, you can never be sure if
        this computer or the network it is connected to are safe. There may already be Trojan horses
        or keyloggers installed on the computer by other people. Also, the data you send through
        public networks such as those found in schools, libraries or internet cafes can easily be
        recorded by hackers. Remember to close your character list after you have logged out of the
        game and want to leave the computer, otherwise another user might log into your account.
        Rather avoid playing in public networks altogether!
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Assign your Tibia account to your own correct email address!</b>
        </Flex>
        <br />
        The assigned email address is the key to your Tibia account. Make sure you always have
        access to this email account. Use your email account regularly because many email providers
        delete inactive email accounts. Do not create an extra email account for your Tibia account.
        You might forget to use it regularly after some time. Adjust the assigned email address in
        time if your current email account is about to change. Also, handle your email account with
        the same caution as your Tibia account. A person with access to your email account might be
        able to hack your Tibia account!
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Do not share or trade accounts!</b>
        </Flex>
        <br />
        There is a reason why account sharing and trading is forbidden under the Tibia Rules.
        Remember you are running a great risk if you give your account data to any other person. We
        know from experience that even close friends have stolen or ruined each other&lsquo;s
        accounts. Also, people who trade their accounts are tricked frequently by the original owner
        and lose their accounts. Please note that CipSoft will not provide support in case an
        account is lost due to account trading or sharing!
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Confirm your account to get your recovery key!</b>
        </Flex>
        <br />
        Confirming your account will not only allow you to use all features of a regular Tibia
        account, but will also get you your very own recovery key. The recovery key allows you to
        reassign your account to a new email address immediately. For this reason the possession of
        a recovery key will give you total control of your account. Moreover, it is needed to link
        or unlink an account with/from an authenticator.
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Register your account and enter correct registration data!</b>
        </Flex>
        <br />
        If you have registered your account, you can request a new recovery key via postal mail for
        a small fee if you have lost it. It is therefore important that you enter your correct and
        full address and remember to keep your registration data up to date, as our letter may not
        reach you if your postal address is incorrect.
        <br />
        Further, players who have entered a valid mobile phone number, will receive a recovery TAN
        via SMS text message whenever they order a new recovery key. The recovery TAN can be used to
        change the email address of an account. Note that the recovery TAN is only valid for 24
        hours and can only be used once.
        <br />
        Only registered players can use the full range of account recovery options, so make sure to
        register your account today if you have not done so yet. Keep in mind that you may lose your
        account if another person gets access to your account and registers it!
        <br />
        <br />
        <Flex alignItems="baseline" gap={1}>
          <Image src={Bullet} height="12px" />
          <b>Connect to Tibia using an authenticator!</b>
        </Flex>
        <br />
        An authenticator offers players an additional layer of security to help prevent unauthorised
        account access. All accounts can be connected to an authenticator. In Tibia players can
        choose between two methods: two-factor authenticator app and two-factor email code
        authentication.
        <br />
        If your account is connected to an authenticator, you will be asked to enter a valid
        authenticator token in addition to your email address and password whenever you try to log
        into the game or the Tibia website. This token is generated via email or an authenticator
        app, e.g.{' '}
        <Link href="https://support.google.com/accounts/answer/1066447">
          Google Authenticator
        </Link>{' '}
        or <Link href="https://authy.com/download/">Authy</Link>
        . Simply download the authenticator app of your choice to your device or activate two-factor
        email code authentication. Log into your account and connect an authenticator to your Tibia
        account by following the steps in the section &lsquo;Two-Factor Authentication&lsquo; on
        your account page.
        <br />
        <b>Important if you use an authenticator app:</b>
        <br />
        1. Before you connect your Tibia account to an authenticator app, you have to enter the
        recovery key for the account to prove that you have it. It is very important that you have
        the recovery key because in case your mobile phone breaks or gets lost, the recovery key is
        the only way to disconnect your Tibia account from the authenticator. You will also need the
        recovery key in case you lose access to the email you have used for two-factor email code
        authentication.
        <br />
        2. It is also important to use an authenticator app on a different device than the one you
        are playing Tibia on. An authenticator on the computer you use for playing Tibia will not
        help you to protect your account if somebody gains access to your computer.
        <br />
        <br />
        <br />
        <h3>What to Do If Your Account Has Been Hacked</h3>
        <b>Step 1: Remove the Security Problem!</b>
        <br />
        The very first thing that you have to do is to find out how somebody got your account data.
        Remember that if you have not removed the security problem, the hacker can again get access
        to your account. Below you will find a list of questions that might help you to find the
        security problem and to remove it.
        <br />
        <ul className="BulletPointList">
          <li>
            <Flex alignItems="baseline" gap={1} mb={2}>
              <Image src={Bullet} />
              Did you download or open any files that possibly contained spy software such as Trojan
              horses or keyloggers? Remember there is a risk that your computer gets infected by spy
              software whenever you get emails containing attachments, visit websites or receive any
              kind of files, even if they have been sent by close friends. Therefore you should run
              one or two up-to-date anti-virus programs on your computer. If you find any infected
              files, the best solution will be to format all hard disks and to re-install your
              computer system. Only then you can be sure that you have removed all infected files.
              <br />
              From our experience the majority of hacking cases are caused by spy software. For this
              reason you should be extremely careful whenever you receive any files no matter how
              trustworthy the source may be.
            </Flex>
          </li>
          <li>
            <Flex alignItems="baseline" gap={1} mb={2}>
              <Image src={Bullet} />
              Did you play Tibia on a computer other than your own? Always remember that you can
              never be sure that computers owned by other persons are not infected by viruses or
              keyloggers. Inform the owner of the other computer that there might be a security
              problem that should be removed. Avoid playing on other computers otherwise you risk
              losing your account again.
            </Flex>
          </li>
          <li>
            <Flex alignItems="baseline" gap={1} mb={2}>
              <Image src={Bullet} />
              Did you give your account data to somebody else or did you share your account with a
              friend? Is it possible that somebody found a piece of paper with your account data on
              it? Always remember not to give your account data to anybody, not even to people who
              claim to be members of the CipSoft Team. Often friends trick each other if the
              characters are quite valuable or they ruin characters carelessly. For this reason do
              not entrust your hard-earned characters to your friends. Make sure to store your
              account data in a safe place.
            </Flex>
          </li>
          <li>
            <Flex alignItems="baseline" gap={1} mb={2}>
              <Image src={Bullet} />
              Is it possible that your email account has been hacked and that somebody found an
              email containing your password or recovery key? In this case secure your email account
              immediately with a new password or contact your email provider for assistance. You
              might also consider changing the email address your Tibia account is assigned to.
              Also, make yourself familiar with the security guidelines of your email provider to
              avoid further problems.
            </Flex>
          </li>
          <li>
            <Flex alignItems="baseline" gap={1} mb={2}>
              <Image src={Bullet} />
              Did you buy the account from somebody or did you trade it? Usually the original holder
              of a Tibia account also has the recovery key. By using this recovery key, the holder
              can get his account back anytime by reassigning the account to a different email
              address. For this reason, account trading is forbidden under the Tibia Rules. CipSoft
              will not provide support for accounts that have been traded.
            </Flex>
          </li>
          <li>
            <Flex alignItems="baseline" gap={1} mb={2}>
              <Image src={Bullet} />
              Did you enter your email address, password or recovery key at any other website than
              www.tibia.com? Perhaps you have entered your account data on a website which you
              thought was www.tibia.com, but you did not get the expected result? Always remember to
              check the address carefully before you enter your account data on a website. Only if
              you see www.tibia.com in it, it is safe to enter your account data.
            </Flex>
          </li>
          <li>
            <Flex alignItems="baseline" gap={1} mb={2}>
              <Image src={Bullet} />
              Is it possible that you have used a password that could be easily guessed? Always make
              sure to use a secure password for your Tibia account that contains a mix of upper and
              lower case letters, numbers and special characters.
            </Flex>
          </li>
        </ul>
        If none of these questions helps you to find the security problem, do not simply ignore it.
        There must be a security problem, otherwise you would not have been hacked. Remember you
        must remove the security problem before trying to get your account back. If you can exclude
        all other possible explanations, it is still possible that there is a keylogger on your
        computer that cannot be found by your anti-virus program. To be absolutely sure to get rid
        of all viruses and keyloggers, you need to format all hard disks and to re-install your
        computer system. Remember that not only your Tibia account is at stake if your computer is
        infected by spy software, but possibly also other confidential data such as bank account
        information.
        <br />
        <br />
        <b>Step 2: Get Your Account Back!</b>
        <br />
        Once you have removed the security problem you can get back access to your account by using
        the <Link href="account/lost">Lost Account Interface</Link>
        . Of course, you need to prove that your claim to the account is justified. Enter the
        requested data and follow the instructions carefully. Please understand there is no way to
        get access to your lost account if the interface cannot help you.
        <br />
        <br />
        <b>Step 3: Follow the Security Hints!</b>
        <br />
        Please make sure to follow the security hints that are stated above from now on. Otherwise
        you might make another mistake that a hacker could take advantage of.
      </Text>
    </Content>
  );
};

export default SecurityHints;
