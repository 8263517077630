/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-whatistibia.gif';
import WhatIsTibia from 'src/assets/images/abouttibia/what_is_tibia_intro.jpg';
import WhatIsVocation from 'src/assets/images/abouttibia/what_is_tibia_vocations.jpg';

const AboutTibia = () => {
  return (
    <Content headline={Title}>
      <Flex alignContent="center" alignItems="center" mt={3} mb={8}>
        <Image src={WhatIsTibia} mr={2} />
        <Box>
          <Text mb={3}>
            Tibia is one of the oldest and most successful massively multiplayer online role-playing
            games (MMORPG) created in Europe. In an MMORPG people from all over the world meet on a
            virtual playground to explore areas, solve tricky riddles and undertake heroic exploits.
          </Text>
          <Text mb={3}>
            For more than 25 years now, players have been visiting the medieval world of Tibia. At
            present, more than 500,000 players from all over the world inhabit the Tibian continent
            enjoying the numerous <Link href="/about/features">game features</Link>.
          </Text>
          <Text>
            Acting as knights, paladins, sorcerers or druids, players are faced with the challenge
            of developing the skills of their selected characters, exploring a large variety of
            areas and dangerous dungeons and interacting with other players on a social and
            diplomatic level. Besides the sophisticated chat tools it is especially the unique
            freedom players enjoy in Tibia that create an enormously immersive gaming experience.
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="center" mb={4}>
        <Image src={WhatIsVocation} />
      </Flex>
      <Text mb={3}>
        Tibia can be played free of charge as a matter of principle. However, your account can be
        upgraded anytime to a <Link href="/about/premium">Premium account</Link>. Advantages of
        Premium accounts include the access to special game areas and items as well as further
        special features relating to the game.
      </Text>
      <Text>
        Detailed information about the game can be found in our{' '}
        <Link href="/about/manual">manual</Link>.
      </Text>
    </Content>
  );
};

export default AboutTibia;
