import ArrowLeft from 'src/assets/images/content/headline-bracer-left.gif';
import ArrowRight from 'src/assets/images/content/headline-bracer-right.gif';
import { Flex, Text } from '@chakra-ui/react';
import { useAccountContext } from 'src/utils/contexts/AccountContext';

const Welcome = () => {
  const { account } = useAccountContext();
  return (
    <Flex justifyContent="center" alignItems="center" padding={1} marginBottom={4}>
      <img src={ArrowLeft} alt="Arrow left" />
      <Text
        textAlign="center"
        verticalAlign="middle"
        fontSize="17px"
        fontWeight="bold"
        paddingInline={1}
        marginTop={1}
      >
        Welcome to your account {account?.firstName}!
      </Text>
      <img src={ArrowRight} alt="Arrow right" />
    </Flex>
  );
};

export default Welcome;
