/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Text } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-downloadclient.gif';
import TableContainer from 'src/components/TableContainer/TableContainer';
import Button from 'src/components/Button';
import { useNavigate } from 'react-router';

const DownloadAgreement = () => {
  const navigate = useNavigate();
  return (
    <Content headline={Title}>
      <Text mb={4}>
        Before you can download the client program please read the Ironman Service Agreement and
        state if you agree to it by clicking on the appropriate button below.
      </Text>
      <TableContainer
        title="Ironman Service Agreement"
        tableBgColor="#d4c0a1"
        tablePadding="5px"
        mb={3}
      >
        <Text mb={3}>
          This agreement describes the terms on which CipSoft GmbH offers you access to an account
          for being able to play the online role playing game &quot;Ironman&quot;. By creating an
          account or downloading the client software you accept the terms and conditions below and
          state that you are of full legal age in your country or have the permission of your
          parents to play this game.
        </Text>
        <Text mb={3}>
          You agree that the use of the software is at your sole risk. We provide the software, the
          game, and all other services &quot;as is&quot;. We disclaim all warranties or conditions
          of any kind, expressed, implied or statutory, including without limitation the implied
          warranties of title, non-infringement, merchantability and fitness for a particular
          purpose. We do not ensure continuous, error-free, secure or virus-free operation of the
          software, the game, or your account.
        </Text>
        <Text mb={3}>
          We are not liable for any lost profits or special, incidental or consequential damages
          arising out of or in connection with the game, including, but not limited to, loss of
          data, items, accounts, or characters from errors, system downtime, or adjustments of the
          gameplay.
        </Text>
        <Text mb={3}>
          While you are playing &quot;Ironman&quot;, you must abide by some rules (&quot;Ironman
          Rules&quot;) that are stated on this homepage. If you break any of these rules, your
          account may be removed and all other services terminated immediately.
        </Text>
        <Text>
          Ironman is neither willing nor required to take part in out-of-court dispute resolution.
        </Text>
      </TableContainer>
      <Flex justifyContent="center">
        <Button onClick={() => navigate('/downloads/client')}>I Agree</Button>
      </Flex>
    </Content>
  );
};

export default DownloadAgreement;
