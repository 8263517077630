/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import BackgroundPopOver from 'src/assets/images/content/scroll.gif';
import Ornament from 'src/assets/images/content/ornament.gif';

interface PopOverPremium {
  image: string;
  title: string;
  description: string;
  description2?: string;
}
const PopOverPremium = ({ image, title, description, description2 }: PopOverPremium) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Flex justifyContent="center">
      <Popover
        returnFocusOnClose
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur
        closeOnEsc
        placement="right"
      >
        <PopoverTrigger>
          <Image
            src={image}
            cursor="help"
            onMouseOver={() => onToggle()}
            onMouseOut={() => onToggle()}
          />
        </PopoverTrigger>
        <PopoverContent bgImage={BackgroundPopOver}>
          <PopoverArrow />
          <PopoverBody>
            <Text
              fontWeight={700}
              marginBottom="15px"
              fontSize="12pt"
              position="relative"
              whiteSpace="normal"
            >
              {title}
            </Text>
            <Text whiteSpace="normal" textAlign="justify" fontSize="9pt">
              {description}
              {description2 && (
                <>
                  <br />
                  <br />
                </>
              )}
              {description2}
            </Text>
            <Image src={Ornament} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default PopOverPremium;
