import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import PremiumBoxImage from 'src/assets/images/themeboxes/premium/themebox.png';
import CoinAnimation from 'src/assets/images/themeboxes/premium/coin_animation.gif';
import CoinConsumables from 'src/assets/images/themeboxes/premium/coins_consumables.png';
import Overlay from 'src/assets/images/themeboxes/premium/type_overlay.png';
import Button from 'src/assets/images/themeboxes/premium/button.png';
import ButtonHover from 'src/assets/images/themeboxes/premium/button_hover.png';
import GetCoins from 'src/assets/images/themeboxes/premium/get_tibia_coins.png';

const PremiumBox = () => {
  return (
    <Box
      id="PremiumBox"
      className="Themebox"
      bgImage={PremiumBoxImage}
      height="204px"
      position="relative"
      backgroundRepeat="no-repeat"
    >
      <Box
        id="PremiumBoxDecor"
        bgImage={CoinAnimation}
        margin="0"
        position="absolute"
        top="-28px"
        left="5px"
        width="64px"
        height="64px"
        zIndex={100}
      />
      <Box
        id="PremiumBoxBg"
        bgImage={CoinConsumables}
        margin="0"
        position="absolute"
        top="34px"
        left="10px"
        width="160px"
        height="126px"
        zIndex={100}
      />
      <Box
        id="PremiumBoxOverlay"
        bgImage={Overlay}
        margin="0"
        position="absolute"
        top="34px"
        left="10px"
        width="163px"
        height="26px"
        zIndex={100}
      >
        <Text
          id="PremiumBoxOverlayText"
          color="#fff"
          fontFamily="Verdana, sans-serif"
          marginTop="3px"
          textAlign="center"
          fontSize="11px"
        >
          Get Supplies Anywhere!
        </Text>
      </Box>
      <Box
        id="PremiumBoxButton"
        position="absolute"
        bottom="43px"
        left="18px"
        textAlign="center"
        zIndex={100}
      >
        <Box
          id="WebshopButton"
          bgImage={Button}
          position="absolute"
          width="142px"
          height="34px"
          textAlign="center"
          zIndex={100}
          cursor="pointer"
          _hover={{ bgImage: ButtonHover }}
        >
          <Box
            id="WebshopButtonText"
            bgImage={GetCoins}
            position="relative"
            top="2px"
            width="140px"
            height="30px"
            textAlign="center"
            zIndex={100}
            cursor="pointer"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PremiumBox;
