/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-gamefeatures.gif';
import Bullet from 'src/assets/images/content/bullet.gif';
import Button from 'src/components/Button';
import { useNavigate } from 'react-router';

const Features = () => {
  const navigate = useNavigate();
  return (
    <Content headline={Title}>
      <Flex mt={3} mb={8}>
        <Box>
          <Text mb={3} fontWeight={700}>
            What is Tibia?
          </Text>
          <Text mb={12}>
            Tibia is a free fantasy massively multiplayer online role-playing game (MMORPG). Take
            the role of a valorous adventurer and explore a mysterious world filled with magic and
            bravery! Meet up with people from all over the world to live through a whole new game
            experience!
          </Text>
          <Text fontWeight={700} mb={4}>
            Why Play Tibia?
          </Text>
          <Box paddingX={4}>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>It&lsquo;s Free!</Text>
                <Text>
                  No hidden costs, no limited trial phase! Just create an account and play Tibia for
                  as long as you like!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Fast and Easy Access!</Text>
                <Text>
                  Even with a slow internet connection downloading the client is a matter of
                  minutes!
                </Text>
              </Box>
            </Flex>

            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Four Character Vocations</Text>
                <Text>
                  Be a valorous knight, a nimble paladin, a mysterious druid or a mighty sorcerer!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Customisable Outfit</Text>
                <Text>
                  Select from various outfits and give your character the looks of your choice!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Character Skills</Text>
                <Text>
                  Beef up your hero! Characters have a variety of skills that will raise through
                  training.
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Dozens of Spells</Text>
                <Text>
                  Fancy some magic? You can fry your enemies with a wide range of deadly spells!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Unlimited Character Development</Text>
                <Text>Characters can be improved on and on. There&lsquo;s just no limit!</Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Massive Game World</Text>
                <Text>
                  Visit bustling cities, mysterious forests and dreary dungeons. The world of Tibia
                  is huge, and it keeps on growing!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Scores of Monsters</Text>
                <Text>
                  Countless fearsome creatures roam the land. Meet vicious orcs, mighty dragons or
                  deadly demons!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Hundreds of Items</Text>
                <Text>
                  Find hundreds of items. All items are unique and come with their own
                  characteristics and graphics!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Quests</Text>
                <Text>
                  Go on dangerous quests for fame and fortune. All players have equal chances to do
                  quests!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Monster Raids and Other Events</Text>
                <Text>
                  Special game events can happen anytime. There is nothing like a surprise attack on
                  a city to make an adventurer&lsquo;s life exciting!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Guilds</Text>
                <Text>
                  Players can team up in guilds. Join your friends in a powerful Tibia guild!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Several Game Modes Available</Text>
                <Text>
                  Would you like to see more or less player killing? Choose a different game world
                  to play the Tibia you like!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Tibia Rules</Text>
                <Text>
                  A balanced set of rules ensures that Tibia does not sink into anarchy while giving
                  great freedom to players.
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Automatic Control of Player Killing</Text>
                <Text>
                  A sophisticated automatic system makes sure player killing never gets out of hand!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Massive Community</Text>
                <Text>
                  With more than 500,000 players, Tibia is one of the largest games of its kind.
                  Meet people from all over the world!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Forum</Text>
                <Text>
                  The Tibian community never sleeps. Exchange opinions with fellow Tibians on our
                  forum!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>VIP List</Text>
                <Text>
                  A sophisticated contact list ensures that you will always know when your friends
                  and enemies are online!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Groundbreaking Chat Feature</Text>
                <Text>
                  Talk to many players at the same time using Tibia&lsquo;s easy-to-use chat
                  feature. Chatting has never been so much fun!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Low System Requirements</Text>
                <Text>
                  Don&lsquo;t worry if your system is a bit advanced in years. Tibia does not
                  require cutting-edge hardware to run!
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Intuitive and Customisable User Interface</Text>
                <Text>
                  With context menus and tool tips assisting your every move, Tibia controls are
                  intuitive and easy to learn.
                </Text>
              </Box>
            </Flex>
            <Flex mb={2}>
              <Image src={Bullet} boxSize="12px" mr={2} />
              <Box>
                <Text fontWeight={700}>Premium Features Available</Text>
                <Text>
                  Would you like to take Tibia even further? Make your account Premium to get cool
                  additional features such as rentable houses, brand new spells and areas, and many
                  more!
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="center">
        <Button onClick={() => navigate('/account/create')}>Create Account</Button>
      </Flex>
    </Content>
  );
};

export default Features;
