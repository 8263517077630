import { Boosted } from 'src/utils/models/boosted.model';
import { IApiResponse } from '../../utils/interfaces/apiResponse.interface';
import BaseHttpService from '../baseHttpService/BaseHttpService';

const baseHttpPlayersService = BaseHttpService.getInstance();

const basePath = '/boosted';

export async function getBoosted(): Promise<IApiResponse<Boosted>> {
  const apiOptions = {
    model: Boosted,
    dataType: 'boosted',
    path: basePath
  };

  const response = await baseHttpPlayersService.fetch(apiOptions);

  if (response.data) {
    return {
      ...response,
      data: response.data
    };
  }

  throw new Error('No response');
}
