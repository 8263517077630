import { Box, Flex, Text } from '@chakra-ui/react';
import TableContainer from 'src/components/TableContainer/TableContainer';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import { useNavigate } from 'react-router';
import Button from 'src/components/Button';

const History = () => {
  const navigate = useNavigate();
  return (
    <TableContainer title="History" marginBottom="16px" id="history">
      <TableContentContainer marginBottom={2}>
        <Flex
          padding="2px 5px"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Box>
            <Text fontWeight={700}>Premium History</Text>
            <Text>Contains all historical data about your Premium Scrolls and Premium Times.</Text>
          </Box>
          <Box>
            <Button onClick={() => navigate('/')}>View History</Button>
          </Box>
        </Flex>
      </TableContentContainer>
      <TableContentContainer marginBottom={2}>
        <Flex
          padding="2px 5px"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Box>
            <Text fontWeight={700}>Payments History</Text>
            <Text>Contains all historical data of your payments.</Text>
          </Box>
          <Box>
            <Button onClick={() => navigate('/')}>View History</Button>
          </Box>
        </Flex>
      </TableContentContainer>
      <TableContentContainer>
        <Flex
          padding="2px 5px"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Box>
            <Text fontWeight={700}>Coins History</Text>
            <Text>
              Contains all historical data about your Tibia Coins and products buyable with Tibia
              Coins.
            </Text>
          </Box>
          <Box>
            <Button onClick={() => navigate('/')}>View History</Button>
          </Box>
        </Flex>
      </TableContentContainer>
    </TableContainer>
  );
};

export default History;
