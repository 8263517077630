import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle({
  content: {
    borderColor: '#5a2800',
    fontSize: '8pt',
    border: '1px double #5a2800'
  }
});
export const popoverTheme = defineMultiStyleConfig({ baseStyle });
