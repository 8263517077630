import { useBlocker, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useAuth } from 'src/utils/hooks/useAuth';
import usePasswordValidation from 'src/utils/hooks/usePassword';
import { useAccountContext } from '../AccountContext';

const dependencies = {
  useAccountContext,
  usePasswordValidation,
  useAuth,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
  useBlocker
};
type Dependencies = typeof dependencies;

export type { Dependencies };
export { dependencies };
