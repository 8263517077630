import IconNews from 'src/assets/images/menu/icon-news.gif';
import LabelNews from 'src/assets/images/menu/label-news.png';
import IconAboutTibia from 'src/assets/images/menu/icon-abouttibia.gif';
import LabelAboutTibia from 'src/assets/images/menu/label-abouttibia.png';
import IconGamesGuides from 'src/assets/images/menu/icon-gameguides.gif';
import LabelGameGuides from 'src/assets/images/menu/label-gameguides.png';
import IconLibrary from 'src/assets/images/menu/icon-library.gif';
import LabelLibrary from 'src/assets/images/menu/label-library.png';
import IconCommunity from 'src/assets/images/menu/icon-community.gif';
import LabelCommunity from 'src/assets/images/menu/label-community.png';
import IconForum from 'src/assets/images/menu/icon-forum.gif';
import LabelForum from 'src/assets/images/menu/label-forum.png';
import IconAccount from 'src/assets/images/menu/icon-account.gif';
import LabelAccount from 'src/assets/images/menu/label-account.png';
import IconCharacterTrade from 'src/assets/images/menu/icon-charactertrade.gif';
import LabelCharacterTrade from 'src/assets/images/menu/label-charactertrade.png';
import IconSupport from 'src/assets/images/menu/icon-support.gif';
import LabelSupport from 'src/assets/images/menu/label-support.png';
import NewsIconTechnical from 'src/assets/images/content/newsicon_technical_small.png';
import NewsIconDevelopment from 'src/assets/images/content/newsicon_development_small.png';
import NewsIconCommunity from 'src/assets/images/content/newsicon_community_small.png';
import NewsIconSupport from 'src/assets/images/content/newsicon_support_small.png';
import { NewsCategory } from './models/news.model';
import { MenuItemProps } from './interfaces/menu.interface';

export const blockedWords = ['badword1', 'badword2', 'Passw0rd', 'Password123'];

export const ERROR_MESSAGES = {
  PASSWORD_LENGTH: '10-29 characters',
  NO_INVALID_LETTERS: 'No invalid letters!',
  NEED_LOWERCASE: 'At least one lower case letter (a-z)',
  NEED_UPPERCASE: 'At least one upper case letter (A-Z)',
  NEED_NUMBER: 'At least one number (0-9)',
  PASSWORD_NOT_MATCH: 'The two passwords do not match!'
};

export const NEWSTICKER_ICON = {
  [NewsCategory.TECHNICAL_ISSUES]: NewsIconTechnical,
  [NewsCategory.SUPPORT]: NewsIconSupport,
  [NewsCategory.DEVELOPMENT]: NewsIconDevelopment,
  [NewsCategory.COMMUNITY]: NewsIconCommunity
};

const Menus: MenuItemProps[] = [
  {
    id: 'news',
    label: LabelNews,
    icon: IconNews,
    subMenu: [
      { id: 1, title: 'Latest News', href: '/', enabled: true },
      { id: 2, title: 'News Archive', href: '/news/archive', enabled: true },
      { id: 3, title: 'Event Schedule', href: '/news/event', enabled: true }
    ],
    isOpen: false
  },
  {
    id: 'abouttibia',
    label: LabelAboutTibia,
    icon: IconAboutTibia,
    subMenu: [
      { id: 1, title: 'What is Tibia?', href: '/about/tibia', enabled: true },
      { id: 2, title: 'Screenshots', href: '/about/screenshots', enabled: false },
      { id: 3, title: 'Game Features', href: '/about/features', enabled: true },
      { id: 4, title: 'Premium Features', href: '/about/premium', enabled: true },
      { id: 5, title: 'About Cipsoft', href: '/about/cipsoft', enabled: false }
    ],
    isOpen: false
  },
  {
    id: 'gameguides',
    label: LabelGameGuides,
    icon: IconGamesGuides,
    subMenu: [
      { id: 1, title: 'Quickstart', href: '/guides/start', enabled: true },
      { id: 2, title: 'Manual', href: '/guides', enabled: false },
      { id: 3, title: 'Security Hints', href: '/guides/security', enabled: true }
    ],
    isOpen: false
  },
  {
    id: 'library',
    label: LabelLibrary,
    icon: IconLibrary,
    subMenu: [
      { id: 1, title: 'Creatures', href: '/library', enabled: true },
      { id: 2, title: 'Boostable Bosses', href: '/library', enabled: true },
      { id: 3, title: 'Spells', href: '/library', enabled: true },
      { id: 4, title: 'Achievements', href: '/library', enabled: true },
      { id: 5, title: 'Worlds Quests', href: '/library', enabled: true },
      { id: 6, title: 'Experience Table', href: '/library', enabled: true },
      { id: 7, title: 'Maps', href: '/library', enabled: true },
      { id: 8, title: 'Genesis', href: '/library', enabled: true },
      { id: 9, title: 'Soundtrack', href: '/library', enabled: true }
    ],
    isOpen: false
  },
  {
    id: 'community',
    label: LabelCommunity,
    icon: IconCommunity,
    subMenu: [
      { id: 1, title: 'Characters', href: '/community/characters', enabled: true },
      { id: 2, title: 'Worlds', href: '/community', enabled: true },
      { id: 3, title: 'Highscores', href: '/community', enabled: true },
      { id: 4, title: 'Leaderboards', href: '/community', enabled: true },
      { id: 5, title: 'Wheel of Destiny Planner', href: '/community', enabled: true },
      { id: 6, title: 'Kill Statistics', href: '/community', enabled: true },
      { id: 7, title: 'Houses', href: '/community', enabled: true },
      { id: 8, title: 'Guilds', href: '/community', enabled: true },
      { id: 9, title: 'Pools', href: '/community', enabled: true },
      { id: 10, title: 'Feedback Form', href: '/community', enabled: true },
      { id: 11, title: 'Fansites', href: '/community', enabled: true },
      { id: 12, title: 'Resellers', href: '/community', enabled: true }
    ],
    isOpen: false
  },
  {
    id: 'forum',
    label: LabelForum,
    icon: IconForum,
    subMenu: [
      { id: 1, title: 'World Boards', href: '/forum', enabled: true },
      { id: 2, title: 'Trade Boards', href: '/forum', enabled: true },
      { id: 3, title: 'Community Boards', href: '/forum', enabled: true },
      { id: 4, title: 'Support Boards', href: '/forum', enabled: true },
      { id: 5, title: 'Guild Boards', href: '/forum', enabled: true },
      { id: 6, title: 'CM Post Archive', href: '/forum', enabled: true }
    ],
    isOpen: false
  },
  {
    id: 'account',
    label: LabelAccount,
    icon: IconAccount,
    subMenu: [
      { id: 1, title: 'Account Management', href: '/account/management', enabled: true },
      { id: 2, title: 'Create Account', href: '/account/create', enabled: true },
      { id: 3, title: 'Download Client', href: '/downloads/agreement', enabled: true },
      { id: 4, title: 'Webshop', href: '/webshop', enabled: true },
      { id: 5, title: 'Lost Account', href: '/account/lost', enabled: true }
    ],
    isOpen: false
  },
  {
    id: 'charactertrade',
    label: LabelCharacterTrade,
    icon: IconCharacterTrade,
    subMenu: [
      { id: 1, title: 'Current Auctions', href: '/trade', enabled: true },
      { id: 2, title: 'Auctions History', href: '/trade', enabled: true },
      { id: 3, title: 'My Bids', href: '/trade', enabled: true },
      { id: 4, title: 'My Actions', href: '/trade', enabled: true },
      { id: 5, title: 'My Watched Actions', href: '/trade', enabled: true }
    ],
    isOpen: false
  },
  {
    id: 'support',
    label: LabelSupport,
    icon: IconSupport,
    subMenu: [
      { id: 1, title: 'FAQ', href: '/support', enabled: true },
      { id: 2, title: 'Tibia Rules', href: '/support', enabled: true },
      { id: 3, title: 'Parents`s Guide', href: '/support', enabled: true },
      { id: 4, title: 'Legal Documents', href: '/support', enabled: true }
    ],
    isOpen: false
  }
];

export { Menus };
