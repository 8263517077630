import * as moment from 'moment-mini';
import { Moment } from 'moment-mini';

export function dateParser(dateValue: number): Moment | null {
  if (!dateValue) {
    return null;
  }
  if (typeof dateValue === 'string') {
    return stringToDate(dateValue);
  }
  return moment.unix(dateValue);
}

function stringToDate(value: string): Moment {
  if (value.match(/\d{4}-\d\d-\d\d$/)) {
    const [year, month, day] = value.split('-').map(Number);

    return moment.utc(new Date(year, month - 1, day));
  }
  return moment.unix(new Date(value).getTime() / 1000.0);
}
