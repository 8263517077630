import { Box, CheckboxProps, Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getNestedErrors } from 'src/utils/getNestedErrors';
import { IInputValidator } from 'src/utils/interfaces/validations.interface';
import ErrorMessage from '../ErrorMessage';

interface ICheckboxProps extends CheckboxProps {
  name: string;
  validators?: IInputValidator;
  defaultChecked?: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  name,
  validators,
  defaultChecked = false,
  ...props
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const errorMessage = getNestedErrors(name, errors);

  return (
    <Box className="checkbox">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultChecked}
        rules={validators}
        render={({ field: { ...rest } }) => (
          <>
            <ChakraCheckbox
              {...rest}
              checked={rest.value}
              id={name.split(' ').join('-')}
              {...props}
            />
            {errorMessage ? <ErrorMessage message={errorMessage} /> : ''}
          </>
        )}
      />
    </Box>
  );
};

export default Checkbox;
