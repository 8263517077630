import { Streams } from 'src/utils/models/streams.model';
import { IApiResponse } from '../../utils/interfaces/apiResponse.interface';
import BaseHttpService from '../baseHttpService/BaseHttpService';

const baseHttpPlayersService = BaseHttpService.getInstance();

const basePath = '/external';

export async function getStreams(): Promise<IApiResponse<Streams>> {
  const apiOptions = {
    model: Streams,
    dataType: 'streams',
    path: `${basePath}/streams`
  };

  const response = await baseHttpPlayersService.fetch(apiOptions);

  if (response.data) {
    return {
      ...response,
      data: response.data
    };
  }

  throw new Error('No response');
}
