/* eslint-disable @typescript-eslint/no-explicit-any */
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-accountmanagement.gif';
import { Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { ICreateAccountPlayer, createPlayer } from 'src/services/accountService/AccountService';
import { PlayerSex } from 'src/utils/models/player.model';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import APIErrorMessage from 'src/components/APIErrorMessage';
import CreateCharacter from './components/CreateCharacter';

const NewPlayer = () => {
  const navigate = useNavigate();

  const [error, setError] = useState<string>();

  const methods = useForm<ICreateAccountPlayer>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      sex: PlayerSex.MALE
    }
  });

  const onSubmitEvent = methods.handleSubmit(async (form: ICreateAccountPlayer) => {
    setError(undefined);

    try {
      await createPlayer(form);
      navigate('/account/overview', { replace: true });
    } catch (err: any) {
      setError(err?.message);
    }
  });

  return (
    <Content headline={Title}>
      <Text marginBottom={4}>
        Please choose a name and sex for your character as well as the game world on which you want
        the character to live. If you cannot think of any fantasy names, click on the link below the
        name field to get some suggestions for a name.
      </Text>
      <Text marginBottom={8}>
        In any case the name must not violate the naming conventions stated in the Tibia Rules, or
        your character might get deleted or name locked.
      </Text>
      <FormProvider {...methods}>
        <form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            onSubmitEvent();
          }}
        >
          {error && <APIErrorMessage message={error} marginBottom="16px" />}
          <CreateCharacter />
        </form>
      </FormProvider>
    </Content>
  );
};

export default NewPlayer;
