import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptcha = (action?: string) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) {
      console.warn('Execute recaptcha not yet available');
      throw new Error('Recaptcha execution failed');
    }

    try {
      const token = await executeRecaptcha(action);
      return token;
    } catch (error) {
      console.error('Recaptcha execution failed', error);
      throw new Error('Recaptcha execution failed');
    }
  }, [executeRecaptcha, action]);

  return { getReCaptchaToken };
};

export default useRecaptcha;
