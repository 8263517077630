/* eslint-disable react/no-array-index-key */
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import CornerTL from 'src/assets/images/content/corner-tl.gif';
import CornerTR from 'src/assets/images/content/corner-tr.gif';
import Border1 from 'src/assets/images/content/border-1.gif';
import InfoBGGreen from 'src/assets/images/content/title-background-green.gif';
import BorderBL from 'src/assets/images/content/corner-bl.gif';
import BorderBR from 'src/assets/images/content/corner-br.gif';
import NewsTickerTitle from 'src/assets/images/strings/headline-newsticker.gif';
import PlusIcon from 'src/assets/images/general/plus.gif';
import MinusIcon from 'src/assets/images/general/minus.gif';
import { useQuery } from 'react-query';
import { getNews } from 'src/services/newsService/NewsService';
import moment from 'moment-mini';
import { NEWSTICKER_ICON } from 'src/utils/constants';
import { NewsType } from 'src/utils/models/news.model';

const NewsTickers = () => {
  const [newsTickerOpened, setNewsTickerOpened] = useState<number | undefined>();

  const newsResult = useQuery({
    queryFn: () => getNews({ type: NewsType.TICKER, itemsPerPage: 5 }),
    queryKey: ['newsTicker'],
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false
  });

  const news = newsResult.data?.data;

  return (
    <Box
      id="NewsTicker"
      className="Box"
      fontSize="0"
      position="relative"
      margin="5px"
      marginTop="4px"
      marginBottom="18px"
      color="#5a2800"
      borderLeft="2px solid #3a3738"
      borderRight="2px solid #3a3738"
      backgroundColor="#debb9d"
      backgroundRepeat="no-repeat"
      overflow="visible"
    >
      <Box
        className="Corner-tl"
        bgImage={CornerTL}
        position="absolute"
        top="-4px"
        left="-5px"
        width="17px"
        height="17px"
        zIndex="50"
      />
      <Box
        className="Corner-tr"
        bgImage={CornerTR}
        position="absolute"
        top="-4px"
        right="-5px"
        width="17px"
        height="17px"
        zIndex="50"
      />
      <Box
        className="Border_1"
        bgImage={Border1}
        position="relative"
        height="5px"
        width="100%"
        backgroundRepeat="repeat-x;"
      />
      <Box
        className="BorderTitleText"
        bgImage={InfoBGGreen}
        position="relative"
        height="27px"
        backgroundRepeat="repeat-x;"
        width="100%"
      >
        <Box
          id="ContentBoxHeadline"
          className="Title"
          position="relative"
          left="5px"
          width="250px"
          height="28px"
          bgImage={NewsTickerTitle}
        />
      </Box>
      <Box className="Border_2" margin="4px" padding="0" backgroundColor="#793d03">
        <Box
          className="Border_3"
          margin="1px"
          borderTop="1px solid #793d03"
          borderBottom="1px solid #793d03"
        >
          <Box
            className="BoxContent"
            padding="5px"
            minHeight="90px"
            height="auto !important"
            fontFamily="Verdana, Arial, Times New Roman, sans-serif"
            fontSize="9pt"
            backgroundColor="#fff2db"
            color="#5a2800"
          >
            <Box id="TickerEntry-0" className="Row" position="relative" width="100%">
              {news?.map((newTicker, index) => (
                <Flex
                  className={index % 2 === 0 ? 'Odd' : 'Even'}
                  padding="2px"
                  backgroundColor={index % 2 === 0 ? '#d4c0a1' : '#f1e0c6'}
                  onClick={() =>
                    setNewsTickerOpened(
                      newsTickerOpened === newTicker.id ? undefined : newTicker.id
                    )
                  }
                  key={index}
                  alignContent="center"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Box
                    className="NewsTickerIcon"
                    bgImage={NEWSTICKER_ICON[newTicker.category]}
                    position="relative"
                    left="2px"
                    height="16px"
                    width="16px"
                    backgroundRepeat="no-repeat"
                  />
                  <Box
                    className="NewsTickerText"
                    fontSize="9pt"
                    position="relative"
                    marginLeft="8px"
                  >
                    <Text
                      className="NewsTickerDate"
                      fontSize="9pt"
                      position="absolute"
                      top="0"
                      float="left"
                      as="span"
                    >
                      {moment.unix(newTicker.date).format('MMM DD YYYY')} -
                    </Text>
                    {newsTickerOpened === newTicker.id && (
                      <Text as="span" fontSize="9pt" position="absolute" top="16px" float="left">
                        {moment.unix(newTicker.date).format('hh:mm')}
                      </Text>
                    )}
                    <Box className="NewsTickerTextContainer" marginLeft="87px" marginRight="20px">
                      <Box
                        id="TickerEntry-0-ShortText"
                        className="NewsTickerShortText"
                        width="700px"
                        height="auto"
                        minHeight={newsTickerOpened === newTicker.id ? '35px' : 'initial'}
                      >
                        <Text isTruncated={newsTickerOpened !== newTicker.id} as="span">
                          {newTicker.body}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    id="TickerEntry-0-Button"
                    className="NewsTickerExtend"
                    bgImage={newsTickerOpened === newTicker.id ? MinusIcon : PlusIcon}
                    position="relative"
                    top="1px"
                    right="1px"
                    height="12px"
                    width="12px"
                    backgroundRepeat="no-repeat"
                    cursor="pointer"
                    zIndex="10"
                  />
                </Flex>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        className="Border_1"
        bgImage={Border1}
        position="relative"
        height="6px"
        width="100%"
        backgroundRepeat="repeat-x"
      />
      <Box className="CornerWrapper-b" position="absolute" width="100%">
        <Box
          className="Corner-bl"
          position="absolute"
          bottom="-4px"
          left="-6px"
          width="17px"
          height="17px"
          zIndex="50"
          bgImage={BorderBL}
        />
      </Box>
      <Box className="CornerWrapper-b" position="absolute" width="100%">
        <Box
          className="Corner-br"
          position="absolute"
          bottom="-4px"
          right="-6px"
          width="17px"
          height="17px"
          zIndex="50"
          bgImage={BorderBR}
        />
      </Box>
    </Box>
  );
};

export default NewsTickers;
