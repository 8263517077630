import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-accountmanagement.gif';
import AccountInfo from './components/GeneralInformation';
import History from './components/History';
import SectionsList from './components/SectionsList';
import ProductsAvailable from './components/ProductsAvailable';
import Registration from './components/Registration';

const AccountManagement = () => {
  return (
    <Content headline={Title}>
      <SectionsList />
      <AccountInfo />
      <ProductsAvailable />
      <History />
      <Registration />
    </Content>
  );
};

export default AccountManagement;
