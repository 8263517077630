import React, { useState, useCallback } from 'react';
import { Box } from '@chakra-ui/layout';

import BoxTop from 'src/assets/images/general/box-top.gif';
import BoxBottom from 'src/assets/images/general/box-bottom.gif';
import { Menus } from 'src/utils/constants';
import { MenuItemProps } from 'src/utils/interfaces/menu.interface';
import MenuItem from './MenuItem';

const MenuBox = () => {
  const [openedMenu, setOpenedMenu] = useState<MenuItemProps[]>(Menus);

  const handleMenuItem = useCallback((id: string) => {
    setOpenedMenu(prevMenus =>
      prevMenus.map(menu => (menu.id === id ? { ...menu, isOpen: !menu.isOpen } : menu))
    );
  }, []);

  return (
    <Box id="Menu" position="relative" left="5px" top="0" backgroundRepeat="no-repeat">
      <Box
        id="MenuTop"
        bgImage={BoxTop}
        position="absolute"
        left="-5px"
        top="-12px"
        height="12px"
        width="180px"
        backgroundRepeat="no-repeat"
      />
      {openedMenu.map(menu => (
        <MenuItem key={menu.id} menu={menu} setOpenedMenu={handleMenuItem} isOpen={menu.isOpen} />
      ))}
      <Box
        id="MenuBottom"
        bgImage={BoxBottom}
        position="relative"
        left="-5px"
        bottom="0"
        height="12px"
        width="180px"
        backgroundRepeat="no-repeat"
      />
    </Box>
  );
};

export default MenuBox;
