import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import CurrentPool from 'src/assets/images/themeboxes/current-poll/currentpollbox.gif';
import Button from 'src/assets/images/buttons/button_blue.gif';
import ButtonHover from 'src/assets/images/buttons/button_blue_over.gif';

const PollBox = () => {
  return (
    <Box
      id="CurrentPollBox"
      className="Themebox"
      bgImage={CurrentPool}
      height="154px"
      width="180px"
      position="relative"
      backgroundRepeat="no-repeat"
    >
      <Text
        id="CurrentPollText"
        fontFamily="Verdana, Arial, sans-serif"
        fontWeight="700"
        lineHeight="11pt"
        color="#5a2800"
        position="absolute"
        height="70px"
        width="150px"
        top="37px"
        left="15px"
        paddingTop="5px"
        overflow="hidden"
        textAlign="center"
      >
        Excited about the Summer Update?
      </Text>
      <Box
        position="absolute"
        bottom="20px"
        left="22px"
        height="25px"
        width="135px"
        cursor="pointer"
      >
        <Box
          id="BigButton"
          bgImage={Button}
          position="relative"
          width="135px"
          height="25px"
          cursor="pointer"
          zIndex="10"
          userSelect="none"
          backgroundRepeat="no-repeat"
        >
          <Text
            alignContent="center"
            left="0"
            width="135px"
            height="25px"
            margin="0"
            padding="0"
            cursor="pointer"
            background="0 0"
            border="none"
            textAlign="center"
            color="#ffd18c"
            fontFamily="Verdana, Arial, Times New Roman, sans-serif"
            fontSize="12px"
            fontWeight="400"
            zIndex="20"
            textShadow="-1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000"
            _hover={{ left: '1px', bgImage: ButtonHover, zIndex: 11 }}
          >
            Vote Now
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PollBox;
