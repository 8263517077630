import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import CaptionContainer from './CaptionContainer';
import InnerTableContainer from './InnerTableContainer';

interface ButtonProps extends BoxProps {
  title: string;
  tableBgColor?: string;
  tablePadding?: string;
  children: React.ReactNode;
}

const TableContainer = ({ title, children, tableBgColor = '#f1e0c5', ...props }: ButtonProps) => {
  return (
    <Box className="TableContainer" position="relative" fontSize="1px" width="100%" {...props}>
      <CaptionContainer title={title} />
      <Box
        className="Table4"
        width="100%"
        border="2px solid #55636c"
        backgroundColor={tableBgColor}
        fontSize="10pt"
        color="#5a2800"
      >
        <InnerTableContainer>{children}</InnerTableContainer>
      </Box>
    </Box>
  );
};

export default TableContainer;
