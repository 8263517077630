/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import TableContainer from 'src/components/TableContainer/TableContainer';
import Button from 'src/components/Button';
import CellInputField from 'src/components/TableContainer/CellInputField';
import AuthService, { IAccountLoginCredentials } from 'src/services/authService/AuthService';
import { useNavigate } from 'react-router';
import APIErrorMessage from 'src/components/APIErrorMessage';
import { useState } from 'react';
import InputLabel from 'src/components/Forms/InputLabel';
import Input from 'src/components/Forms/Input';
import useRecaptcha from 'src/utils/hooks/useRecaptcha';
import Authenticator from './Authenticator';

const authService = AuthService.getInstance();

interface LoginProps {
  email: string;
  password: string;
}

const LoginForm = () => {
  const { getReCaptchaToken } = useRecaptcha();

  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const methods = useForm<LoginProps>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmitEvent = methods.handleSubmit(async (form: LoginProps) => {
    setError(undefined);

    try {
      const recaptchaToken = await getReCaptchaToken();

      const payload: IAccountLoginCredentials = {
        email: form.email,
        password: form.password,
        recaptchaToken
      };

      await authService.login(payload);
      navigate('/account/overview', { replace: true });
    } catch (err: any) {
      setError(err?.message);
    }
  });

  return (
    <>
      {error && <APIErrorMessage message={error} marginBottom="16px" />}
      <TableContainer title="Account Login">
        <TableContentContainer marginBottom={2}>
          <FormProvider {...methods}>
            <form
              onSubmit={(e: React.SyntheticEvent) => {
                e.preventDefault();
                e.stopPropagation();
                onSubmitEvent();
              }}
            >
              <Table className="TableContent" width="100%">
                <Tbody>
                  <Tr className="LoginTable">
                    <Td>
                      <Box className="LoginBox" display="flex" flexWrap="wrap">
                        <InputLabel>Email Address:</InputLabel>
                        <CellInputField>
                          <Input name="email" maxLength={50} type="email" />
                        </CellInputField>
                        <Box className="Break" flexBasis="100%" height="5px" />
                        <InputLabel>Tibia Password:</InputLabel>
                        <CellInputField>
                          <Input name="password" maxLength={29} type="password" />
                        </CellInputField>
                      </Box>
                    </Td>
                    <Td className="LoginButtons" float="right">
                      <Button type="submit">Login</Button>
                      <Box className="Break" flexBasis="100%" height={1} />
                      <Button onClick={() => null}>Lost Account</Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </form>
          </FormProvider>
        </TableContentContainer>
        <Authenticator />
      </TableContainer>
    </>
  );
};

export default LoginForm;
