import React from 'react';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import BoxFrameVertical from 'src/assets/images/content/box-frame-vertical.gif';
import BoxFrameHorizontal from 'src/assets/images/content/box-frame-horizontal.gif';
import BoxFrameEdge from 'src/assets/images/content/box-frame-edge.gif';
import AttentionSign from 'src/assets/images/content/attentionsign.gif';

interface ErrorProps extends BoxProps {
  message: string;
}

const APIErrorMessage = ({ message, ...props }: ErrorProps) => {
  return (
    <Box className="SmallBox" position="relative" fontSize="1px" {...props}>
      <Box className="MessageContainer" position="relative" height="100%">
        <Box
          className="BoxFrameHorizontal"
          bgImage={BoxFrameHorizontal}
          position="relative"
          height="4px"
        />
        <Box
          className="BoxFrameEdgeLeftTop"
          bgImage={BoxFrameEdge}
          position="absolute"
          left="-1px"
          top="-1px"
          width="5px"
          height="5px"
        />
        <Box
          className="BoxFrameEdgeRightTop"
          bgImage={BoxFrameEdge}
          position="absolute"
          right="-1px"
          top="-1px"
          width="5px"
          height="5px"
        />
        <Box
          className="Message"
          fontSize="10pt"
          position="relative"
          height="100%"
          backgroundColor="#d4c0a1"
          padding="5px 10px"
        >
          <Box
            bgImage={BoxFrameVertical}
            className="BoxFrameVerticalLeft"
            position="absolute"
            left="0"
            top="0"
            width="3px"
            backgroundRepeat="repeat-y"
            height="100%"
          />
          <Box
            bgImage={BoxFrameVertical}
            className="BoxFrameVerticalRight"
            position="absolute"
            right="0"
            top="0"
            width="3px"
            backgroundRepeat="repeat-y"
            height="100%"
          />
          <Flex alignContent="center" alignItems="center">
            <Box
              className="AttentionSign"
              bgImage={AttentionSign}
              width="30px"
              height="26px"
              marginRight="8px"
            />
            <Box className="HintBox">
              <Text fontWeight="600" color="red" fontSize="8pt">
                The following error has occurred:
              </Text>
              <Text color="red" fontSize="8pt">
                {message}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          className="BoxFrameHorizontal"
          bgImage={BoxFrameHorizontal}
          position="relative"
          height="4px"
        />
        <Box
          className="BoxFrameEdgeRightBottom"
          bgImage={BoxFrameEdge}
          position="absolute"
          right="-1px"
          bottom="-1px"
          width="5px"
          height="5px"
        />
        <Box
          className="BoxFrameEdgeLeftBottom"
          bgImage={BoxFrameEdge}
          position="absolute"
          left="-1px"
          bottom="-1px"
          width="5px"
          height="5px"
        />
      </Box>
    </Box>
  );
};

export default APIErrorMessage;
