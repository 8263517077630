import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

interface CellInputFieldProps extends FlexProps {
  children: React.ReactNode;
}

const CellInputField: React.FC<CellInputFieldProps> = ({ children, ...props }) => (
  <Flex flexGrow={1} marginRight="7px" alignContent="center" alignItems="baseline" {...props}>
    {children}
  </Flex>
);

export default CellInputField;
