import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import BoxFrameVertical from 'src/assets/images/content/box-frame-vertical.gif';
import BoxFrameHorizontal from 'src/assets/images/content/box-frame-horizontal.gif';
import BoxFrameEdge from 'src/assets/images/content/box-frame-edge.gif';

interface ButtonProps extends BoxProps {
  margin?: string;
  children: React.ReactNode;
}

const Message = ({ children, margin, ...props }: ButtonProps) => {
  return (
    <Box className="MessageContainer" position="relative" height="100%" margin={margin}>
      <Box
        className="BoxFrameHorizontal"
        bgImage={BoxFrameHorizontal}
        position="relative"
        height="4px"
      />
      <Box
        className="BoxFrameEdgeLeftTop"
        bgImage={BoxFrameEdge}
        position="absolute"
        left="-1px"
        top="-1px"
        width="5px"
        height="5px"
      />
      <Box
        className="BoxFrameEdgeRightTop"
        bgImage={BoxFrameEdge}
        position="absolute"
        right="-1px"
        top="-1px"
        width="5px"
        height="5px"
      />
      <Box
        className="Message"
        fontSize="10pt"
        position="relative"
        height="100%"
        backgroundColor="#d4c0a1"
        padding="10px 10px 13px 10px"
        {...props}
      >
        <Box
          bgImage={BoxFrameVertical}
          className="BoxFrameVerticalLeft"
          position="absolute"
          left="0"
          top="0"
          width="3px"
          backgroundRepeat="repeat-y"
          height="100%"
        />
        <Box
          bgImage={BoxFrameVertical}
          className="BoxFrameVerticalRight"
          position="absolute"
          right="0"
          top="0"
          width="3px"
          backgroundRepeat="repeat-y"
          height="100%"
        />
        <Box className="HintBox" marginBottom="-3px">
          {children}
        </Box>
      </Box>
      <Box
        className="BoxFrameHorizontal"
        bgImage={BoxFrameHorizontal}
        position="relative"
        height="4px"
      />
      <Box
        className="BoxFrameEdgeRightBottom"
        bgImage={BoxFrameEdge}
        position="absolute"
        right="-1px"
        bottom="-1px"
        width="5px"
        height="5px"
      />
      <Box
        className="BoxFrameEdgeLeftBottom"
        bgImage={BoxFrameEdge}
        position="absolute"
        left="-1px"
        bottom="-1px"
        width="5px"
        height="5px"
      />
    </Box>
  );
};

export default Message;
