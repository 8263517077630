import { Box, Text } from '@chakra-ui/react';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import Button from 'src/components/Button';

const Authenticator = () => {
  return (
    <TableContentContainer>
      <Box padding="2px 5px" border="1px solid #faf0d7">
        <Box float="right" width="135px" marginLeft="10px">
          <Button>Use Authenticator</Button>
        </Box>
        <Text marginBottom="13px">
          If your Tibia Account is already connected to an authenticator app, click on &quot;Use
          Authenticator&quot;. A field will be displayed which allows you to provide your
          authenticator app token along with your account data upon login. Otherwise, you will be
          asked for your authenticator app token in the next step.
        </Text>
        <Text marginBottom="10px">
          An authenticator app is a security feature which helps to prevent any unauthorised access
          to your Tibia account! You can connect your account to an authenticator app via your
          account management page.
        </Text>
      </Box>
    </TableContentContainer>
  );
};

export default Authenticator;
