import React from 'react';
import Content from 'src/components/MainContent/Content';
import News from 'src/components/MainContent/News';
import NewsTickers from 'src/components/MainContent/NewsTickers';
import NewsHeadline from 'src/assets/images/strings/headline-news.gif';

const LatestNews = () => (
  <>
    <NewsTickers />
    <Content headline={NewsHeadline}>
      <News />
    </Content>
  </>
);

export default LatestNews;
