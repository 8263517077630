import React from 'react';
import { Box, Text } from '@chakra-ui/react';

interface InputLabelProps {
  children: React.ReactNode;
}

const InputLabel = ({ children }: InputLabelProps) => {
  return (
    <Box
      className="Cell LabelV120"
      flexBasis="150px"
      marginBottom="5px"
      fontWeight="700"
      paddingRight="10px"
      whiteSpace="nowrap"
      verticalAlign="top"
      width="120px"
    >
      <Text>{children}</Text>
    </Box>
  );
};

export default InputLabel;
