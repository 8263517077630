import { News } from '../../utils/models/news.model';
import { IApiResponse } from '../../utils/interfaces/apiResponse.interface';
import BaseHttpService from '../baseHttpService/BaseHttpService';

const baseHttpPlayersService = BaseHttpService.getInstance();

const basePath = '/news';

export async function getNews({
  type,
  category,
  itemsPerPage = 5
}: {
  type?: number;
  category?: number;
  itemsPerPage?: number;
}): Promise<IApiResponse<News[]>> {
  const apiOptions = {
    model: News,
    dataType: 'news',
    path: `${basePath}/list`,
    config: {
      params: {
        type,
        category,
        itemsPerPage
      }
    }
  };

  const response = await baseHttpPlayersService.getAll(apiOptions);

  if (response.data) {
    return {
      ...response,
      data: response.data
    };
  }

  throw new Error('No response');
}
