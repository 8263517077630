import 'reflect-metadata';

export enum Vocations {
  NONE = 0,
  SORCERER = 1,
  DRUID = 2,
  PALADIN = 3,
  KNIGHT = 4,
  MASTER_SORCERER = 5,
  ELDER_DRUID = 6,
  ROYAL_PALADIN = 7,
  ELITE_KNIGHT = 8
}

export enum PlayerSex {
  FEMALE = 0,
  MALE = 1
}

export class Player {
  id: number;

  name: string;

  groupId: number;

  accountId: number;

  level: number;

  vocation: Vocations;

  health: number;

  healthmax: number;

  experience: number;

  lookbody: number;

  lookfeet: number;

  lookhead: number;

  looklegs: number;

  looktype: number;

  lookaddons: number;

  maglevel: number;

  mana: number;

  manamax: number;

  manaspent: number;

  soul: number;

  townId: number;

  posx: number;

  posy: number;

  posz: number;

  conditions: Buffer;

  cap: number;

  sex: PlayerSex;

  pronoun: number;

  lastlogin: number;

  lastip: number;

  save: boolean;

  skull: boolean;

  skulltime: number;

  lastlogout: number;

  blessings: number;

  blessings1: number;

  blessings2: number;

  blessings3: number;

  blessings4: number;

  blessings5: number;

  blessings6: number;

  blessings7: number;

  blessings8: number;

  onlinetime: number;

  deletion: number;

  balance: number;

  offlinetrainingTime: number;

  offlinetrainingSkill: number;

  stamina: number;

  skillFist: number;

  skillFistTries: number;

  skillClub: number;

  skillClubTries: number;

  skillSword: number;

  skillSwordTries: number;

  skillAxe: number;

  skillAxeTries: number;

  skillDist: number;

  skillDistTries: number;

  skillShielding: number;

  skillShieldingTries: number;

  skillFishing: number;

  skillFishingTries: number;

  skillCriticalHitChance: number;

  skillCriticalHitChanceTries: number;

  skillCriticalHitDamage: number;

  skillCriticalHitDamageTries: number;

  skillLifeLeechChance: number;

  skillLifeLeechChanceTries: number;

  skillLifeLeechAmount: number;

  skillLifeLeechAmountTries: number;

  skillManaLeechChance: number;

  skillManaLeechChanceTries: number;

  skillManaLeechAmount: number;

  skillManaLeechAmountTries: number;

  skillCriticalhitChance: number;

  skillCriticalhitDamage: number;

  skillLifeleechChance: number;

  skillLifeleechAmount: number;

  skillManaleechChance: number;

  skillManaleechAmount: number;

  manashield: number;

  maxManashield: number;

  xpboostStamina: number | null;

  xpboostValue: number | null;

  marriageStatus: number;

  marriageSpouse: number;

  bonusRerolls: number;

  preyWildcard: number;

  taskPoints: number;

  quicklootFallback: boolean;

  lookmountbody: number;

  lookmountfeet: number;

  lookmounthead: number;

  lookmountlegs: number;

  lookfamiliarstype: number;

  isreward: boolean;

  istutorial: boolean;

  forgeDusts: number;

  forgeDustLevel: number;

  randomizeMount: boolean;

  bossPoints: number;

  constructor(args: Partial<Player>) {
    Object.assign(this, args);
  }
}
