import { Box, Flex, Text } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-accountmanagement.gif';
import CreateAccountText from 'src/assets/images/buttons/mediumbutton_createaccount.png';
import BigButton from 'src/components/BigButton';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import TableContainer from 'src/components/TableContainer/TableContainer';
import ImportantInformation from './ImportantInformation';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Content headline={Title}>
      <ImportantInformation />
      <LoginForm />
      <Text as="h1" textAlign="center" marginTop="28px" marginBottom="12px">
        New to Tibia?
      </Text>
      <TableContainer title="New Player">
        <TableContentContainer>
          <Flex
            padding="2px 5px"
            border="1px solid #faf0d7"
            justifyContent="space-between"
            width="100%"
            alignContent="center"
            alignItems="center"
          >
            <Box>
              <Text fontWeight={600} fontSize="12pt">
                Tibia...
              </Text>
              <Box marginLeft="10px">
                <Text fontSize="12pt">... where hardcore gaming meets fantasy.</Text>
                <Text fontSize="12pt">... where friendships last a lifetime.</Text>
                <Text fontSize="12pt">... unites adventurers since 1997!</Text>
              </Box>
            </Box>
            <Box float="right" marginTop="2px">
              <BigButton src={CreateAccountText} />
            </Box>
          </Flex>
        </TableContentContainer>
      </TableContainer>
    </Content>
  );
};

export default Login;
