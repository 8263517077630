import React from 'react';
import { Box, Link } from '@chakra-ui/react';
import artworkTop from 'src/assets/images/header/tibia-logo-artwork-top.gif';

const LogoArtwork = () => {
  return (
    <Box
      id="LeftArtwork"
      position="absolute"
      height="140px"
      width="166px"
      top="-140px"
      left="4px"
      backgroundRepeat="no-repeat"
      zIndex="5"
    >
      <Link href="/">
        <Box
          id="TibiaLogoArtworkTop"
          position="absolute"
          top="-17px"
          left="-14px"
          height="158px"
          width="196px"
          zIndex="5"
          cursor="pointer"
          border="0"
          bgImage={artworkTop}
        />
      </Link>
    </Box>
  );
};

export default LogoArtwork;
