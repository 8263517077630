import { Player, PlayerSex } from 'src/utils/models/player.model';
import { IRecaptcha } from 'src/utils/interfaces/recaptcha.interface';
import { IApiResponse } from '../../utils/interfaces/apiResponse.interface';
import BaseHttpService from '../baseHttpService/BaseHttpService';

export interface ICreateAccountPlayer {
  name: string;
  sex: PlayerSex;
}

export interface ICreateAccount extends IRecaptcha {
  email: string;
  password: string;
  password2: string;
  agreement: boolean;
  worldId: number;
  player: {
    name: string;
    sex: PlayerSex;
  };
}

const baseHttpService = BaseHttpService.getInstance();

const basePath = '/account';

export async function createAccount(body: ICreateAccount): Promise<IApiResponse<Player>> {
  const apiOptions = {
    model: Player,
    dataType: 'account',
    path: `${basePath}/create`,
    body
  };

  const response = await baseHttpService.create(apiOptions);

  if (response.data) {
    return {
      ...response,
      data: response.data
    };
  }

  throw new Error('No response');
}

export async function createPlayer(body: ICreateAccountPlayer): Promise<IApiResponse<Player>> {
  const apiOptions = {
    model: Player,
    dataType: 'players',
    path: `${basePath}/players/create`,
    body: { ...body }
  };

  const response = await baseHttpService.create(apiOptions);

  if (response.data) {
    return {
      ...response,
      data: response.data
    };
  }

  throw new Error('No response');
}

export async function getAccountPlayers(): Promise<IApiResponse<Player[]>> {
  const apiOptions = {
    model: Player,
    dataType: 'players',
    path: `${basePath}/players`
  };

  const response = await baseHttpService.getAll(apiOptions);

  if (response.data) {
    return {
      ...response,
      data: response.data
    };
  }

  throw new Error('No response');
}
