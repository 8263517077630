/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import CornerTL from 'src/assets/images/content/corner-tl.gif';
import CornerTR from 'src/assets/images/content/corner-tr.gif';
import Border1 from 'src/assets/images/content/border-1.gif';
import InfoBG from 'src/assets/images/content/cacheinfo_background.gif';
import IconTwitch from 'src/assets/images/info/icon-twitch.png';
import IconStreamer from 'src/assets/images/info/icon-streamers.png';
import IconYoutube from 'src/assets/images/info/icon-youtube.png';
import IconDownload from 'src/assets/images/info/icon-download.png';
import IconPlayersOnline from 'src/assets/images/info/icon-players-online.png';
import BorderBL from 'src/assets/images/content/corner-bl.gif';
import BorderBR from 'src/assets/images/content/corner-br.gif';
import { getStreams } from 'src/services/externalService/ExternalService';
import { Streams } from 'src/utils/models/streams.model';
import { getOnlineTotal } from 'src/services/onlineService/OnlineService';

const InfoBar = () => {
  const playersOnlineResult = useQuery({
    queryFn: () => getOnlineTotal(),
    queryKey: ['playersOnline'],
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false
  });

  const twitchStreamsResult = useQuery({
    queryFn: () => getStreams(),
    queryKey: ['streams'],
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false
  });

  const { data: playersData } = playersOnlineResult;
  const { data: twitchData } = twitchStreamsResult;

  const playersOnline = playersData?.data?.total || 0;
  const streams: Streams = twitchData?.data || { twitch: { views: 0, streams: 0 } };

  return (
    <Box
      className="Box"
      fontSize="0"
      position="relative"
      margin="5px"
      marginTop="4px"
      marginBottom="18px"
      color="#5a2800"
      borderLeft="2px solid #3a3738"
      borderRight="2px solid #3a3738"
      backgroundColor="#debb9d"
      backgroundRepeat="no-repeat"
      overflow="visible"
    >
      <Box
        className="Corner-tl"
        bgImage={CornerTL}
        position="absolute"
        top="-4px"
        left="-5px"
        width="17px"
        height="17px"
        zIndex="50"
      />
      <Box
        className="Corner-tr"
        bgImage={CornerTR}
        position="absolute"
        top="-4px"
        right="-5px"
        width="17px"
        height="17px"
        zIndex="50"
      />
      <Box
        className="Border_1"
        bgImage={Border1}
        position="relative"
        height="6px"
        width="100%"
        backgroundRepeat="repeat-x;"
      />
      <Box
        className="BorderTitleText"
        bgImage={InfoBG}
        position="relative"
        height="28px"
        backgroundRepeat="repeat-x;"
        width="100%"
      >
        <Box
          className="InfoBar"
          position="relative"
          height="28px"
          top="6px"
          marginLeft="5px"
          marginRight="5px"
          fontSize="10px"
          color="#fff"
          fontFamily="Verdana, Arial, Times New Roman, sans-serif;"
        >
          <Flex alignContent="center" alignItems="center">
            <Link
              href="https://www.twitch.tv/directory/category/tibia"
              as="a"
              isExternal
              className="InfoBarBlock"
              color="#fff"
              fontWeight="400"
              marginRight="15px"
              textDecoration="none"
            >
              <Flex
                className="InfoBarNumbers"
                as="span"
                color="white"
                fontWeight={400}
                position="relative"
                alignContent="center"
                alignItems="center"
              >
                <Image className="InfoBarBigLogo NotOnMobile" src={IconTwitch} border={0} />
                <Image
                  className="InfoBarSmallElement"
                  src={IconStreamer}
                  marginLeft="10px"
                  border={0}
                  overflow="clip"
                />
                <Text className="InfoBarSmallElement" marginLeft="5px" border={0} as="span">
                  {streams.twitch.streams}
                </Text>

                <Image
                  className="InfoBarSmallElement"
                  src={IconStreamer}
                  marginLeft="10px"
                  border={0}
                />
                <Text className="InfoBarSmallElement" marginLeft="5px" border={0} as="span">
                  {streams.twitch.views}
                </Text>
              </Flex>
            </Link>

            <Link
              href="/"
              as="a"
              className="InfoBarBlock"
              color="#fff"
              fontWeight="400"
              marginRight="15px"
              textDecoration="none"
            >
              <Flex alignItems="center" alignContent="center">
                <Image className="InfoBarBigLogo NotOnMobile" src={IconYoutube} border={0} />
                <Flex
                  className="InfoBarNumbers"
                  color="white"
                  fontWeight={400}
                  position="relative"
                  alignItems="center"
                  alignContent="center"
                >
                  <Image
                    className="InfoBarSmallElement"
                    src={IconStreamer}
                    marginLeft="10px"
                    border={0}
                  />
                  <Text className="InfoBarSmallElement" marginLeft="5px" border={0} as="span">
                    12
                  </Text>

                  <Image
                    className="InfoBarSmallElement"
                    src={IconStreamer}
                    marginLeft="10px"
                    border={0}
                  />
                  <Text className="InfoBarSmallElement" marginLeft="5px" border={0} as="span">
                    17
                  </Text>
                </Flex>
              </Flex>
            </Link>

            <Link
              href="/"
              as="a"
              className="NotOnMobile"
              color="#fff"
              fontWeight="400"
              textDecoration="none"
            >
              <Flex className="InfoBarBigLogo" alignContent="center" alignItems="center">
                <Image src={IconDownload} border={0} />
                <Text className="InfoBarSmallElement" marginLeft="10px" border={0} as="span">
                  Fankit
                </Text>
              </Flex>
            </Link>

            <Link href="/" as="a" color="#fff" fontWeight="400" textDecoration="none" width="60%">
              <Flex
                className="InfoBarBigLogo"
                alignContent="center"
                alignItems="center"
                justifyContent="end"
                width="100%"
              >
                <Image src={IconPlayersOnline} border={0} />
                <Text className="InfoBarSmallElement" marginLeft="10px" border={0} as="span">
                  {playersOnline} Player{playersOnline > 1 ? 's' : ''} Online
                </Text>
              </Flex>
            </Link>
          </Flex>
        </Box>
      </Box>
      <Box
        className="Border_1"
        bgImage={Border1}
        position="relative"
        height="6px"
        width="100%"
        backgroundRepeat="repeat-x"
      />
      <Box className="CornerWrapper-b" position="absolute" width="100%">
        <Box
          className="Corner-bl"
          position="absolute"
          bottom="-4px"
          left="-6px"
          width="17px"
          height="17px"
          zIndex="50"
          bgImage={BorderBL}
        />
      </Box>
      <Box className="CornerWrapper-b" position="absolute" width="100%">
        <Box
          className="Corner-br"
          position="absolute"
          bottom="-4px"
          right="-6px"
          width="17px"
          height="17px"
          zIndex="50"
          bgImage={BorderBR}
        />
      </Box>
    </Box>
  );
};

export default InfoBar;
