import 'reflect-metadata';

export class Streams {
  twitch: {
    views: number;
    streams: number;
  };

  constructor(args: Partial<Streams>) {
    Object.assign(this, args);
  }
}
