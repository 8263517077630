import { RouterProvider } from 'react-router';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { router } from './routes';
import { dependencies } from './utils/contexts/di/Dependencies';
import { DependenciesProvider } from './utils/contexts/di/DependenciesContext';
import { theme } from './theme/theme';

const queryClient = new QueryClient();

export const App = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}>
    <DependenciesProvider value={dependencies}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </QueryClientProvider>
    </DependenciesProvider>
  </GoogleReCaptchaProvider>
);
