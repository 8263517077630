export const getNestedErrors = (
  name: string,
  errors: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
  }
) => {
  const nestedAttributes = name.split('.');
  if (nestedAttributes.length > 0) {
    let attributes = errors[nestedAttributes[0]];
    if (attributes) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < nestedAttributes.length; i++) {
        if (attributes[nestedAttributes[i]]) {
          attributes = attributes[nestedAttributes[i]];
        } else {
          return false;
        }
      }
    }
    return attributes?.message;
  }
  return false;
};
