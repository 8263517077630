import 'reflect-metadata';

export enum NewsType {
  NEWS = 1,
  TICKER = 2,
  ARTICLE = 3
}

export enum NewsCategory {
  TECHNICAL_ISSUES = 1,
  SUPPORT = 2,
  DEVELOPMENT = 3,
  COMMUNITY = 4
}

export class News {
  id: number;

  title: string;

  body: string;

  type: NewsType;

  date: number;

  category: NewsCategory;

  player_id: number;

  last_modified_by: number;

  last_modified_date: number;

  comments: string;

  article_text?: string;

  article_image?: string;

  hidden?: number;

  constructor(args: Partial<News>) {
    Object.assign(this, args);
  }
}
