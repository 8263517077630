import { Transform } from 'class-transformer';
import { Moment } from 'moment-mini';
import 'reflect-metadata';
import { dateParser } from '../dateParser';

export type TRoles = AccountRoles[];

export enum AccountRoles {
  ACCOUNT_TYPE_NORMAL = 1,
  ACCOUNT_TYPE_TUTOR = 2,
  ACCOUNT_TYPE_SENIORTUTOR = 3,
  ACCOUNT_TYPE_GAMEMASTER = 4,
  ACCOUNT_TYPE_GOD = 5
}

interface AddressProps {
  street?: string;

  number?: string;

  complement?: string;

  zipCode?: string;

  city?: string;

  country?: string;

  state?: string;
}

export class Account {
  id: number;

  accessToken: string;

  name: string;

  email: string;

  firstName: string;

  lastName: string;

  address: AddressProps;

  mobileNumber: string;

  isVerified: boolean;

  premdays: number;

  premdaysPurchased: number;

  lastday: number;

  type: AccountRoles;

  coins: number;

  coinsTransferable: number;

  tournamentCoins: number;

  @Transform(value => dateParser(value), { toClassOnly: true })
  creation: Moment;

  recruiter: number;

  constructor(args: Partial<Account>) {
    Object.assign(this, args);
  }
}
