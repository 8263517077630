/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  Image,
  Link,
  RadioGroup,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr
} from '@chakra-ui/react';
import { faker } from '@faker-js/faker';
import TableContainer from 'src/components/TableContainer/TableContainer';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import CellInputField from 'src/components/TableContainer/CellInputField';
import { useFormContext } from 'react-hook-form';
import { PlayerSex } from 'src/utils/models/player.model';
import { useNavigate } from 'react-router';
import { ICreateAccountPlayer } from 'src/services/accountService/AccountService';
import Button from 'src/components/Button';
import { WorldCategories, WorldLocations, WorldTypes } from 'src/utils/interfaces/worlds.interface';
import WorldAll from 'src/assets/images/content/option_server_location_all.png';
import WorldSouthAmerica from 'src/assets/images/content/option_server_location_bra.png';
import WorldType from 'src/assets/images/content/option_server_pvp_type_optional.gif';
import Input from 'src/components/Forms/Input';
import Radio from 'src/components/Forms/Radio';

const CreateCharacter = () => {
  const navigate = useNavigate();

  const methods = useFormContext<ICreateAccountPlayer>();

  const generateNewName = () => {
    const generatedName = `${faker.person.firstName()} ${faker.person.lastName()}`;
    methods.setValue('name', generatedName);
  };

  return (
    <>
      <TableContainer title="Create Character" marginBottom="16px">
        <TableContentContainer marginBottom={2}>
          <Table>
            <Tbody>
              <Tr fontWeight={700} whiteSpace="nowrap" bgColor="#d5c0a1">
                <Td border="1px solid #faf0d7" width="50%">
                  Name
                </Td>
                <Td border="1px solid #faf0d7">Sex</Td>
              </Tr>
              <Tr fontWeight={700} whiteSpace="nowrap" bgColor="rgb(241, 224, 198)">
                <Td border="1px solid #faf0d7" width="50%">
                  <CellInputField>
                    <Box>
                      <Input name="name" maxLength={29} />
                      <Box>
                        [
                        <Text
                          fontSize="smaller"
                          onClick={() => generateNewName()}
                          cursor="pointer"
                          as="span"
                          color="#004294"
                        >
                          Suggest Name
                        </Text>
                        ]
                      </Box>
                    </Box>
                  </CellInputField>
                </Td>
                <Td border="1px solid #faf0d7">
                  <RadioGroup defaultValue={PlayerSex.MALE.toString()}>
                    <Stack spacing={1} direction="column">
                      <Radio
                        id={PlayerSex.MALE.toString()}
                        size="md"
                        name="sex"
                        value={PlayerSex.MALE.toString()}
                      >
                        Male
                      </Radio>
                      <Radio
                        id={PlayerSex.FEMALE.toString()}
                        size="md"
                        name="sex"
                        value={PlayerSex.FEMALE.toString()}
                      >
                        Female
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContentContainer>
        <TableContentContainer>
          <Table>
            <Tbody>
              <Tr bgColor="#D4C0A1">
                <Td padding="2px 5px" border="1px solid #faf0d7" width="100%">
                  <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                    Game World
                  </Text>
                </Td>
              </Tr>
              <Tr bgColor="#D4C0A1">
                <Td border="1px solid #faf0d7" padding="1rem">
                  <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                    Filter by game world category
                  </Text>
                  <Text>
                    There are different game world categories to choose from. Regular game worlds
                    are the default category, where all game features work as described in the{' '}
                    <Link href="/gameguides/manual">manual</Link>.
                  </Text>
                  <Text>
                    Experimental game worlds are special game worlds, which are sometimes used to
                    test new features. Characters on these game worlds can be played normally,
                    though they may be affected by restrictions during tests.
                  </Text>
                  <Text>
                    For details on experimental worlds, please see the corresponding{' '}
                    <Link href="/gameguides/manual">manual</Link> section.
                  </Text>
                  <Text>
                    Finally, you can choose if you like to play on a game world which has been
                    protected by BattlEye from the start. BattlEye is a software that prevents the
                    use of cheat software. It is running in the background while you are playing
                    Tibia. For more information, please have a look at our{' '}
                    <Link href="/support">FAQ</Link>.
                  </Text>
                  <RadioGroup
                    defaultValue={WorldCategories.REGULAR.toString()}
                    padding="2rem 3rem 1rem"
                  >
                    <Stack spacing={1} direction="row">
                      <Radio
                        id={WorldCategories.REGULAR.toString()}
                        size="md"
                        name="worldCategory"
                        value={WorldCategories.REGULAR.toString()}
                      >
                        Regular Game World
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Td>
              </Tr>
              <Tr bgColor="#D4C0A1">
                <Td padding="1rem" border="1px solid #faf0d7">
                  <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                    Filter by game world location
                  </Text>
                  <Text marginBottom={4}>
                    For a good connection and with this the best possible gaming experience, please
                    select a game world that is situated nearest your own location.
                  </Text>
                  <RadioGroup defaultValue={WorldLocations.ALL.toString()} padding="2rem 3rem 1rem">
                    <Stack spacing={12} direction="row">
                      <Flex justifyContent="center" flexDirection="column" alignItems="center">
                        <Image src={WorldAll} border={0} marginBottom={1} width="48px" />
                        <Radio
                          id={WorldLocations.ALL.toString()}
                          size="md"
                          name="worldLocation"
                          value={WorldLocations.ALL.toString()}
                        >
                          All
                        </Radio>
                      </Flex>
                      <Flex justifyContent="center" flexDirection="column" alignItems="center">
                        <Image src={WorldSouthAmerica} border={0} marginBottom={1} width="48px" />
                        <Radio
                          id={WorldLocations.SOUTH_AMERICA.toString()}
                          size="md"
                          name="worldLocation"
                          value={WorldLocations.SOUTH_AMERICA.toString()}
                        >
                          South America
                        </Radio>
                      </Flex>
                    </Stack>
                  </RadioGroup>
                </Td>
              </Tr>
              <Tr bgColor="#D4C0A1">
                <Td padding="1rem" border="1px solid #faf0d7">
                  <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                    Filter by game world PvP type
                  </Text>
                  <Text>
                    There are five different game world types that differ in the strictness of PvP
                    rules. For a detailed description, please have a look at our{' '}
                    <Link href="/gameguides/manual">manual</Link>. If you cannot decide for a game
                    world type, we recommend playing on optional PvP.
                  </Text>
                  <RadioGroup defaultValue={WorldTypes.OPTIONAL.toString()} padding="2rem">
                    <Stack spacing={12} direction="row">
                      <Flex justifyContent="center" flexDirection="column" alignItems="center">
                        <Image src={WorldType} border={0} marginBottom={1} width="48px" />
                        <Radio
                          id={WorldTypes.OPTIONAL.toString()}
                          size="md"
                          name="worldType"
                          value={WorldTypes.OPTIONAL.toString()}
                        >
                          <Text fontWeight={700}>Optional PvP</Text>
                        </Radio>
                        <Text>Only if both sides agree, characters can be fought</Text>
                      </Flex>
                    </Stack>
                  </RadioGroup>
                </Td>
              </Tr>
              <Tr bgColor="#f1e0c6">
                <Td padding="1rem" border="1px solid #faf0d7">
                  <RadioGroup defaultValue="Ironman">
                    <Stack spacing={12} direction="row">
                      <Flex justifyContent="center" flexDirection="column" alignItems="center">
                        <Radio id="Ironman" size="md" name="worldName" value="Ironman">
                          Ironman
                        </Radio>
                      </Flex>
                    </Stack>
                  </RadioGroup>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContentContainer>
      </TableContainer>
      <Flex justifyContent="space-around">
        <Button type="submit">Submit</Button>
        <Button onClick={() => navigate(-1)}>Back</Button>
      </Flex>
    </>
  );
};

export default CreateCharacter;
