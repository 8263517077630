import { Flex, Image, Link, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import TableContainer from 'src/components/TableContainer/TableContainer';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import DailyRewardNotColleted from 'src/assets/images/content/icon-status-dailyreward-notcollected.png';
import CharHidden from 'src/assets/images/content/icon-status-hidden.png';
import { useQuery } from 'react-query';
import { useAccountContext } from 'src/utils/contexts/AccountContext';
import { Player } from 'src/utils/models/player.model';
import { getVocationName } from 'src/utils/dataParsers';
import { useState } from 'react';
import Button from 'src/components/Button';
import { useNavigate } from 'react-router';
import { getAccountPlayers } from 'src/services/accountService/AccountService';

const DownloadClient = () => {
  const navigate = useNavigate();
  const { account } = useAccountContext();

  const [selectedCharacter, setSelectedCharacter] = useState<number>();

  const playersResult = useQuery({
    queryFn: () => getAccountPlayers(),
    queryKey: ['players'],
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!account?.id,
    onSuccess: characters => {
      setSelectedCharacter(characters.data[0].id);
    }
  });

  const { data: charactersData } = playersResult;

  const characters = charactersData?.data || [];

  return (
    <TableContainer title="Characters" marginBottom="16px">
      <TableContentContainer>
        <Table>
          <Tbody>
            <Tr fontWeight={700} whiteSpace="nowrap" bgColor="#d5c0a1">
              <Td width="26px" border="1px solid #faf0d7" />
              <Td border="1px solid #faf0d7">Name</Td>
              <Td width="100px" border="1px solid #faf0d7">
                Status
              </Td>
              <Td width="100px" />
            </Tr>
            {characters.map((character: Player, index) => (
              <Tr
                fontWeight={selectedCharacter === character.id ? 700 : 400}
                whiteSpace="nowrap"
                bgColor={index % 2 ? 'rgb(213, 192, 161)' : 'rgb(241, 224, 198)'}
                onClick={() => setSelectedCharacter(character.id)}
                cursor="pointer"
                key={character.id}
              >
                <Td width="26px" border="1px solid #faf0d7">
                  {index + 1}.
                </Td>
                <Td border="1px solid #faf0d7" padding="12px 8px">
                  <Text
                    lineHeight="12px"
                    verticalAlign="middle"
                    whiteSpace="nowrap"
                    fontSize={selectedCharacter === character.id ? '13pt' : 'smaller'}
                    marginBottom="12px"
                  >
                    {character.name}
                  </Text>
                  <Text
                    lineHeight="12px"
                    verticalAlign="middle"
                    whiteSpace="nowrap"
                    fontSize="smaller"
                  >
                    {getVocationName(character.vocation)} - Level {character.level} - On Ironman
                  </Text>
                </Td>
                <Td width="100px" border="1px solid #faf0d7">
                  <Flex>
                    <Image
                      src={DailyRewardNotColleted}
                      alt="Daily Reward"
                      height="22px"
                      width="11px"
                      marginRight="4px"
                    />
                    <Image src={CharHidden} alt="Daily Reward" height="22px" width="11px" />
                  </Flex>
                </Td>
                <Td width="100px" border="1px solid #faf0d7">
                  <Flex
                    flexDir="column"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    display={selectedCharacter === character.id ? 'flex' : 'none'}
                  >
                    <Text>
                      [<Link href="/#">Edit</Link>]
                    </Text>
                    <Text>
                      [<Link href="/#">Delete</Link>]
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContentContainer>
      <Flex justifyContent="right">
        <Button marginTop={2} onClick={() => navigate('/account/player/new')}>
          Create Chatacter
        </Button>
      </Flex>
    </TableContainer>
  );
};

export default DownloadClient;
