/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box } from '@chakra-ui/react';
import chain from 'src/assets/images/general/chain.gif';
import loginBox from 'src/assets/images/loginbox/loginbox-textfield-background.gif';
import CreateAccountText from 'src/assets/images/loginbox/loginbox-font-create-account.gif';
import CreateAccountTextHover from 'src/assets/images/loginbox/loginbox-font-create-account-over.gif';
import LogoutText from 'src/assets/images/loginbox/loginbox-font-logout.gif';
import LogoutTextHover from 'src/assets/images/loginbox/loginbox-font-logout-over.gif';
import MyAccountText from 'src/assets/images/buttons/mediumbutton_myaccount.png';
import BoxBottom from 'src/assets/images/general/box-bottom.gif';
import LoginButton from 'src/assets/images/buttons/mediumbutton_login.png';
import BoxTop from 'src/assets/images/general/box-top.gif';
import { useNavigate } from 'react-router';
import { useDependencies } from 'src/utils/contexts/di/DependenciesContext';
import AuthService from 'src/services/authService/AuthService';
import Button from '../BigButton';

const authService = AuthService.getInstance();

const LoginBox = () => {
  const navigate = useNavigate();
  const { useAuth } = useDependencies();
  const user = useAuth();

  const isLoggedIn = user.isUserAuthenticated();

  const handleCreateAccount = () => {
    return navigate('/account/create');
  };

  const handleLogout = async () => {
    try {
      await authService.logout();
      navigate('/logout');
    } catch (err: any) {
      console.error(err?.message);
    }
  };

  return (
    <Box
      className="SmallMenuBox"
      position="relative"
      left="5px"
      top="8px"
      width="100%"
      marginBottom="38px"
      backgroundRepeat="no-repeat"
    >
      <Box
        className="SmallBoxTop"
        bgImage={BoxTop}
        position="absolute"
        left="-5px"
        top="-12px"
        height="12px"
        width="180px"
        backgroundRepeat="no-repeat"
      />
      <Box
        className="SmallBoxBorder"
        bgImage={chain}
        position="absolute"
        top="0"
        height="52px"
        width="8px"
        backgroundRepeat="repeat-y"
        zIndex="10"
      />
      <Box
        className="SmallBoxButtonContainer"
        bgImage={loginBox}
        position="relative"
        height="39px"
        width="160px"
        left="5px"
        backgroundRepeat="repeat-y"
        zIndex="1"
      >
        <Box
          id="PlayNowContainer"
          position="absolute"
          width="150px"
          height="37px"
          top="1px"
          left="5px"
          zIndex="99"
        >
          <Button
            src={isLoggedIn ? MyAccountText : LoginButton}
            onClick={() => navigate(isLoggedIn ? '/account/overview' : '/account/login')}
          />
        </Box>
      </Box>
      <Box
        className="Loginstatus"
        bgImage={loginBox}
        position="relative"
        top="0"
        left="5px"
        height="13px"
        width="160px"
      >
        <Box id="LoginstatusText">
          <Box
            id="LoginstatusText_1"
            bgImage={isLoggedIn ? LogoutText : CreateAccountText}
            position="absolute"
            top="1px"
            left="18px"
            width="124px"
            height="11px"
            cursor="pointer"
            _hover={{
              position: 'absolute',
              top: '1px',
              left: '18px',
              width: '124px',
              height: '11px',
              cursor: 'pointer',
              bgImage: isLoggedIn ? LogoutTextHover : CreateAccountTextHover
            }}
            onClick={() => (isLoggedIn ? handleLogout() : handleCreateAccount())}
          />
        </Box>
      </Box>
      <Box
        className="SmallBoxBorder BorderRight"
        bgImage={chain}
        position="absolute"
        top="0"
        height="52px"
        width="8px"
        backgroundRepeat="repeat-y"
        zIndex="10"
        right="9px"
      />
      <Box
        className="Loginstatus SmallBoxBottom"
        bgImage={BoxBottom}
        position="absolute"
        top="52px"
        left="-5px"
        height="12px"
        width="180px"
        backgroundRepeat="no-repeat"
      />
    </Box>
  );
};

export default LoginBox;
