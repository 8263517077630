import 'reflect-metadata';
import { BoostedBoss, BoostedCreature } from '../interfaces/boosted.interface';

export class Boosted {
  boss: BoostedBoss;

  creature: BoostedCreature;

  constructor(args: Partial<Boosted>) {
    Object.assign(this, args);
  }
}
