import React from 'react';
import { Box, Text, Button as ButtonChakra, ButtonProps } from '@chakra-ui/react';
import ButtonBlue from 'src/assets/images/buttons/button_blue.gif';
import ButtonBlueHover from 'src/assets/images/buttons/button_blue_over.gif';
import ButtonGreen from 'src/assets/images/buttons/button_green.gif';
import ButtonGreenHover from 'src/assets/images/buttons/button_green_over.gif';
import ButtonRed from 'src/assets/images/buttons/button_red.gif';
import ButtonRedHover from 'src/assets/images/buttons/button_red_over.gif';

interface IButtonProps extends ButtonProps {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'success' | 'warning' | 'default';
  onClick?: () => void;
}

const getButtonCalor = (variant: string) => {
  if (variant === 'success') {
    return { image: ButtonGreen, imageHover: ButtonGreenHover };
  }

  if (variant === 'warning') {
    return { image: ButtonRed, imageHover: ButtonRedHover };
  }

  if (variant === 'default') {
    return { image: ButtonBlue, imageHover: ButtonBlueHover };
  }

  return { image: ButtonBlue, imageHover: ButtonBlueHover };
};

const Button = ({
  children,
  type = 'button',
  variant = 'default',
  onClick,
  ...props
}: IButtonProps) => {
  return (
    <ButtonChakra size="small" type={type} {...props}>
      <Box
        bgImage={getButtonCalor(variant).image}
        position="relative"
        width="135px"
        height="25px"
        cursor="pointer"
        zIndex={10}
        onClick={onClick}
      >
        <Box
          position="absolute"
          width="135px"
          height="25px"
          cursor="pointer"
          _hover={{
            bgImage: getButtonCalor(variant).imageHover,
            zIndex: 11
          }}
        >
          <Text
            position="absolute"
            top="6px"
            width="135px"
            height="25px"
            margin="0"
            padding="0"
            cursor="pointer"
            background="0 0"
            border="none"
            textAlign="center"
            color="#ffd18c"
            fontFamily="Verdana, Arial, Times New Roman, sans-serif"
            fontSize="12px"
            fontWeight="400"
            zIndex="20"
            textShadow="-1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000"
            _hover={{
              top: '7px',
              left: '1px'
            }}
          >
            {children}
          </Text>
        </Box>
      </Box>
    </ButtonChakra>
  );
};

export default Button;
