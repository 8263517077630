import { Box, RadioGroup, Stack, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { faker } from '@faker-js/faker';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import CellInputField from 'src/components/TableContainer/CellInputField';
import { PlayerSex } from 'src/utils/models/player.model';
import { ICreateAccount } from 'src/services/accountService/AccountService';
import { validateCharacterName } from 'src/utils/validations/player';
import { useFormContext } from 'react-hook-form';
import Input from 'src/components/Forms/Input';
import InputLabel from 'src/components/Forms/InputLabel';
import Radio from 'src/components/Forms/Radio';

const PlayerForm = () => {
  const methods = useFormContext<ICreateAccount>();

  const generateNewName = () => {
    const generatedName = `${faker.person.firstName()} ${faker.person.lastName()}`;
    methods.setValue('player.name', generatedName);
  };

  return (
    <TableContentContainer marginBottom={2}>
      <Table>
        <Tbody>
          <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
            <Td>
              <CellInputField marginBottom="8px" alignItems="baseline">
                <InputLabel>Character Name:</InputLabel>
                <Box>
                  <Input
                    name="player.name"
                    maxLength={29}
                    useIcon
                    validators={{
                      required: 'Please enter a name for your character!',
                      validate: (value: string) => {
                        const isInvalid = validateCharacterName(value);

                        if (isInvalid) {
                          return isInvalid;
                        }

                        return true;
                      }
                    }}
                  />
                  <Box>
                    [
                    <Text
                      fontSize="smaller"
                      onClick={() => generateNewName()}
                      cursor="pointer"
                      as="span"
                      color="#004294"
                      fontWeight={700}
                    >
                      suggest name
                    </Text>
                    ]
                  </Box>
                </Box>
              </CellInputField>
            </Td>
          </Tr>
          <Tr whiteSpace="nowrap" bgColor="#d5c0a1">
            <Td>
              <CellInputField marginBottom={2} alignItems="center">
                <InputLabel>Sex: </InputLabel>
                <RadioGroup name="player.sex">
                  <Stack spacing={16} direction="row">
                    <Radio
                      id={PlayerSex.MALE.toString()}
                      size="sm"
                      name="player.sex"
                      value={PlayerSex.MALE.toString()}
                    >
                      male
                    </Radio>
                    <Radio
                      id={PlayerSex.FEMALE.toString()}
                      size="sm"
                      name="player.sex"
                      value={PlayerSex.FEMALE.toString()}
                    >
                      female
                    </Radio>
                  </Stack>
                </RadioGroup>
              </CellInputField>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContentContainer>
  );
};

export default PlayerForm;
