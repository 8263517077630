import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-accountmanagement.gif';
import Welcome from './components/Welcome';
import AccountStatus from './components/AccountStatus';
import DownloadClient from './components/DownloadClient';
import Characters from './components/Characters';

const AccountOverview = () => {
  return (
    <Content headline={Title}>
      <Welcome />
      <AccountStatus />
      <DownloadClient />
      <Characters />
    </Content>
  );
};

export default AccountOverview;
