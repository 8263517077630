import { Box, Flex, Link, Text } from '@chakra-ui/react';
import Button from 'src/components/Button';
import { useNavigate } from 'react-router';
import Message from 'src/components/Message';

const SectionsList = () => {
  const navigate = useNavigate();
  return (
    <Message padding="10px" margin="0 0 12px 0" id="top">
      <Flex justifyContent="space-between">
        <Box textAlign="center">
          <Text as="span" marginRight="4px">
            [<Link href="/account/management#general">General Information</Link>]
          </Text>
          <Text as="span" marginRight="4px">
            [<Link href="/account/management#products">Products Available</Link>]
          </Text>
          <Text as="span" marginRight="4px">
            [<Link href="/account/management#history">History</Link>]
          </Text>
          <Text as="span" marginRight="4px">
            [<Link href="/account/management#general">Registration</Link>]
          </Text>
          <Text as="span" marginRight="4px">
            [<Link href="/account/management#general">Two-Factor Authentication</Link>]
          </Text>
          <Text as="span" marginRight="4px">
            [<Link href="/account/management#general">Tickets</Link>]
          </Text>
        </Box>
        <Flex flexDir="column" rowGap={1}>
          <Button variant="success">Get Premium</Button>
          <Button onClick={() => navigate('/account/overview')}>Overview</Button>
        </Flex>
      </Flex>
    </Message>
  );
};

export default SectionsList;
