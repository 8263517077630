import { Box, Flex, Text } from '@chakra-ui/react';
import TableContainer from 'src/components/TableContainer/TableContainer';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import { useNavigate } from 'react-router';
import Button from 'src/components/Button';

const ProductsAvailable = () => {
  const navigate = useNavigate();
  return (
    <TableContainer title="Products Available" marginBottom="16px" id="products">
      <TableContentContainer marginBottom={2}>
        <Flex
          padding="2px 5px"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Box>
            <Text fontWeight={700}>Tibia Coins</Text>
            <Text>
              Get Tibia Coins to shop exclusive products in the Store, including Mounts, Outfits, a
              Character Name Change or even Premium Time.
            </Text>
          </Box>
          <Box>
            <Button variant="success" onClick={() => navigate('/')}>
              Get Tibia Coins
            </Button>
          </Box>
        </Flex>
      </TableContentContainer>
      <TableContentContainer marginBottom={2}>
        <Flex
          padding="2px 5px"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Box>
            <Text fontWeight={700}>Premium Time</Text>
            <Text>
              Buy Premium Time to enhance your gaming experience by gaining additional abilities and
              advantages.
            </Text>
          </Box>
          <Box>
            <Button variant="success" onClick={() => navigate('/')}>
              Get Premium Time
            </Button>
          </Box>
        </Flex>
      </TableContentContainer>
      <TableContentContainer marginBottom={2}>
        <Flex
          padding="2px 5px"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Box>
            <Text fontWeight={700}>Extra Services</Text>
            <Text>Order a Recovery Key if you need a new one.</Text>
          </Box>
          <Box>
            <Button variant="success" onClick={() => navigate('/')}>
              Get Extra Service
            </Button>
          </Box>
        </Flex>
      </TableContentContainer>
      <TableContentContainer>
        <Flex
          padding="2px 5px"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Box>
            <Text fontWeight={700}>Use Game Code</Text>
            <Text>
              Enter your game code for Premium Time, Tibia Coins or an Extra Service which you have
              purchased at one of our official resellers.
            </Text>
          </Box>
          <Box>
            <Button onClick={() => navigate('/')}>Use Game Code</Button>
          </Box>
        </Flex>
      </TableContentContainer>
    </TableContainer>
  );
};

export default ProductsAvailable;
