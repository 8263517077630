export enum WorldCategories {
  REGULAR = 1,
  BATTLEEYE_PROTECTED = 2,
  EXPERIMENTAL = 3
}

export enum WorldLocations {
  ALL = 1,
  EUROPE = 2,
  NORTH_AMERICA = 3,
  SOUTH_AMERICA = 4,
  OCEANIA = 5
}

export enum WorldTypes {
  OPTIONAL = 1,
  OPEN = 2,
  RETRO = 3,
  HARDCORE = 4
}
