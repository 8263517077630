/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, Text } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-accountmanagement.gif';
import TableContainer from 'src/components/TableContainer/TableContainer';

const Logout = () => {
  return (
    <Content headline={Title}>
      <TableContainer title="Logout Successful" tableBgColor="#d4c0a1" tablePadding="5px">
        <Text>
          You have logged out of your Tibia account. In order to view your account you need to{' '}
          <Link href="/account/login">log in</Link> again.
        </Text>
      </TableContainer>
    </Content>
  );
};

export default Logout;
