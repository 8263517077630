import { Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import CornerTL from 'src/assets/images/content/corner-tl.gif';
import CornerTR from 'src/assets/images/content/corner-tr.gif';
import Border1 from 'src/assets/images/content/border-1.gif';
import InfoBGGreen from 'src/assets/images/content/title-background-green.gif';
import BorderBL from 'src/assets/images/content/corner-bl.gif';
import BorderBR from 'src/assets/images/content/corner-br.gif';

const Content = ({ children, headline }: { children: ReactNode; headline: string }) => {
  return (
    <Box
      id="News"
      className="Box"
      fontSize="0"
      position="relative"
      margin="5px"
      marginTop="4px"
      marginBottom="18px"
      color="#5a2800"
      borderLeft="2px solid #3a3738"
      borderRight="2px solid #3a3738"
      backgroundColor="#debb9d"
      backgroundRepeat="no-repeat"
      overflow="visible"
    >
      <Box
        className="Corner-tl"
        bgImage={CornerTL}
        position="absolute"
        top="-4px"
        left="-5px"
        width="17px"
        height="17px"
        zIndex="50"
      />
      <Box
        className="Corner-tr"
        bgImage={CornerTR}
        position="absolute"
        top="-4px"
        right="-5px"
        width="17px"
        height="17px"
        zIndex="50"
      />
      <Box
        className="Border_1"
        bgImage={Border1}
        position="relative"
        height="6px"
        width="100%"
        backgroundRepeat="repeat-x"
      />
      <Box
        className="BorderTitleText"
        bgImage={InfoBGGreen}
        position="relative"
        height="24px"
        backgroundRepeat="repeat-x"
        width="100%"
      />
      <Box
        id="ContentBoxHeadline"
        className="Title"
        position="absolute"
        top="6px"
        left="5px"
        width="250px"
        height="28px"
        bgImage={headline}
      />
      <Box className="Border_2" margin="4px" padding="0" backgroundColor="#793d03">
        <Box className="Border_3" border="1px solid #793d03">
          <Box
            className="BoxContent"
            padding="10px"
            minHeight="367px"
            height="auto"
            fontFamily="Verdana, Arial, Times New Roman, sans-serif"
            fontSize="9pt"
            backgroundColor="#fff2db"
            color="#5a2800"
          >
            {children}
          </Box>
        </Box>
      </Box>
      <Box
        className="Border_1"
        bgImage={Border1}
        position="relative"
        height="6px"
        width="100%"
        backgroundRepeat="repeat-x"
      />
      <Box className="CornerWrapper-b" position="absolute" width="100%">
        <Box
          className="Corner-bl"
          position="absolute"
          bottom="-4px"
          left="-6px"
          width="17px"
          height="17px"
          zIndex="50"
          bgImage={BorderBL}
        />
      </Box>
      <Box className="CornerWrapper-b" position="absolute" width="100%">
        <Box
          className="Corner-br"
          position="absolute"
          bottom="-4px"
          right="-6px"
          width="17px"
          height="17px"
          zIndex="50"
          bgImage={BorderBR}
        />
      </Box>
    </Box>
  );
};

export default Content;
