/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import AuthService from '../../services/authService/AuthService';
import { IApiError } from '../interfaces/apiError.interface';

/**
 * Request Interceptor and Response Interceptor
 * httpAxios: Interceptor to standarized (camelCase) all api calls, add token
 * and handler errors to use
 */
const httpAxios = axios.create();

/**
 * Be cautious while touching this handlers because we have a circular dependency between AuthService and apiInterceptor
 * that means, that we can cause infinite loops if not cautious.
 * Removing this circular dependency should be done on a future tech debt ticket
 */
httpAxios.interceptors.request.use(config => {
  const token = AuthService.getInstance().accessToken;
  const newConfig = { ...config };

  if (config.params) {
    newConfig.params = snakecaseKeys(config.params);
  }
  if (config.data) {
    newConfig.data = snakecaseKeys(config.data, { exclude: [/\w\.\w/] });
  }
  if (token && newConfig.headers && !newConfig.headers.Authorization) {
    newConfig.headers.Authorization = token;
  }
  if (newConfig.headers) {
    newConfig.headers.Accept = 'application/json';
  }

  return newConfig;
});

// Response Interceptor
httpAxios.interceptors.response.use(
  async response => {
    // Any status code that lie within the range of 2xx
    return {
      ...response,
      data: camelCaseKeys(response.data, { deep: true })
    };
  },
  async (error: Error | AxiosError) => {
    const GENERIC_ERROR = {
      network: [`We're sorry, but an error occurred.`]
    };

    if (!axios.isAxiosError(error)) return Promise.reject(GENERIC_ERROR);

    if (error?.response?.status === 401) return handle401Errors(error);

    if (error?.response?.data?.errors) {
      const { bc_order_id } = error.response.data;

      const errors: IApiError = camelCaseKeys(
        {
          ...(bc_order_id ? { bcOrderId: bc_order_id } : {}),
          ...error.response.data.errors
        },
        { deep: true }
      );

      return Promise.reject(errors);
    }

    // Integration errors come with key `error` instead of `errors`
    if (error?.response?.data?.error && error?.response?.status < 500) {
      const errors: IApiError = camelCaseKeys(error.response.data.error, { deep: true });
      return Promise.reject(errors);
    }

    if (
      error?.response?.data?.error?.sovos_ship_compliant_connection &&
      error?.response?.data?.error?.sovos_ship_compliant_connection[0].message ===
        'The connection credentials are not valid'
    ) {
      const errors = {
        sovos_invalid_credentials: ['Invalid Sovos ShipCompliant Credentials']
      };
      return Promise.reject(errors);
    }

    if (error?.message === 'canceled') return Promise.resolve({ network: 'canceled' });

    return Promise.reject(GENERIC_ERROR);
  }
);

const handle401Errors = async (error: any) => {
  switch (error.response.data.error) {
    case 'Invalid Email or password.':
    case 'The credential provided do not match our records. After 5 unsuccessful login attempts your account will be temporarily disabled for security purposes':
    case 'You have one more attempt before your account is locked.': {
      const errors = { login: ['Log in failed.'] };
      return Promise.reject(errors);
    }
    case 'User is inactive.': {
      const errors = { inactiveUser: ['User is inactive.'] };
      return Promise.reject(errors);
    }
    case 'Your account is locked.': {
      const errors = { attemptsExceeded: ['Log in failed.'] };
      return Promise.reject(errors);
    }
    case 'Record Not Found': {
      return AuthService.getInstance().forceLogout();
    }
    default:
      return AuthService.getInstance().forceLogout();
  }
};

export default httpAxios;
