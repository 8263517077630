import React, { Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { PublicLayout } from './utils/layout/PublicRoute';
import { PrivateLayout, PrivateRoute } from './utils/layout/PrivateRoute';
import Logout from './views/Logout/Logout';
import NewPlayer from './views/AccountManagement/NewPlayer';
import CreateAccount from './views/CreateAccount/CreateAccount';
import AccountOverview from './views/AccountManagement/AccountOverview';
import AccountManagement from './views/AccountManagement/AccountManagement';
import LatestNews from './views/LatestNews/LatestNews';
import Login from './views/Login/Login';
import DownloadAgreement from './views/Downloads/Agreement';
import DownloadClient from './views/Downloads/Client';
import AboutTibia from './views/About/AboutTibia';
import Features from './views/About/Feature';
import Premium from './views/About/Premium';
import GuideStart from './views/Guides/GuideStart';
import SecurityHints from './views/Guides/SecurityHints';

const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        element={
          <SuspenseWrapper>
            <PrivateRoute>
              <PrivateLayout />
            </PrivateRoute>
          </SuspenseWrapper>
        }
      >
        <Route path="account/overview" element={<AccountOverview />} />
        <Route path="account/management" element={<AccountManagement />} />
        <Route path="account/player/new" element={<NewPlayer />} />
        <Route path="logout" element={<Logout />} />
      </Route>
      <Route
        element={
          <SuspenseWrapper>
            <PublicLayout />
          </SuspenseWrapper>
        }
      >
        <Route path="/" element={<LatestNews />} />
        <Route path="account/create" element={<CreateAccount />} />
        <Route path="about/tibia" element={<AboutTibia />} />
        <Route path="about/features" element={<Features />} />
        <Route path="about/premium" element={<Premium />} />
        <Route path="guides/start" element={<GuideStart />} />
        <Route path="guides/security" element={<SecurityHints />} />
        <Route path="account/login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="downloads/agreement" element={<DownloadAgreement />} />
        <Route path="downloads/client" element={<DownloadClient />} />
        <Route path="latestnews" element={<LatestNews />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Route>
  )
);
