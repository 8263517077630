import React from 'react';
import { Box } from '@chakra-ui/react';
import mediumBG from 'src/assets/images/buttons/mediumbutton.gif';
import mediumBGOver from 'src/assets/images/buttons/mediumbutton-over.gif';

interface ButtonProps {
  src: string;
  onClick?: () => void;
}

const BigButton = ({ src, onClick }: ButtonProps) => {
  return (
    <Box
      bgImage={mediumBG}
      position="relative"
      top="0"
      left="0"
      width="150px"
      height="37px"
      cursor="pointer"
      zIndex="10"
      _hover={{
        bgImage: mediumBGOver,
        zIndex: 15
      }}
    >
      <Box
        w="157px"
        h="37px"
        position="absolute"
        top={0}
        left={0}
        bgImage={src}
        onClick={onClick}
      />
    </Box>
  );
};

export default BigButton;
