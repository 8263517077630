import { createContext, useContext } from 'react';
import { Dependencies } from './Dependencies';

const DependenciesContext = createContext<Dependencies | null>(null);

const { Provider: DependenciesProvider } = DependenciesContext;

const useDependencies = () => {
  const dependencies = useContext(DependenciesContext);

  if (!dependencies) {
    throw Error('You used useDependencies outside a DependenciesProvider');
  }

  return dependencies;
};

export { useDependencies, DependenciesContext, DependenciesProvider };
