/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-createaccount.gif';
import { FormProvider, useForm } from 'react-hook-form';
import APIErrorMessage from 'src/components/APIErrorMessage';
import Button from 'src/components/Button';
import { useNavigate } from 'react-router';
import { ICreateAccount, createAccount } from 'src/services/accountService/AccountService';
import TableContainer from 'src/components/TableContainer/TableContainer';
import AuthService, { IAccountLoginCredentials } from 'src/services/authService/AuthService';
import useRecaptcha from 'src/utils/hooks/useRecaptcha';
import AccountForm from './Components/AccountForm';
import PlayerForm from './Components/PlayerForm';
import WorldForm from './Components/WorldForm';
import RulesForm from './Components/RulesForm';

const authService = AuthService.getInstance();

const CreateAccount = () => {
  const { getReCaptchaToken } = useRecaptcha();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();

  const methods = useForm<ICreateAccount>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      password2: '',
      worldId: 1,
      player: {
        name: ''
      }
    }
  });

  const onSubmitEvent = methods.handleSubmit(async (form: ICreateAccount) => {
    setError(undefined);

    try {
      const recaptchaToken = await getReCaptchaToken();

      const data = {
        ...form,
        player: {
          ...form.player,
          sex: Number(form.player.sex)
        },
        recaptchaToken
      };

      await createAccount(data);

      const payload: IAccountLoginCredentials = {
        email: form.email,
        password: form.password,
        recaptchaToken
      };

      await authService.login(payload);
      navigate('/account/overview', { replace: true });
    } catch (err: any) {
      setError(err?.message);
    }
  });

  return (
    <Content headline={Title}>
      <FormProvider {...methods}>
        <form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            onSubmitEvent();
          }}
        >
          {error && <APIErrorMessage message={error} marginBottom="16px" />}
          <TableContainer title="Create New Account" marginBottom="16px">
            <AccountForm />
            <PlayerForm />
            <WorldForm />
            <RulesForm />
          </TableContainer>
          <Flex justifyContent="space-around">
            <Button type="submit">Submit</Button>
          </Flex>
        </form>
      </FormProvider>
    </Content>
  );
};

export default CreateAccount;
