import { Box, Radio as ChakraRadio, RadioProps } from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IInputValidator } from 'src/utils/interfaces/validations.interface';
import ErrorMessage from '../ErrorMessage';
import { getNestedErrors } from '../../utils/getNestedErrors';

interface IRadioProps extends RadioProps {
  name: string;
  id: string;
  validators?: IInputValidator;
  defaultChecked?: boolean;
}

const Radio: React.FC<IRadioProps> = ({ name, validators, id, ...props }) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext();

  const errorMessage = getNestedErrors(name, errors);

  return (
    <Box className="checkbox" key={name}>
      <Controller
        key={name}
        name={name}
        control={control}
        rules={validators}
        render={({ field: { ...rest } }) => (
          <>
            <ChakraRadio
              {...rest}
              checked={watch(name) === props.value}
              id={id.split(' ').join('-')}
              {...props}
            />
            {errorMessage ? <ErrorMessage message={errorMessage} /> : ''}
          </>
        )}
      />
    </Box>
  );
};

export default Radio;
