import { useState, useEffect } from 'react';
import PasswordValidator from 'password-validator';
import { IPasswordError, IPasswordStrength } from '../interfaces/accounts.interface';
import { ERROR_MESSAGES, blockedWords } from '../constants';

// Hook for password validation
const usePasswordValidation = (password: string) => {
  const [passwordErrors, setPasswordErrors] = useState<IPasswordError>();
  const [passwordStrength, setPasswordStrength] = useState<IPasswordStrength>();

  useEffect(() => {
    setPasswordErrors(getPasswordErrors(password));
    setPasswordStrength(getClientPasswordStrength(password));
  }, [password]);

  const validatePassword = (): string | boolean => {
    const errors = getPasswordErrors(password);

    if (errors.passwordLength) return ERROR_MESSAGES.PASSWORD_LENGTH;
    if (errors.needSpecialCharacter) return ERROR_MESSAGES.NO_INVALID_LETTERS;
    if (errors.needLowerCase) return ERROR_MESSAGES.NEED_LOWERCASE;
    if (errors.needUppercase) return ERROR_MESSAGES.NEED_UPPERCASE;
    if (errors.needNumber) return ERROR_MESSAGES.NEED_NUMBER;

    return true;
  };

  return { passwordErrors, passwordStrength, validatePassword };
};

export default usePasswordValidation;

// Function to get password strength
const getClientPasswordStrength = (password: string): IPasswordStrength => {
  const { length } = password;

  if (length < 11) return { strength: 'very weak', color: '#ec644b' };
  if (length < 13) return { strength: 'medium', color: '#b0b300' };
  return { strength: 'strong', color: '#20a000' };
};

// Function to get password errors
const getPasswordErrors = (password: string): IPasswordError => {
  const passwordSchema = new PasswordValidator();

  passwordSchema
    .is()
    .min(10)
    .is()
    .max(29)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits(1)
    .has()
    .not()
    .spaces()
    .is()
    .not()
    .oneOf(blockedWords);

  const result = passwordSchema.validate(password, { list: true }) as string[];

  return {
    passwordLength: result.includes('min'),
    needSpecialCharacter: result.includes('oneOf'),
    needLowerCase: result.includes('lowercase'),
    needUppercase: result.includes('uppercase'),
    needNumber: result.includes('digits')
  };
};
