import { Box, Link, Text } from '@chakra-ui/react';
import Message from 'src/components/Message';
import AttentionSign from 'src/assets/images/content/attentionsign.gif';

const ImportantInformation = () => {
  return (
    <Message margin="0 0 30px 0">
      <Box
        className="AttentionSign"
        bgImage={AttentionSign}
        width="30px"
        height="26px"
        position="relative"
        float="right"
        marginRight="15px"
        top="3px"
        left="8px"
      />
      <Text fontWeight="600" marginBottom="16px">
        Important Information!
      </Text>
      <Text marginBottom={4}>
        We have changed the login process! Account names have been abolished. To log in, you only
        need your <b>password</b> and the account&apos;s <b>email address.</b>
      </Text>
      <Text marginBottom={0}>
        You have <b>forgotten</b> your <b>email address</b> but you still <b>know</b> the{' '}
        <b>account name</b> which had been used for your Tibia account?
      </Text>
      <Text>
        Get your email address here: <Link href="/home">link</Link>
      </Text>
    </Message>
  );
};

export default ImportantInformation;
