import {
  Flex,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr
} from '@chakra-ui/react';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import { WorldLocations, WorldTypes } from 'src/utils/interfaces/worlds.interface';
import WorldAll from 'src/assets/images/content/option_server_location_all.png';
import WorldSA from 'src/assets/images/content/option_server_location_bra.png';
import WorldEurope from 'src/assets/images/content/option_server_location_eur.png';
import WorldNA from 'src/assets/images/content/option_server_location_usa.png';
import WorldOceania from 'src/assets/images/content/option_server_location_oce.png';
import WorldNPVP from 'src/assets/images/content/option_server_pvp_type_optional.gif';
import WorldPVP from 'src/assets/images/content/option_server_pvp_type_open.gif';
import WorldRetro from 'src/assets/images/content/option_server_pvp_type_retro.gif';
import WorldHardcore from 'src/assets/images/content/option_server_pvp_type_retrohardcore.gif';

const WorldForm = () => {
  return (
    <TableContentContainer marginBottom={2}>
      <Table>
        <Tbody>
          <Tr bgColor="#D4C0A1">
            <Td>
              <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                World Location:
              </Text>
              <RadioGroup
                defaultValue={WorldLocations.SOUTH_AMERICA.toString()}
                padding="0.5rem 3.5rem 1rem 2.5rem"
              >
                <Stack spacing={16} direction="row">
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldAll} border={0} marginBottom={1} width="48px" />
                    <Radio size="sm" name="world.location" value={WorldLocations.ALL.toString()}>
                      all
                    </Radio>
                  </Flex>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldEurope} border={0} marginBottom={1} width="48px" />
                    <Radio
                      size="sm"
                      name="world.location"
                      value={WorldLocations.EUROPE.toString()}
                      isDisabled
                    >
                      Europe
                    </Radio>
                  </Flex>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldNA} border={0} marginBottom={1} width="48px" />
                    <Radio
                      size="sm"
                      name="world.location"
                      value={WorldLocations.NORTH_AMERICA.toString()}
                      isDisabled
                    >
                      North America
                    </Radio>
                  </Flex>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldSA} border={0} marginBottom={1} width="48px" />
                    <Radio
                      size="sm"
                      name="world.location"
                      value={WorldLocations.SOUTH_AMERICA.toString()}
                    >
                      South America
                    </Radio>
                  </Flex>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldOceania} border={0} marginBottom={1} width="48px" />
                    <Radio
                      size="sm"
                      name="world.location"
                      value={WorldLocations.OCEANIA.toString()}
                      isDisabled
                    >
                      Oceania
                    </Radio>
                  </Flex>
                </Stack>
              </RadioGroup>
            </Td>
          </Tr>
          <Tr bgColor="#D4C0A1">
            <Td>
              <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                World Type:
              </Text>
              <RadioGroup defaultValue={WorldTypes.OPTIONAL.toString()} padding="2rem">
                <SimpleGrid columns={2} spacing={10}>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldNPVP} border={0} marginBottom={1} width="48px" />
                    <Radio size="sm" name="world.type" value={WorldTypes.OPTIONAL.toString()}>
                      <Text fontWeight={700}>Optional PvP</Text>
                    </Radio>
                    <Text textAlign="center">
                      Only if both sides agree, characters can be fought
                    </Text>
                  </Flex>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldPVP} border={0} marginBottom={1} width="48px" />
                    <Radio
                      size="sm"
                      name="world.type"
                      value={WorldTypes.OPEN.toString()}
                      isDisabled
                    >
                      <Text fontWeight={700}>Open PvP</Text>
                    </Radio>
                    <Text textAlign="center">
                      Killing other characters is possible, but restricted
                    </Text>
                  </Flex>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldRetro} border={0} marginBottom={1} width="48px" />
                    <Radio
                      size="sm"
                      name="world.type"
                      value={WorldTypes.RETRO.toString()}
                      isDisabled
                    >
                      <Text fontWeight={700}>Retro Open PvP</Text>
                    </Radio>
                    <Text textAlign="center">
                      Killing other characters is possible, but restricted (old PvP rules)
                    </Text>
                  </Flex>
                  <Flex justifyContent="center" flexDirection="column" alignItems="center">
                    <Image src={WorldHardcore} border={0} marginBottom={1} width="48px" />
                    <Radio
                      size="sm"
                      name="world.type"
                      value={WorldTypes.HARDCORE.toString()}
                      isDisabled
                    >
                      <Text fontWeight={700}>Retro Hardcore PvP</Text>
                    </Radio>
                    <Text textAlign="center">
                      Killing other characters is not restricted at all (old PvP rules)
                    </Text>
                  </Flex>
                </SimpleGrid>
              </RadioGroup>
            </Td>
          </Tr>
          <Tr bgColor="#D4C0A1">
            <Td>
              <Flex>
                <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap" marginRight={16}>
                  World Name:
                </Text>
                <Text>Suggested world: Ironman</Text>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContentContainer>
  );
};

export default WorldForm;
