import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
);

const baseStyle = definePartsStyle({
  icon: {
    padding: '1px'
  },
  label: defineStyle({
    fontSize: '10pt',
    alignItems: 'baseline'
  }),
  control: {
    bgColor: 'white',
    boxSize: '12px',
    padding: 0, // change the padding of the control
    borderRadius: 3, // change the border radius of the control
    borderWidth: 1,
    borderColor: '#767676'
  }
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
