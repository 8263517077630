import {
  Box,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import CellInputField from 'src/components/TableContainer/CellInputField';
import BackgroundPopOver from 'src/assets/images/content/scroll.gif';
import { ICreateAccount } from 'src/services/accountService/AccountService';
import validator from 'validator';
import NOkIcon from 'src/assets/images/general/nok.gif';
import OkIcon from 'src/assets/images/general/ok.gif';
import { ERROR_MESSAGES } from 'src/utils/constants';
import { useDependencies } from 'src/utils/contexts/di/DependenciesContext';
import InputLabel from 'src/components/Forms/InputLabel';
import Input from 'src/components/Forms/Input';

const AccountForm = () => {
  const { usePasswordValidation } = useDependencies();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const methods = useFormContext<ICreateAccount>();

  const password = methods.watch('password');

  const { passwordErrors, validatePassword } = usePasswordValidation(password);

  return (
    <TableContentContainer marginBottom={2} width="100%">
      <Table width="100%">
        <Tbody width="100%">
          <Tr bgColor="#D4C0A1" width="100%">
            <Td padding="2px 5px" border="1px solid #faf0d7" width="100%">
              <CellInputField marginBottom="8px">
                <InputLabel>Email Address:</InputLabel>
                <Input
                  name="email"
                  maxLength={29}
                  useIcon
                  validators={{
                    required: 'Please enter your email address!',
                    validate: (value: string) => {
                      const IsValid = validator.isEmail(value);

                      if (!IsValid) {
                        return 'This email address has an invalid format. Please enter a correct email address!';
                      }

                      return true;
                    }
                  }}
                />
              </CellInputField>
              <CellInputField marginBottom="8px">
                <InputLabel>Password:</InputLabel>
                <Popover
                  returnFocusOnClose
                  isOpen={isOpen}
                  onClose={onClose}
                  closeOnBlur
                  closeOnEsc
                  placement="right"
                >
                  <PopoverTrigger>
                    <Box width="auto">
                      <Input
                        name="password"
                        maxLength={29}
                        type="password"
                        useIcon
                        validators={{
                          required: 'Please enter your password!',
                          validate: () => {
                            if (!isOpen) {
                              onToggle();
                            }

                            const isInvalid = validatePassword();

                            if (isInvalid) {
                              return isInvalid;
                            }

                            return true;
                          }
                        }}
                      />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent bgImage={BackgroundPopOver}>
                    <PopoverArrow />
                    <PopoverBody>
                      <Text fontWeight={700} marginBottom="5px">
                        Password Requirements
                      </Text>
                      <Flex alignContent="center" alignItems="center" marginBottom={1}>
                        <Image
                          src={passwordErrors?.passwordLength ? NOkIcon : OkIcon}
                          w="12px"
                          h="12px"
                          marginRight={1}
                        />
                        <Text>{ERROR_MESSAGES.PASSWORD_LENGTH}</Text>
                      </Flex>
                      <Flex alignContent="center" alignItems="center" marginBottom={1}>
                        <Image
                          src={passwordErrors?.needSpecialCharacter ? NOkIcon : OkIcon}
                          w="12px"
                          h="12px"
                          marginRight={1}
                        />
                        <Text>{ERROR_MESSAGES.NO_INVALID_LETTERS}</Text>
                      </Flex>
                      <Flex alignContent="center" alignItems="center" marginBottom={1}>
                        <Image
                          src={passwordErrors?.needLowerCase ? NOkIcon : OkIcon}
                          w="12px"
                          h="12px"
                          marginRight={1}
                        />
                        <Text>{ERROR_MESSAGES.NEED_LOWERCASE}</Text>
                      </Flex>
                      <Flex alignContent="center" alignItems="center" marginBottom={1}>
                        <Image
                          src={passwordErrors?.needUppercase ? NOkIcon : OkIcon}
                          w="12px"
                          h="12px"
                          marginRight={1}
                        />
                        <Text>{ERROR_MESSAGES.NEED_UPPERCASE}</Text>
                      </Flex>
                      <Flex alignContent="center" alignItems="center" marginBottom={1}>
                        <Image
                          src={passwordErrors?.needNumber ? NOkIcon : OkIcon}
                          w="12px"
                          h="12px"
                          marginRight={1}
                        />
                        <Text>{ERROR_MESSAGES.NEED_NUMBER}</Text>
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </CellInputField>
              <CellInputField marginBottom="4px">
                <InputLabel>Password Again:</InputLabel>
                <Input
                  name="password2"
                  maxLength={29}
                  type="password"
                  useIcon
                  validators={{
                    required: 'Please enter the password again!',
                    validate: (value: string) => {
                      if (value !== password) {
                        return ERROR_MESSAGES.PASSWORD_NOT_MATCH;
                      }

                      return true;
                    }
                  }}
                />
              </CellInputField>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContentContainer>
  );
};

export default AccountForm;
