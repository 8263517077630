import { Link, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import Checkbox from 'src/components/Forms/Checkbox';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';

const RulesForm = () => {
  return (
    <TableContentContainer marginBottom={2}>
      <Table>
        <Tbody>
          <Tr bgColor="#D4C0A1">
            <Td>
              <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap" marginBottom={2}>
                Please select the following check box:
              </Text>
              <Checkbox alignItems="start" name="agreement" isRequired>
                I agree to the Tibia Service <Link href="/support/agreement">Agreement</Link>, the{' '}
                <Link href="/support/rule">Tibia Rules</Link> and the{' '}
                <Link href="/support/privacy">Tibia Privacy Policy</Link>.
              </Checkbox>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContentContainer>
  );
};

export default RulesForm;
