/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { plainToClass } from 'class-transformer';
import { IApiOptions } from 'src/utils/interfaces/apiOptions.interface';
import { IApiResponse } from 'src/utils/interfaces/apiResponse.interface';
import httpAxios from '../../utils/interceptors/apiInterceptor';

export default class BaseHttpService {
  // Since we have multiple services, this URL will be overwritten by the classes that will inherit from it.
  public url = process.env.REACT_APP_FAHREN_API_URL || '';

  public create<T>(options: IApiOptions<T>): Promise<IApiResponse<T>> {
    return httpAxios.post(`${this.url + options.path}`, options.body, options.config).then(res => {
      return {
        data: options.model
          ? plainToClass(options.model, this.getDataResponse(res, options) as T)
          : (this.getDataResponse(res, options) as T)
      };
    });
  }

  public fetch<T>(options: IApiOptions<T>): Promise<IApiResponse<T>> {
    return httpAxios.get(`${this.url + options.path}`, options.config).then(res => {
      return {
        data: options.model
          ? plainToClass(options.model, this.getDataResponse(res, options) as T)
          : (this.getDataResponse(res, options) as T)
      };
    });
  }

  public getAllByPost<T>(options: IApiOptions<T>): Promise<IApiResponse<T[]>> {
    return httpAxios.post(`${this.url + options.path}`, options.body, options.config).then(res => {
      return {
        data: options.model
          ? plainToClass(options.model, this.getDataResponse(res, options) as T[])
          : (this.getDataResponse(res, options) as T[]),
        meta: res.data.meta ? res.data.meta : {}
      };
    });
  }

  public getAll<T>(options: IApiOptions<T>): Promise<IApiResponse<T[]>> {
    return httpAxios.get(`${this.url + options.path}`, options.config).then(res => {
      return {
        data: options.model
          ? plainToClass(options.model, this.getDataResponse(res, options) as T[])
          : (this.getDataResponse(res, options) as T[]),
        meta: res.data.meta ? res.data.meta : {}
      };
    });
  }

  public getAllByFilters<T>(options: IApiOptions<T>): Promise<IApiResponse<T[]>> {
    return httpAxios.post(`${this.url + options.path}`, options.body, options.config).then(res => {
      return {
        data: options.model
          ? plainToClass(options.model, this.getDataResponse(res, options) as T[])
          : (this.getDataResponse(res, options) as T[]),
        meta: res.data.meta ? res.data.meta : {},
        dynamicFieldsMetrics: res.data.dynamicFieldsMetrics ? res.data.dynamicFieldsMetrics : {}
      };
    });
  }

  public update<T>(options: IApiOptions<T>): Promise<IApiResponse<T>> {
    return httpAxios.put(`${this.url + options.path}`, options.body, options.config).then(res => {
      return {
        data: options.model
          ? plainToClass(options.model, this.getDataResponse(res, options) as T)
          : (this.getDataResponse(res, options) as T)
      };
    });
  }

  public delete<T>(options: IApiOptions<T>): Promise<boolean> {
    return httpAxios.delete(`${this.url + options.path}`, options.config).then(() => {
      return true;
    });
  }

  /**
   * Since the api returns different objects, we get the object we want
   * by finding the dataType expected (ex: users, prospect, products)
   */
  private getDataResponse<T>(res: AxiosResponse, options: IApiOptions<T>) {
    return options.dataType ? this.findByDataType(res.data, options.dataType) : res.data;
  }

  private findByDataType(obj: any, dataType: string): any {
    if (obj === null) {
      return null;
    }
    if (obj[dataType]) {
      return obj[dataType].items ? obj[dataType].items : obj[dataType];
    }
    let result;
    Object.keys(obj).forEach(p => {
      if (Object.prototype.hasOwnProperty.call(obj, p) && typeof obj[p] === 'object') {
        result = this.findByDataType(obj[p], dataType);
        if (result) {
          return result;
        }
      }

      return null;
    });
    return result;
  }

  /**
   * Singleton:
   * static makes it visible on the class itself rather than on the instances.
   */
  static instance: BaseHttpService;

  static getInstance(): BaseHttpService {
    if (!this.instance) {
      this.instance = new BaseHttpService();
    }
    return this.instance;
  }
}
