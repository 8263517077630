import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import { plainToClass } from 'class-transformer';
import jwt_decode from 'jwt-decode';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import httpAxios from 'src/utils/interceptors/apiInterceptor';
import { IRecaptcha } from 'src/utils/interfaces/recaptcha.interface';
import { Account } from 'src/utils/models/account.model';

export interface IAccountLoginCredentials extends IRecaptcha {
  email: string;
  password: string;
}

export default class AuthService {
  public url = process.env.REACT_APP_FAHREN_API_URL || '';

  public path = '/account';

  public whoAmIPath = '/me';

  public tokenPath = '/users/token';

  public TOKEN_KEY = 'FAHREN_TOKEN';

  public accessToken: string | null;

  public isLoggedIn = false;

  public userLoggedIn: Account | null;

  constructor() {
    const cookies = parseCookies();
    if (cookies.FAHREN_TOKEN) {
      this.setLoggedInState(cookies.FAHREN_TOKEN);
    } else {
      this.deleteSession();
    }
  }

  login(credentials: IAccountLoginCredentials): Promise<Account> {
    const cookies = parseCookies();
    if (cookies.FAHREN_TOKEN) {
      const errors = { message: ['You are already logged in.'] };
      return Promise.reject(errors);
    }
    return httpAxios
      .post(`${this.url + this.path}/login`, credentials)
      .then((res: AxiosResponse) => {
        this.handleToken(res.data.accessToken);
        this.setLoggedInAccount(res.data);
        return plainToClass(Account, res.data as Account);
      });
  }

  whoAmI(): Promise<Account> {
    return httpAxios
      .get(`${this.url + this.whoAmIPath}`)
      .then((res: AxiosResponse) => {
        this.setLoggedInAccount(res.data);
        return plainToClass(Account, res.data as Account);
      })
      .catch(err => err);
  }

  logout(): Promise<boolean> {
    return httpAxios.delete(`${this.url + this.path}/logout`).then(() => {
      this.deleteSession();
      return true;
    });
  }

  forceLogout() {
    this.deleteAccessToken();
    this.accessToken = null;
    window.location.href = '/account/login';
    return null;
  }

  setAccountAsLoggedIn(userInfo: Account): void {
    this.accessToken = userInfo.accessToken;
    const bearerToken = `Bearer ${userInfo.accessToken}`;
    this.setLoggedInAccount(userInfo);
    this.setTokenInCookie(bearerToken);
  }

  // Used only to decript data from token (tenants not included)
  private setLoggedInState(token: string): void {
    try {
      const userInfo = jwt_decode(token) as never;
      this.accessToken = token;
      this.isLoggedIn = true;
      this.userLoggedIn = plainToClass(Account, camelCaseKeys(userInfo, { deep: true }) as JSON);
    } catch (error) {
      this.deleteSession();
    }
  }

  private handleToken(token: string): void {
    this.accessToken = token;
    this.setTokenInCookie(token);
  }

  private setLoggedInAccount(userInfo: Account): void {
    this.isLoggedIn = true;
    this.userLoggedIn = plainToClass(
      Account,
      camelCaseKeys(userInfo as never, { deep: true }) as JSON
    );
  }

  private setTokenInCookie(token: string): void {
    setCookie(null, this.TOKEN_KEY, token, {
      maxAge: 60 * 60, // 1hr
      path: '/'
    });
  }

  private deleteSession(): void {
    this.deleteAccessToken();
    this.accessToken = null;
    this.isLoggedIn = false;
    this.userLoggedIn = null;
  }

  private deleteAccessToken(): void {
    destroyCookie(null, this.TOKEN_KEY, { path: '/' });
  }

  /**
   * Singleton:
   * static makes it visible on the class itself rather than on the instances.
   */
  static instance: AuthService;

  static getInstance(): AuthService {
    if (!this.instance) {
      this.instance = new AuthService();
    }
    return this.instance;
  }
}
