import validator from 'validator';
import { blockedWords } from '../constants';

export const validateCharacterName = (name: string): string | boolean => {
  const isValidLength = validator.isLength(name, { min: 2, max: 29 });
  const hasOnlyLettersAndSpaces = /^[A-Za-z\s]+$/.test(name);
  const isNotBlocked = !blockedWords.includes(name);
  const hasSingleSpacesBetweenWords = name.split(' ').every(word => word.length > 0);
  const isFirstLetterUppercase = name.split(' ').every(word => /^[A-Z][a-z]*$/.test(word));
  const hasOnlyOneUppercasePerWord = name
    .split(' ')
    .every(word => word.match(/[A-Z]/g)?.length === 1);
  const hasNoTrailingSpace = !/\s$/.test(name);

  const isValidLetters = hasOnlyLettersAndSpaces && isNotBlocked;

  if (!hasOnlyOneUppercasePerWord) {
    return 'In names capital letters are only alowed at the beginning of a word.';
  }

  if (!isValidLength) {
    return 'A name must have at least 2 but no more than 29 letters.';
  }

  if (!isValidLetters) {
    return 'This name contains invalid letters. Please use only A-Z, a-z and space.';
  }

  if (!isFirstLetterUppercase) {
    return 'The first letter of a name has to be a capital letter.';
  }

  if (!hasSingleSpacesBetweenWords) {
    return 'This name contains more than one space between words. Please use only one space between words.';
  }

  if (!hasNoTrailingSpace) {
    return 'This name contains a space at the end. Please remove this space.';
  }

  return true;
};
