import { IApiResponse } from '../../utils/interfaces/apiResponse.interface';
import { PlayersOnline } from '../../utils/models/players_online.model';
import BaseHttpService from '../baseHttpService/BaseHttpService';

const baseHttpPlayersService = BaseHttpService.getInstance();

const basePath = '/online';

export async function getOnlineTotal(): Promise<IApiResponse<PlayersOnline>> {
  const apiOptions = {
    model: PlayersOnline,
    path: basePath
  };

  const response = await baseHttpPlayersService.fetch(apiOptions);

  if (response.data) {
    return {
      ...response,
      data: response.data
    };
  }

  throw new Error('No response');
}
