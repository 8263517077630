import { Grid } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import InfoBar from './InfoBar';
import RightArtwork from './RightArtwork';

const MainContent = ({ children }: { children: ReactNode }) => {
  return (
    <Grid
      gridArea="content"
      display="table"
      className="main-content Content"
      position="relative"
      top={0}
      width="100%"
      zIndex={100}
    >
      <InfoBar />
      <RightArtwork />
      {children}
    </Grid>
  );
};

export default MainContent;
