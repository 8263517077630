import { useCallback, useEffect, useState } from 'react';
import AuthService from 'src/services/authService/AuthService';
import { AccountRoles, TRoles } from '../models/account.model';

export const AUTHORIZED_ROLES_FOR = {
  ACCESS_PRODUCTS: [AccountRoles.ACCOUNT_TYPE_NORMAL]
};

export const useAuth = () => {
  const authService = AuthService.getInstance();
  const userLoggedIn = authService?.userLoggedIn;
  const userRole = userLoggedIn?.type;

  const setDefaultRoles = useCallback(() => {
    let defaultRoles: TRoles = [];

    if (userRole) {
      defaultRoles = [...defaultRoles, userRole];
    }

    return defaultRoles;
  }, [userRole]);

  const [roles, setRoles] = useState<TRoles>(setDefaultRoles());

  useEffect(() => {
    setRoles(setDefaultRoles());
  }, [setDefaultRoles]);

  const isUserAuthenticated = () => !!userLoggedIn;

  const checkRoles = (allowedRoles: TRoles) =>
    roles.some((role: AccountRoles) => allowedRoles.includes(role));

  return {
    checkRoles,
    isUserAuthenticated,
    userLoggedIn
  };
};
