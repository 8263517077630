import { Box, Flex, Text } from '@chakra-ui/react';
import { useAccountContext } from 'src/utils/contexts/AccountContext';
import TableContainer from 'src/components/TableContainer/TableContainer';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import Button from 'src/components/Button';

const Registration = () => {
  const { account } = useAccountContext();
  return (
    <TableContainer title="Registration" marginBottom="16px" id="general">
      <TableContentContainer>
        <Flex padding="2px 5px" gap={3} justifyContent="space-between">
          <Text fontWeight={700}>Address: </Text>
          <Box width="90%">
            <Text>
              {account?.firstName} {account?.lastName}
            </Text>
            <Text>{account?.address?.street}</Text>
            <Text>{account?.address?.number}</Text>
            <Text>
              {account?.address?.zipCode}, {account?.address?.city}
            </Text>
            <Text>{account?.address?.country}</Text>
            <Text>{account?.address?.state}</Text>
            <Text>{account?.mobileNumber}</Text>
            <Text>{account?.isVerified}</Text>
          </Box>
          <Box float="right" position="relative">
            <Button>Edit</Button>
          </Box>
        </Flex>
      </TableContentContainer>
    </TableContainer>
  );
};

export default Registration;
