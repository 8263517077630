import React from 'react';
import { Box, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import IconNews from 'src/assets/images/content/newsicon_development_big.png';
import NewsTitleBG from 'src/assets/images/content/newsheadline_background.gif';
import { useQuery } from 'react-query';
import { getNews } from 'src/services/newsService/NewsService';
import { NewsType } from 'src/utils/models/news.model';
import moment from 'moment-mini';
import DOMPurify from 'dompurify';

const News: React.FC = () => {
  const newsResult = useQuery({
    queryFn: () => getNews({ type: NewsType.NEWS, itemsPerPage: 5 }),
    queryKey: ['news'],
    keepPreviousData: true,
    cacheTime: 0,
    refetchOnWindowFocus: false
  });

  const news = newsResult.data?.data;

  return (
    <>
      {news?.map(newsItem => (
        <Box key={newsItem.id}>
          <Box className="NewsHeadline">
            <Box
              bgImage={NewsTitleBG}
              position="relative"
              minHeight="26px"
              marginBottom="5px"
              backgroundRepeat="repeat"
              backgroundPosition="2px"
              border="1px solid #000"
            >
              <Box>
                <Box
                  bgImage={IconNews}
                  width={8}
                  height={8}
                  position="absolute"
                  top="-2px"
                  left="0"
                  marginLeft="5px"
                  marginRight="5px"
                  float="left"
                />
                <Text
                  className="NewsHeadlineDate"
                  fontSize="7pt"
                  marginTop="8px"
                  marginLeft="40px"
                  marginRight="4px"
                  width="fit-content"
                  color="#fff"
                  float="left"
                  as="span"
                >
                  {moment.unix(newsItem.date).format('MMM DD YYYY')} -
                </Text>
              </Box>
              <Box
                className="NewsHeadlineText"
                marginLeft="7px"
                marginBottom="2px"
                lineHeight="26px"
                fontSize="10pt"
                fontWeight="700"
                color="#fff"
              >
                <Text as="span">{newsItem.title}</Text>
              </Box>
            </Box>
          </Box>
          <Table className="NewsTable" cellPadding="0" cellSpacing="0" width="100%" border="0">
            <Tbody>
              <Tr>
                <Td className="NewsTableContainer" paddingX="10px">
                  <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsItem.body) }} />
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      ))}
    </>
  );
};

export default News;
