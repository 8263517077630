import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useDependencies } from '../contexts/di/DependenciesContext';
import AccountProvider from '../contexts/AccountContext';
import RootLayout from '../../components/Layout';

type PrivateRouteProps = { children: ReactElement };
export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { useAuth } = useDependencies();
  const auth = useAuth();
  return auth.isUserAuthenticated() ? children : <Navigate to="/account/login" />;
};

export const PrivateLayout = () => {
  return (
    <RootLayout>
      <AccountProvider>
        <Outlet />
      </AccountProvider>
    </RootLayout>
  );
};
