import { Grid } from '@chakra-ui/react';
import React from 'react';
import PremiumBox from './PremiumBox';
import PollBox from './PollBox';

const MainBoxes = () => {
  return (
    <Grid
      gridArea="sidebar"
      position="relative"
      display="flex"
      flexDirection="column"
      height="100%"
      width="180px"
      className="main-themeboxes"
      fontSize="10pt"
      top={0}
      marginBottom={10}
      marginX="auto"
    >
      <PremiumBox />
      <PollBox />
    </Grid>
  );
};

export default MainBoxes;
