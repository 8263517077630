import React from 'react';
import { Box } from '@chakra-ui/layout';
import BoxTop from 'src/assets/images/general/box-top.gif';
import chain from 'src/assets/images/general/chain.gif';
import loginBox from 'src/assets/images/loginbox/loginbox-textfield-background.gif';
import BoxBottom from 'src/assets/images/general/box-bottom.gif';
import DownloadButton from 'src/assets/images/buttons/mediumbutton_download.png';
import { useNavigate } from 'react-router';
import Button from '../BigButton';

const DownloadBox = () => {
  const navigate = useNavigate();
  return (
    <Box
      className="SmallMenuBox"
      id="DownloadBox"
      position="relative"
      left="5px"
      top="4px"
      width="100%"
      marginBottom="38px"
      backgroundRepeat="no-repeat"
    >
      <Box
        className="SmallBoxTop"
        bgImage={BoxTop}
        position="absolute"
        left="-5px"
        top="-12px"
        height="12px"
        width="180px"
        backgroundRepeat="no-repeat"
      />
      <Box
        className="SmallBoxBorder"
        bgImage={chain}
        position="absolute"
        top="0"
        height="39px"
        width="8px"
        backgroundRepeat="repeat-y"
        zIndex="10"
      />
      <Box
        className="SmallBoxButtonContainer"
        bgImage={loginBox}
        position="relative"
        height="39px"
        width="160px"
        left="5px"
        backgroundRepeat="repeat-y"
        zIndex="1"
      >
        <Box
          id="PlayNowContainer"
          position="absolute"
          width="150px"
          height="37px"
          top="1px"
          left="5px"
          zIndex="99"
        >
          <Button src={DownloadButton} onClick={() => navigate('/downloads/agreement')} />
        </Box>
      </Box>
      <Box
        className="SmallBoxBorder BorderRight"
        bgImage={chain}
        position="absolute"
        top="0"
        height="39px"
        width="8px"
        backgroundRepeat="repeat-y"
        zIndex="10"
        right="9px"
      />
      <Box
        className="Loginstatus SmallBoxBottom"
        bgImage={BoxBottom}
        position="absolute"
        top="39px"
        left="-5px"
        height="12px"
        width="180px"
        backgroundRepeat="no-repeat"
      />
    </Box>
  );
};

export default DownloadBox;
