/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-quickstart.gif';

const GuideStart = () => {
  return (
    <Content headline={Title}>
      <Text>
        This is a short introduction to Tibia. It will provide you with general instructions on how
        to set up the game and to start playing. If you have a specific question, or if you would
        like to get detailed instructions, please see the <Link href="/guides/manual">manual</Link>{' '}
        for reference.
        <br />
        <br />
        <br />
        <b>
          <h3>1 How to Start</h3>
        </b>
        To play Tibia you need to create an account first. Simply click on &lsquo;Create
        Account&lsquo; either on the <Link href="/account/create">website</Link> or directly in the{' '}
        <Link href="/downloads/agreement">website</Link>Tibia client after you have downloaded it
        and follow the instructions. Make sure to select a safe password which cannot be guessed
        easily. Select your character&lsquo;s name, gender and the game world you want to play on.
        <br />
        Please make sure that you choose a name for your character that does not violate the{' '}
        <Link href="/support/rules">Tibia Rules</Link>. Of course, you have to follow this code of
        conduct while playing and using our website at any time. Otherwise, you may be excluded from
        our game service.
        <br />
        <br />
        If you haven&lsquo;t done yet, you need to <Link href="/downloads/agreement">
          download
        </Link>{' '}
        the Tibia client and install it on your computer.
        <br />
        Now you are ready to start playing. Go to your windows desktop and double-click on the Tibia
        icon to start the game client. Once you have downloaded the Tibia client with the Tibia
        launcher, you will be taken to the title screen. There simply enter your account data and
        your character list will be displayed. Double-click on the name of the character you have
        created. The client will now connect to the game server and your adventure can begin.
        <br />
        <br />
        Please note that if too many players are online on the selected game world, you will have to
        wait a while before you can log in. The client will show your current position in the login
        queue and automatically try to log in your character after the displayed waiting period.
        <br />
        <br />
        Please make sure to <Link href="/guides/manual#recoverykey">confirm your account</Link> so
        you can use all features of a regular Tibia free account. To do so click on the link in the
        email you have received after creating your account. For security reasons, you should also{' '}
        <Link href="/guides/manual#registration">register</Link> your account.
        <br />
        <br />
        <h3>2 Screen Layout</h3>
        Once you are logged into the game, you will see the adventure screen:
        <br />
        <br />
        <center>
          <Image src="https://static.tibia.com/images/gameguides/startscreen.jpg" />
        </center>
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_1.gif" />
          <b>Game Window</b>
        </div>
        The game window is where most of the action is taking place. It shows a part of the world of
        Tibia, with your own character in the middle.
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_2.gif" />
          <b>Minimap</b>
        </div>
        Much like a radar screen, the <Link href="/guides/manual#minimap">minimap</Link> will show
        you an overview of your surroundings. In the beginning a large part of this map will be dark
        since you need to explore the area first. The map buttons to the right will allow you to
        scroll around on the minimap.
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_3.gif" />
          <b>Status Bars</b>
        </div>
        The status bars show your current hit points (red) and magic points (blue). Note that in the
        beginning you will have no magic points (mana) at all.
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_4.gif" />
          <b>Inventory</b>
        </div>
        Right below these bars you can see your{' '}
        <Link href="/guides/manual#inventory">inventory</Link>. It is divided into several slots for
        different body parts. Please note that you can put items only in the slot they are designed
        for, i.e. you can place a shield in your character&lsquo;s hand, but not on his head. The
        top right slot is reserved for containers such as bags and backpacks.
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_5.gif" />
          <b>Combat Controls</b>
        </div>
        The <Link href="/guides/manual#combatcontrol">combat control</Link> determine your
        character&lsquo;s behaviour in battle.
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_6.gif" />
          <b>Buttons</b>
        </div>
        Several further buttons are arranged next to or below the inventory screen. Selecting
        &lsquo;Options&lsquo; will open up a menu, most of the others will open up sub-windows.
        Clicking on &lsquo;Stop&lsquo; will cause your character to stop any action he is currently
        taking, while the button &lsquo;Logout&lsquo; will end the game.
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_7.gif" />
          <b>Console</b>
        </div>
        At the bottom of the screen you will find the console through which you can talk to other
        players. Communication is organised in different chat channels which can be activated by
        clicking on the little button with the file card in the top right corner of the console.
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_8.gif" />
          <b>Cooldown Bar</b>
        </div>
        In the cooldown bar you can see how much time you still have to wait until a spell or a
        spell of a certain spell group is available again. You can hide the cooldown bar in the
        Options menu under General.
        <div className="Bulletpoint">
          <Image src="https://static.tibia.com/images/gameguides/bullet_9.gif" />
          <b>Action Bar</b>
        </div>
        Action bars can be used to assign objects, spells, text or hotkeys to different action
        buttons. To assign objects, simply drag &amp; drop them with your mouse onto one of the
        action buttons. You can also drag spells from your spell list onto an action button. To
        assign texts and hotkeys or to edit already assigned buttons, just right-click on the action
        button and choose the desired option. In the Options menu Action Bars, you have several
        options to adjust your action bars.
        <h3>3 First Steps</h3>
        <b>Moving Your Character</b>
        <br />
        You will start your adventure with a tutorial in a mysterious cave. Players who create a new
        character can skip this tutorial. Simply leave the box &lsquo;Do you want to play the
        tutorial?&lsquo; in the character creation section unchecked.
        <br />
        Use the mousepointer to find the next space you should{' '}
        <Link href="/guides/manual#move">move</Link> your character to. The mousepointer will turn
        there from a prohibitory sign into some walking legs.
        <br />
        Once you have mastered the tutorial, you will of course be able to wander around freely
        either by clicking at some place at the game window, using the arrow keys or by
        left-clicking on your minimap. Some NPCs will mark important points on your minimap if you
        ask them to. However, you can also add own marks to the minimap, e.g. to remember the
        entrance to your favourite dungeon, to find a special NPC or to mark the way to your own
        house.
        <br />
        <br />
        <b>Drag and Drop</b>
        <br />
        Next you find a shiny dagger which is certainly a great thing to have in a place like this.{' '}
        <Link href="/guides/manual#drag">drag</Link> the dagger to your inventory by holding the
        left mouse button down when moving the object. Then let the mouse button go to drop the
        dagger into your inventory&lsquo;s hand slot.
        <br />
        <br />
        <b>Your First Kill, Experience Points and Skills</b>
        <br />
        On your way, you will next find a wounded cave draptor. Although, young adventurers are
        better advised to stay away from draptors, this one is already nearly dead. To finish off
        this draptor, right-click on it to <Link href="/guides/manual#attack">attack</Link> it.
        Alternatively, you can left-click on it in the battle list. Although, the draptor will not
        simply surrender and fights back, some well-aimed blows with your dagger should be enough to
        send it to its ancestors. Congratulations, you have just gained your first{' '}
        <Link href="/guides/manual#experience">experience points</Link> and reached level 2!
        Usually, experience points are obtained by fighting aggressive monsters, but there are also
        certain quests which yield experience points as a reward. If you have gathered enough
        experience points, your character will advance in level, which will make it faster and
        stronger in a number of ways.
        <br />
        In addition to his experience your character also has{' '}
        <Link href="/guides/manual#skills">skills</Link> that determine how well your character can
        fight, fish etc. You will improve these skills simply by using or training them. To check
        out your current experience level and your skills click on the &lsquo;Skills&lsquo; button
        beneath your inventory or press the key combination Ctrl+S.
        <br />
        <br />
        <b>Looting Monsters</b>
        <br />
        Once you have killed the draptor, you should left-click on its corpse. With a bit of luck
        you will find some money in it. Open your bag by right-clicking on it. Now left-click on the
        draptor&lsquo;s loot and drag it into the bag.
        <br />
        Please note if more than one character helped killing a creature, only the one that made the
        most damage will be able to loot in the first 10 seconds after the kill. During this time it
        is also impossible to move the corpse. If a character has joined a{' '}
        <Link href="/guides/manual#party">party</Link>, every member of this party will be able to
        loot the dead creature. However, if the character, no matter if in a a party or not, that
        has dealt the most damage to a monster has only 14 or less hours of{' '}
        <Link href="/guides/manual#stamina">stamina</Link> left, the defeated monster will not
        contain any loot.
        <br />
        <br />
        <b>Talking to Inhabitants</b>
        <br />
        But of course, there are not only aggressive creatures in Tibia. Many inhabitants are
        peaceful just like Menesto. You can easily recognise them by the little speech bubble which
        of course also indicates that you can talk to these folks. Left-click on Menesto to start
        your conversation with &lsquo;Hi&lsquo;. An{' '}
        <Link href="/guides/manual#npcchannel">NPC Channel</Link> will open up in which Menesto will
        talk to you. NPCs in Tibia react to keywords. Simply answer their questions or use the light
        blue marked words to make conversation.
        <br />
        <br />
        <b>Eating</b>
        <br />
        Menesto tells you that he had lost the dagger earlier fighting the draptor. In exchange for
        his dagger, he gives you some food. Your stomach is already rumbling. So left-click on this
        delicious piece of meat to consume it. Eating is essential in Tibia as it is the easiest way
        to refill your hit and mana points. There is plenty of food in Tibia. You can loot it from
        monsters, buy it in shops, or harvest it from fields and bushes. You can even use certain
        ingredients to bake bread, cake or cook special meals.
        <br />
        <br />
        <b>Climbing Ladders and Using Ropes</b>
        <br />
        Once you are done eating, you should continue your conversation with Menesto. He will equip
        you with a rope and a torch and send you off. Your next aim is a ladder. Of course, nobody
        wants to stay in such a dark cave forever. So you should take the opportunity and climb up
        the ladder by left-clicking on the lower-right end of the ladder. Although, the ladder got
        you a level further up, you are still in the cave. There is a little round shadow on the
        floor which indicates that there must be a hole above it. Left click on your rope, then
        left-click on the shadow to pull yourself up a further level. Congratulations you have found
        the way out of the cave and mastered the tutorial.
        <br />
        <br />
        <h3>4 Mastering Dawnport</h3>
        Now that you have mastered the tutorial, your character can walk freely. Simply left-click
        on the spot in the game window you want your character to go to or use the arrow keys.
        Although, at first, you should of course climb up the ladder to enter the outpost Dawnport.
        Talk to Inigo first to learn more about this little island and your first steps as an
        adventurer.
        <br />
        <br />
        <Flex gap={4}>
          <Image
            src="https://static.tibia.com/images/gameguides/contextmenu.gif"
            className="ContentImageLeftFloat"
          />

          <Box>
            <b>Context Menu</b>
            <br />
            Players can switch between different mouse controls. Particularly players who use the
            stand-alone client, cannot use the handy &lsquo;left smartclick&lsquo; control. For
            those players, Tibia works mainly via a context menu.If you right-click on an item, a
            context menu will open up where you can read various commands. Depending on the
            characteristics of an item, the context menu will vary, e.g. you can &lsquo;Open&lsquo;
            boxes, &lsquo;Use&lsquo; stairs, &lsquo;Rotate&lsquo; a chair, or &lsquo;Look&lsquo; to
            read what is written on a sign.
            <br />A context menu will also open up if you click on a character or on an NPC.
            Right-click on Inigo and a context menu will open up in which you can select several
            options such as look, use, follow and even attack.
          </Box>
        </Flex>
        <br />
        <br />
        <b>Vocation</b>
        <br />
        There are four exciting <Link href="/guides/manual#vocations">vocations</Link> to choose
        from: Be a valorous knight, a swift-footed paladin, a powerful druid or a mighty sorcerer!
        On Dawnport you have the opportunity to try them all out. To get a vocation simply walk
        trough one of the 4 exits. They are marked by large statues embodying a representative of
        the vocation you will get there. You can change your vocation on Dawnport as often as you
        like.
        <br />
        Whenever you pass one of the gates, you will get a fitting equipment, outfit, skills and
        spells, for the selected vocation. Note that potions and runes are only given to you the
        first time you enter the gate. Also, once you leave Dawnport, you will lose all equipment
        you receive when passing through one of the gates.
        <br />
        <br />
        <b>Quests</b>
        <br />
        Look for Mr Morris in Dawnport to send you on your very first quest. Quests or missions are
        jobs or tasks that inhabitants of Tibia have for you. Of course, Mr Morris will send you on
        rather easy and basic quests. If your character gets stronger, also the quests will be more
        difficult and challenging.
        <br />
        You should really take the time to talk to the inhabitants of Tibia. You will learn a lot
        about the fascinating world you are playing in that way. Simply greet them and ask them
        about general things such as their <i>job</i> or their <i>name</i>. With a bit of luck, they
        will also have some job for you - simply ask them for a <i>mission</i> or a <i>quest</i>. If
        you complete their <Link href="/guides/manual#quests">quest</Link>, they will reward you
        with useful items, sometimes experience points, or with valuable information.
        <br />
        <br />
        <Flex gap={4}>
          <Image
            src="https://static.tibia.com/images/gameguides/npctrade.jpg"
            className="ContentImageLeftFloat"
          />
          <Box>
            <b>Trading with NPCs</b>
            <br />
            Once you have killed your first trolls and gathered at least 10 gold coins, you should
            buy yourself a shovel to explore the mysterious stone piles. Go to Richard, left click
            on him to greet him. Alternatively, you can also type &lsquo;Hi&lsquo; or
            &lsquo;Hello&lsquo; in your console and hit the &lsquo;Enter&lsquo; key. Now ask him for
            a trade. Select the &lsquo;shovel&lsquo; from the &lsquo;Buy&lsquo; tab and click on
            &lsquo;Buy&lsquo; to confirm the deal. Note that you will not be able to select an item
            for which you do not have enough money or capacity.
            <br />
            <br />
            <b>Death</b>
            <br />
            Don&lsquo;t be too frightened to go out and to explore! Remember the worst thing that
            can happen to you is <Link href="/guides/manual#death">death</Link> - and death,
            thankfully, is not the final matter in Tibia as it is in real life. If your character
            gets killed, it will reappear in the local temple safe and sound. However, there is a
            penalty for dying - your character will lose experience and skills. As soon as your
            character reaches level 8, there is a chance that you lose some items upon death as
            well.
          </Box>
        </Flex>
        <br />
        <br />
        <div className="Bulletpoint">
          <b>Player Killing and Adventurer&lsquo;s Blessing</b>{' '}
          <Image src="https://static.tibia.com/images/gameguides/skull_white.gif" />
        </div>
        <br />
        Once you have reached level 8, it is also possible to take part in PvP fights. Unless you
        are playing on an optional PvP game world other players can attack and even{' '}
        <Link href="/guides/manual#kills">kills</Link> your character now! It is a good idea to stay
        out of trouble, so you should be friendly and avoid insults or provocations. If you see a
        character who is marked with a little skull mark underneath his name, you should be careful
        because that character has recently been involved in violence. If you should run into
        trouble, remember <Link href="/guides/manual#protectionzone">protection zone</Link> such as
        temples or depots will give you shelter until the storm has cleared.
        <br />
        Further, characters on open PvP game worlds up to level 20 are automatically protected by
        the Adventurer&lsquo;s Blessing. You can easily see if your character is protected by this
        blessing by the little anchor shown in your inventory. Further, the inventory will be
        displayed in yellow.
        <br />
        If your character dies in a PvP fight being protected by the Adventurer&lsquo;s blessing,
        your character will not lose any items, experience and skill points. But beware, as soon as
        your character attacks another character first or reaches level 21 for the first time, the
        blessing will be lost for good.
        <br />
        <br />
        <b>Stamina</b>
        <br />
        The counter for your stamina indicates the time that is left until a character is too tired
        to gain experience while hunting. Characters can hunt for up to 42 hours before they no
        longer gain experience points. In the first three hours, so until you reach hour 39, Premium
        players will gain 50% more experience points. However, during the final 14 hours defeated
        creatures will only yield half the experience. Moreover, if the character that has dealt the
        most damage to a monster has only 14 or less hours of stamina left, the defeated monster
        will not contain any loot.
        <br />
        Stamina regenerates while characters are logged out. For every 3 minutes you are logged off,
        your character will gain 1 minute added to its stamina up to hour 39. For the last three
        hours, your character needs to be logged off for 6 minutes to regain 1 minute of its
        stamina.
        <br />
        <br />
        <b>Sending Messages to Other Players</b>
        <br />
        Of course, you can also ask other players around you for advise or information. Maybe you
        just want to start a friendly conversation or ask someone to hunt with you together. Also,
        you do not have to buy from Richard or from other inhabitants of Dawnport. You will find
        there are many other players around, and surely some of them will be happy to trade with
        you! To <Link href="/guides/manual#talk">talk</Link> to other nearby players enter any text
        in the local chat channel and hit the enter key. You can also message players directly by
        right-clicking onto them and selecting &lsquo;Message to&lsquo; and the name of the
        character. This will open a channel where you can talk to this player in private.
        <br />
        <br />
        <Flex gap={4}>
          <Box>
            <b>Trading with Other Players</b>
            <br />
            If another player agrees to trade with you, you can do so by using a special{' '}
            <Link href="/guides/manual#trademode">trade mode</Link>. Simply place your character
            next to the player you want to trade with. Right-click on the item you would like to
            trade and select &lsquo;Trade with ...&lsquo;. The cursor will turn into crosshairs. Now
            all you need to do is to click on your trade partner. Your partner will now see the
            offer, and if he agrees to trade, he will offer another item in exchange. If you are
            satisfied with his offer, click on &lsquo;Accept&lsquo; to complete the deal. If you
            want to trade several items at once, put them into a container and offer it to your
            trade partner.
          </Box>
          <Image
            src="https://static.tibia.com/images/gameguides/safetrade.jpg"
            className="ContentImageRightFloat"
          />
        </Flex>
        <br />
        <br />
        <div className="Bulletpoint">
          <b>Logout Block</b>
          <Image src="https://static.tibia.com/images/gameguides/condition_logoutblock.gif" />
        </div>
        <br />
        Please note that you should always use the logout button to quit Tibia. If you are not
        allowed to log out immediately, it is likely that you have recently been involved in
        fighting. The so-called <Link href="/guides/manual#logoutblock">logout block</Link> ensures
        that you cannot escape from a challenge in an unfair way. Remember if you simply shut down
        your client, your character may stay in the game even if your computer has been switched
        off. This can be very dangerous so always log out properly in a safe place.
        <br />
        <br />
        <b>Getting Help</b>
        <br />
        If you have questions concerning the client controls, general game play, the use of accounts
        or the official website, there are many different ways to{' '}
        <Link href="/guides/manual#gethelp">get help</Link>
        . For example, tutorial hints will supply you with useful tips as you go along. This means
        that either a new window will pop up explaining the handling of a certain function, or a
        certain part in the adventure screen will be marked. Also, you can activate the client help
        by hitting the key combination Ctrl+H. The client help will give you detailed information
        about the parts of the client and special items in the game window.
        <br />
        If you have specific questions, you should have a look at the{' '}
        <Link href="/support/gethelp">FAQ</Link> and the <Link href="/guides/manual">manual</Link>{' '}
        on our website. If you do not find your answer here, you can use the help channel in the
        game. Simply press the key combination Ctrl+T and ask your question. Our experienced players
        will be happy to help you.
        <br />
        Finally, if you find out that the <Link href="/support/rules">Tibia Rules</Link> have been
        violated, you can make a report. All players have the possibility to report other characters
        that use unofficial software such as bots, illegal character names, and illegal statements
        directly to customer support. To do so, right-click on the character or the statement and
        select the corresponding reporting reason, or click on the corresponding button on the
        character page or the forum if you like to report an illegal name, post, signature or
        comment from the Tibia website.
        <br />
        <br />
        <b>Selecting Your Definite Vocation and Leaving Dawnport</b>
        <br />
        You can leave Dawnport anytime between level 8 and level 20. Note, however, that your Tibia
        account must be confirmed. Once you have decided you are ready to leave for the main
        continent, you also need to select your definite vocation. You should consider your
        character&lsquo;s vocation well because once you have made your choice it cannot be
        reversed. If you need more information about vocations, talk to Oressa downstairs.
        <br />
        Once you have decided for a vocation for good, you need to talk to Oressa and tell her about
        your decision. Once she has bestowed you the vocation of your choice, you can enter the
        corresponding room. Note that once you have entered the room, there is no way turning back
        and you have to stick to the vocation you have chosen. In each room, you will find a box
        with new equipment just fitting for your vocation.
        <br />
        Once you have equipped yourself, there is only one way out which leads you directly to the
        ship. Captain Dreadnought will happily sail you to the destination of your choice. However,
        the city needs a publicly accessible port, so it is impossible to travel to Kazordoon or
        Svargrond. If you have not made up your mind in which city you want to start off, Captain
        Dreadnought is also able to provide you with some basic information about every Tibian city.
        <br />
        Please note that there is a limit for certain items you can take from Dawnport to the main
        continent:
        <ul>
          <li>
            <b>Characters up to level 8:</b> can have a maximum of 1000 gold in their bank account,
            500 gold in their backpack, and no more than 20 pieces of a certain looted item, e.g.
            max. 20 insectoid eggs + 20 pieces of swampling wood etc.
          </li>
          <li>
            <b>Characters level 9-20:</b> can have a maximum of 1500 gold in their bank account, 500
            gold in their backpack, and no more than 20 pieces of a certain looted item, e.g. max.
            20 insectoid eggs + 20 pieces of swampling wood etc.
          </li>
          <li>
            {' '}
            Also runes, any sort of mana and health potions and the equipment you get when passing
            one of the gates will be deleted before your departure to the main continent.
          </li>
        </ul>
        Further, on Dawnport, the skill points for a character&lsquo;s magic level are limited to
        the amount which equals magic level 20 for druids and sorcerers.
        <br />
        <br />
        <b>Rookgaard</b>
        <br />
        Players who already have at least one character on the main continent, can choose whether
        they play Dawnport or the old beginner&lsquo;s island Rookgaard. Simply talk to Inigo about
        Rookgaard if you prefer to play there. Note that your character may not be level 10 or
        higher if he wants to go to Rookgaard and that it is impossible to return to Dawnport from
        Rookgaard.
        <br />
        <br />
        <h3>5 The Main Continent</h3>
        <b>New Features on the Main Continent</b>
        <br />
        Once you have arrived in the port of your new hometown, you should talk to the city guide
        waiting there for all newly arrived adventurers. He has interesting information about the
        town, and he also can mark places of interest on your character&lsquo;s minimap. If your
        character is below level 10, he will even escort you to the depot.
        <br />
        General game play on the main continent of Tibia is similar to Dawnport. You can explore
        secret places, do quests, and, of course, you can level up your character. However, there
        are also many new exciting features to discover, such as the city depots where you can store
        your items, the Royal Tibian Mail which will allow you to send parcels and letters around
        the continent, and, of course, powerful <Link href="/guides/manual#magic">magic</Link>! At
        last your characters will be able to spend their mana on magical spells that will help them
        in many ways.
        <br />
        And there is more! You can give your characters a family and even a home of their own. Join
        up with your friends in a <Link href="/guides/manual#guild">guild</Link> - as a Premium
        player you can even found one of your own. Also, if you have a Premium account, you can rent
        one of the lovely <Link href="/guides/manual#houses">houses</Link> that are available in the
        various cities on Tibia.
        <br />
        <br />
        <b>Making the Best of Your Vocation</b>
        <br />
        Try to make the best of your character. Keep in mind that every{' '}
        <Link href="/guides/manual#vocations">vocations</Link> has individual strengths and
        weaknesses. If you choose to be a sorcerer or a druid, your first step should be a visit to
        the local magic shop in your new home city. You should start buying more spells as soon as
        possible. Keep in mind, however, that they are not always sold in magic shops. In some
        cities you will have to take a good look around to find your local spell dealer. Knights and
        paladins should also buy spells early on, for they can use some magic, too. However,
        adequate equipment and good skills are more important for these vocations, so perhaps it is
        a better idea to spend your money on arms and armour and look for some easy{' '}
        <Link href="/guides/manual#creatures">monsters</Link> on which you can improve your skills.
        <br />
        Whatever you do, you should always try to make the best of your character&lsquo;s vocation.
        There are enemies on the main continent the like of which you have never seen on Dawnport,
        so you better make sure your character will be strong enough to face the challenge!
        <br />
        <br />
        <b>Exploring the Main Continent</b>
        <br />
        It is a good idea to stay close to the safety of your home{' '}
        <Link href="/guides/manual#cities">cities</Link> for a while, but you should not do so
        forever. Remember there is a whole continent out there for you to explore and to conquer!
        From the troll-ridden marshes of Venore to the lurking horrors of the Ghostlands, from the
        awe-inspiring orc fortress at Ulderek&lsquo;s Rock to the fierce dragon lords in the
        dreadful dungeons of Fibula, Tibia is a thrilling world filled with mystery and adventure.
        Are you ready to take on the challenge of Tibia?
        <br />
        <br />
      </Text>
    </Content>
  );
};

export default GuideStart;
