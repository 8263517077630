import { Vocations } from './models/player.model';

export function getVocationName(id: number): string {
  switch (id) {
    case Vocations.NONE:
      return 'None';
    case Vocations.SORCERER:
      return 'Sorcerer';
    case Vocations.DRUID:
      return 'Druid';
    case Vocations.KNIGHT:
      return 'Knight';
    case Vocations.PALADIN:
      return 'Paladin';
    case Vocations.MASTER_SORCERER:
      return 'Master Sorcerer';
    case Vocations.ELDER_DRUID:
      return 'Elder Druid';
    case Vocations.ROYAL_PALADIN:
      return 'Royal Paladin';
    case Vocations.ELITE_KNIGHT:
      return 'Elite Knight';
    default:
      return 'None';
  }
}
