import React from 'react';
import { Box } from '@chakra-ui/react';

interface InnerTableContainerProps {
  children: React.ReactNode;
}

const InnerTableContainer = ({ children }: InnerTableContainerProps) => {
  return (
    <Box width="100%" position="relative" padding={2}>
      {children}
    </Box>
  );
};

export default InnerTableContainer;
