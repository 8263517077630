import { Box, Flex, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { useAccountContext } from 'src/utils/contexts/AccountContext';
import TableContainer from 'src/components/TableContainer/TableContainer';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import FreeAccountStatus from 'src/assets/images/account/account-status_red.gif';
import PremiumAccountStatus from 'src/assets/images/account/account-status_green.gif';
import PremiumIconTravel from 'src/assets/images/premiumfeatures/PremiumIcon-Travel.png';
import PremiumIconMount from 'src/assets/images/premiumfeatures/PremiumIcon-Mount.png';
import PremiumIconCharms from 'src/assets/images/premiumfeatures/PremiumIcon-Charms.png';
import Button from 'src/components/Button';
import moment from 'moment-mini';
import { useNavigate } from 'react-router';

const AccountStatus = () => {
  const navigate = useNavigate();
  const { account } = useAccountContext();
  const isPremiumAccount = Number(account?.premdays) > 0;
  return (
    <TableContainer title="Account Status" marginBottom="16px">
      <TableContentContainer marginBottom={2}>
        <Table>
          <Tbody>
            <Tr>
              <Td padding="2px 5px" border="none">
                <Box
                  bgImage={isPremiumAccount ? PremiumAccountStatus : FreeAccountStatus}
                  boxSize="52px"
                  objectFit="cover"
                />
              </Td>
              <Td
                width="100%"
                valign="middle"
                padding="2px 5px"
                verticalAlign="middle"
                border="none"
              >
                <Text
                  as="span"
                  color={isPremiumAccount ? 'green' : '#ef0000'}
                  fontWeight={700}
                  fontSize="14pt"
                >
                  {isPremiumAccount ? 'Premium Account' : 'Free Account'}
                </Text>
                <br />
                <Text fontSize="smaller">
                  Your Premium Time expired at{' '}
                  {moment().add(account?.premdays, 'days').format('MMM MM YYYY')}
                  <br />
                  (Balance of Premium Time: {account?.premdays} days)
                </Text>
              </Td>
              <Td padding="2px 5px" border="none">
                <Button marginBottom="5px" onClick={() => navigate('/account/management')}>
                  Manage Account
                </Button>
                <div />
                <Button variant="success" marginBottom="5px">
                  Get Premium
                </Button>
                <div />
                <Button variant="warning">Logout</Button>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContentContainer>
      <TableContentContainer>
        <Table>
          <Tbody>
            <Tr>
              <Td padding="2px 5px" display="flex" margin="0px auto">
                <Flex
                  alignItems="center"
                  flex="1"
                  justifyContent="flex-start"
                  padding={0}
                  height="32px"
                >
                  <Box
                    bgImage={PremiumIconTravel}
                    boxSize="32px"
                    objectFit="cover"
                    margin="0px 5px"
                  />
                  <Text as="span">use instant travel system</Text>
                </Flex>
                <Flex alignItems="center" flex="1" justifyContent="flex-start" height="32px">
                  <Box
                    bgImage={PremiumIconMount}
                    boxSize="32px"
                    objectFit="cover"
                    margin="0px 5px"
                  />
                  <Text as="span">tame mounts to travel faster </Text>
                </Flex>
                <Flex alignItems="center" flex="1" justifyContent="flex-start" height="32px">
                  <Box
                    bgImage={PremiumIconCharms}
                    boxSize="32px"
                    objectFit="cover"
                    margin="0px 5px"
                  />
                  <Text as="span">use up to 6 charms at the same time</Text>
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContentContainer>
    </TableContainer>
  );
};

export default AccountStatus;
