import React from 'react';
import { Box } from '@chakra-ui/layout';

import ButtonBG from 'src/assets/images/menu/button-background.gif';
import ButtonBGOver from 'src/assets/images/menu/button-background-over.gif';
import Minus from 'src/assets/images/general/minus.gif';
import Plus from 'src/assets/images/general/plus.gif';
import { MenuItemProps } from 'src/utils/interfaces/menu.interface';
import SubmenuItem from './SubmenuItem';

interface IMenuItemProps {
  menu: MenuItemProps;
  setOpenedMenu: (value: string) => void;
  isOpen: boolean;
}

const MenuItem: React.FC<IMenuItemProps> = ({ menu, setOpenedMenu, isOpen }) => {
  const handleMenuClick = () => setOpenedMenu(menu.id);

  return (
    <Box id={menu.id} className="menuitem" cursor="pointer" onClick={handleMenuClick}>
      <Box as="span">
        <Box
          bgImage={ButtonBG}
          position="relative"
          h="32px"
          w="170px"
          display="block"
          cursor="pointer"
          _hover={{
            bgImage: ButtonBGOver,
            position: 'relative',
            height: '32px',
            width: '170px',
            display: 'block'
          }}
        >
          <Box position="absolute" h="32px" w="32px" top="0" left="8px" bgImage={menu.icon} />
          <Box h="22px" w="116px" right="12px" top="6px" position="absolute" bgImage={menu.label} />
          <Box
            position="absolute"
            top="20px"
            right="-2px"
            h="12px"
            w="12px"
            backgroundRepeat="no-repeat"
            bgImage={isOpen ? Minus : Plus}
          />
        </Box>
      </Box>
      <Box
        position="relative"
        mx="5px"
        backgroundColor="#0d2e2b"
        w="160px"
        display={isOpen ? 'block' : 'none'}
      >
        {menu.subMenu
          .filter(submenu => !!submenu.enabled)
          .map(subMenu => (
            <SubmenuItem key={subMenu.id} label={subMenu.title} href={subMenu.href} />
          ))}
      </Box>
    </Box>
  );
};

export default MenuItem;
