/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseCookies, setCookie } from 'nookies';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment-mini';
import { Account, AccountRoles } from '../models/account.model';
import useIsMounted from '../hooks/useIsMounted';
import AuthService from '../../services/authService/AuthService';

export type AccountContextData = {
  account: Account | undefined;
  setAccount: (tenant: Account) => void;
};

export const AccountContext = React.createContext({} as AccountContextData);

const authService = AuthService.getInstance();
const activeKey = authService.TOKEN_KEY;

const AccountProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isMounted = useIsMounted();
  const [account, setAccount] = useState<Account | undefined>({
    id: 0,
    accessToken: '',
    name: '',
    email: '',
    premdays: 0,
    premdaysPurchased: 0,
    lastday: 0,
    type: AccountRoles.ACCOUNT_TYPE_NORMAL,
    coins: 0,
    coinsTransferable: 0,
    tournamentCoins: 0,
    creation: moment(),
    recruiter: 0,
    firstName: '',
    lastName: '',
    address: {
      city: '',
      complement: '',
      country: '',
      number: '',
      state: '',
      street: '',
      zipCode: ''
    },
    mobileNumber: '',
    isVerified: false
  });

  const fetchLoggedInUser = useCallback(() => {
    return authService
      .whoAmI()
      .then((account: Account) => {
        if (isMounted()) {
          const cookies = parseCookies();
          if (cookies.FAHREN_TOKEN) {
            setAccount({ ...account, accessToken: cookies.FAHREN_TOKEN });
          } else {
            setAccountInStorage(account);
          }
        }
      })
      .catch((err: any) => err);
  }, [isMounted]);

  useEffect(() => {
    fetchLoggedInUser();
  }, [fetchLoggedInUser]);

  const setAccountInStorage = (account: Account): void => {
    setAccount(account);
    setCookie(null, activeKey, JSON.stringify(setAccountInfo(account)), {
      maxAge: 60 * 60 * 2, // 2hr
      path: '/'
    });
  };

  const values = useMemo(() => ({ account, setAccount: setAccountInStorage }), [account]);

  return <AccountContext.Provider value={values}>{children}</AccountContext.Provider>;
};

export default AccountProvider;

export const useAccountContext = () => React.useContext(AccountContext);

export const setAccountInfo = (account: Account) => {
  return {
    ...account
  };
};
