import { Grid } from '@chakra-ui/react';

import MainBoxes from 'src/components/MainBoxes/MainBoxes';
import MainMenu from './MainMenu/MainMenu';
import MainHeader from './MainHeader/MainHeader';
import MainContent from './MainContent/MainContent';
import '../theme/global.scss';

const RootLayout = ({
  children
}: Readonly<{
  children: React.ReactNode;
}>) => (
  <Grid
    className="main-site-container"
    maxW="1280px"
    minW="1024px"
    margin="0 auto"
    gridTemplateColumns="205px auto 200px"
    gridTemplateAreas='"header header header"
          "navigation content sidebar"
          "navigation content sidebar"'
  >
    <MainHeader />
    <MainMenu />
    <MainContent>{children}</MainContent>
    <MainBoxes />
  </Grid>
);

export default RootLayout;
