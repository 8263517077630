/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  Image,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import Content from 'src/components/MainContent/Content';
import Title from 'src/assets/images/strings/headline-downloadclient.gif';
import DownloadIcon from 'src/assets/images/account/download_windows.gif';
import TableContainer from 'src/components/TableContainer/TableContainer';
import BackgroundPopOver from 'src/assets/images/content/scroll.gif';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';

const DownloadClient = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Content headline={Title}>
      <TableContainer title="Download Client" tablePadding="5px" mb={3}>
        <TableContentContainer marginBottom={3}>
          <Flex
            direction="column"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            width="100%"
            border="1px solid #faf0d7"
          >
            <Text as="h1" mb={3} textAlign="center">
              Official Tibia Client
            </Text>
            <Image src={DownloadIcon} boxSize="90px" mb={3} />
            <Link href="/downloads/ironman.zip" fontSize="12pt" lineHeight="1.2" mb={3}>
              Download Tibia
              <br />
              Windows Client
            </Link>
            <Popover
              returnFocusOnClose
              isOpen={isOpen}
              onClose={onClose}
              closeOnBlur
              closeOnEsc
              placement="right"
            >
              <PopoverTrigger>
                <Text mb={3}>
                  [
                  <Text
                    as="a"
                    onMouseOver={() => onToggle()}
                    onMouseOut={() => onToggle()}
                    cursor="help"
                    fontSize="x-small"
                    fontWeight={700}
                  >
                    system requeriment
                  </Text>
                  ]
                </Text>
              </PopoverTrigger>
              <PopoverContent bgImage={BackgroundPopOver}>
                <PopoverArrow />
                <PopoverBody>
                  <Text fontWeight={700} marginBottom="5px">
                    Password Requirements
                  </Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </TableContentContainer>
        <TableContentContainer>
          <Box padding={1} width="100%" border="1px solid #faf0d7">
            <Text mb={2} fontWeight={700}>
              Disclaimer
            </Text>
            <Text>
              The software and any related documentation is provided &quot;as is&quot; without
              warranty of any kind. The entire risk arising out of use of the software remains with
              you. In no event shall CipSoft GmbH be liable for any damages to your computer or loss
              of data.
            </Text>
          </Box>
        </TableContentContainer>
      </TableContainer>
    </Content>
  );
};

export default DownloadClient;
