import React from 'react';
import { Outlet } from 'react-router';
import RootLayout from '../../components/Layout';

export const PublicLayout = () => {
  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
};
