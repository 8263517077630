import {
  Box,
  Flex,
  Image,
  Input as InputChakra,
  InputProps as InputPropsChakra,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getNestedErrors } from 'src/utils/getNestedErrors';
import NOkIcon from 'src/assets/images/general/nok.gif';
import OkIcon from 'src/assets/images/general/ok.gif';
import { useDependencies } from 'src/utils/contexts/di/DependenciesContext';
import { IInputValidator } from 'src/utils/interfaces/validations.interface';
import ErrorMessage from '../ErrorMessage';

type InputTypes = 'text' | 'email' | 'number' | 'tel' | 'password';

interface IInputProps extends InputPropsChakra {
  name: string;
  useIcon?: boolean;
  validators?: IInputValidator;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  type?: InputTypes;
  required?: boolean;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  tooltip?: string;
  usePasswordStrength?: boolean;
}

const Input: React.FC<IInputProps> = ({
  name,
  useIcon = false,
  validators,
  defaultValue,
  label,
  placeholder,
  type = 'text',
  required = true,
  usePasswordStrength = false,
  maxLength,
  onChange,
  onBlur,
  tooltip,
  ...props
}) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext();

  const { usePasswordValidation } = useDependencies();

  const value = watch(name);

  const { passwordStrength } = usePasswordValidation(value?.toString() || '');

  const errorMessage: string = getNestedErrors(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={validators}
      render={({ field }) => (
        <Box width="100%">
          <Flex alignContent="center" alignItems="center" width="100%">
            <InputChakra
              {...field}
              type={type}
              label={label}
              id={name.split(' ').join('-')}
              placeholder={placeholder}
              required={required}
              error={errors}
              maxLength={maxLength}
              onChange={onChange ?? field.onChange}
              onBlur={onBlur ?? field.onBlur}
              textAlign="start"
              cursor="text"
              backgroundColor="field"
              padding="1px 4px"
              rounded="2px"
              height="21px"
              border="1px solid gray"
              fontSize="10pt"
              color="black"
              width="214px"
              marginRight="5px"
              {...props}
            />
            {useIcon && (
              <Image src={errorMessage || !watch(name) ? NOkIcon : OkIcon} w="12px" h="12px" />
            )}
          </Flex>
          {name === 'password' && usePasswordStrength && (
            <Box backgroundColor={passwordStrength?.color} border="1px solid grey" maxWidth="214px">
              <Text color="white" textAlign="center">
                {passwordStrength?.strength}
              </Text>
            </Box>
          )}
          {errorMessage && name !== 'password' ? (
            <ErrorMessage message={errorMessage} marginTop={errorMessage ? 1 : 0} />
          ) : (
            ''
          )}
        </Box>
      )}
    />
  );
};

export default Input;
