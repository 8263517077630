import { Flex, Image, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { useAccountContext } from 'src/utils/contexts/AccountContext';
import TableContainer from 'src/components/TableContainer/TableContainer';
import TableContentContainer from 'src/components/TableContainer/TableContentContainer';
import TibiaCoinTrusted from 'src/assets/images/account/icon-tibiacointrusted.png';
import TibiaCoin from 'src/assets/images/account/icon-tibiacoin.png';
import moment from 'moment-mini';

const GeneralInformation = () => {
  const { account } = useAccountContext();
  const isPremiumAccount = Number(account?.premdays) > 0;
  return (
    <TableContainer title="General Information" marginBottom="16px" id="general">
      <TableContentContainer>
        <Table>
          <Tbody>
            <Tr bgColor="#D4C0A1">
              <Td padding="2px 5px" border="1px solid #faf0d7">
                <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                  Email Address:
                </Text>
              </Td>
              <Td width="90%" valign="middle" padding="2px 5px" verticalAlign="middle">
                <Text fontSize="10pt">{account?.email}</Text>
              </Td>
            </Tr>
            <Tr bgColor="#F1E0C6">
              <Td padding="2px 5px" border="1px solid #faf0d7">
                <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                  Created:
                </Text>
              </Td>
              <Td width="90%" valign="middle" padding="2px 5px" verticalAlign="middle">
                <Text fontSize="10pt">
                  {moment(account?.creation)
                    .locale('America/Sao_Paulo')
                    .format('MMM DD YYYY, HH:mm:s')}{' '}
                  BRT
                </Text>
              </Td>
            </Tr>
            <Tr bgColor="#D4C0A1">
              <Td padding="2px 5px" border="1px solid #faf0d7">
                <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                  Last Login (Game):
                </Text>
              </Td>
              <Td width="90%" valign="middle" padding="2px 5px" verticalAlign="middle">
                <Text fontSize="10pt">{account?.email}</Text>
              </Td>
            </Tr>
            <Tr bgColor="#F1E0C6">
              <Td padding="2px 5px" border="1px solid #faf0d7">
                <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                  Last Login (Website):
                </Text>
              </Td>
              <Td width="90%" valign="middle" padding="2px 5px" verticalAlign="middle">
                <Text fontSize="10pt">{account?.email}</Text>
              </Td>
            </Tr>
            <Tr bgColor="#D4C0A1">
              <Td padding="2px 5px" border="1px solid #faf0d7">
                <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap" as="span">
                  Account Status:
                </Text>
              </Td>
              <Td width="90%" valign="middle" padding="2px 5px" verticalAlign="middle">
                <Text
                  fontSize="10pt"
                  color={isPremiumAccount ? 'green' : '#ef0000'}
                  fontWeight={700}
                >
                  {isPremiumAccount ? 'Premium Account' : 'Free Account'}
                </Text>
                <Text fontSize="smaller">
                  (Premium Time {isPremiumAccount ? 'will expire at' : 'expired at'}{' '}
                  {moment().add(account?.premdays, 'days').format('MMM MM YYYY')}, balance:{' '}
                  {account?.premdays} days)
                </Text>
              </Td>
            </Tr>
            <Tr bgColor="#F1E0C6">
              <Td padding="2px 5px" border="1px solid #faf0d7">
                <Text fontSize="10pt" fontWeight={700} whiteSpace="nowrap">
                  Tibia Coins:
                </Text>
              </Td>
              <Td width="90%" valign="middle" padding="2px 5px" verticalAlign="middle">
                <Flex>
                  <Text fontSize="10pt">{account?.coins}</Text>
                  <Image
                    src={TibiaCoin}
                    alt="coin"
                    boxSize="12px"
                    marginLeft="5px"
                    marginRight="5px"
                  />
                  (<Text fontSize="10pt">Including: {account?.coinsTransferable}</Text>
                  <Image src={TibiaCoinTrusted} alt="coin" boxSize="12px" marginLeft="5px" />)
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContentContainer>
    </TableContainer>
  );
};

export default GeneralInformation;
