import React from 'react';
import { Text, Box, Image, Flex } from '@chakra-ui/react';
import chain from 'src/assets/images/general/chain.gif';
import ActiveSubMenuIcon from 'src/assets/images/menu/icon-activesubmenu.gif';
import { useLocation, useNavigate } from 'react-router';

interface SubmenuItemProps {
  label: string;
  href: string;
}

const SubmenuItem: React.FC<SubmenuItemProps> = ({ label, href }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isSelected = location.pathname === href;

  const handleClick = () => navigate(href);

  return (
    <Box
      id={`submenu_${label}`}
      position="relative"
      color="white"
      bgColor="rgb(13, 46, 43)"
      borderBottom="1px solid #4b7874"
      _hover={{ bgColor: 'rgb(20, 67, 63)' }}
      onClick={handleClick}
      paddingX={1}
    >
      <Box
        bgImage={chain}
        position="absolute"
        top="0"
        left="-5px"
        h="40px"
        w="7px"
        bgRepeat="repeat-y"
      />
      <Flex alignContent="center" alignItems="center">
        {isSelected && (
          <Image position="absolute" left="3px" src={ActiveSubMenuIcon} boxSize="10px" />
        )}
        <Text
          lineHeight={5}
          pl="11px"
          color={isSelected ? 'white' : '#d7d7d7'}
          _hover={{ color: 'white' }}
        >
          {label}
        </Text>
      </Flex>
      <Box bgImage={chain} position="absolute" top="0" right="-4px" h="40px" w="7px" />
    </Box>
  );
};

export default SubmenuItem;
