import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import BoxFrameVertical from 'src/assets/images/content/box-frame-vertical.gif';
import TableHeadlineBorder from 'src/assets/images/content/table-headline-border.gif';
import BoxFrameEdge from 'src/assets/images/content/box-frame-edge.gif';

interface CaptionContainerProps {
  title: string;
}

const CaptionContainer = ({ title }: CaptionContainerProps) => {
  return (
    <Box
      className="CaptionContainer"
      position="relative"
      fontSize="1pt"
      backgroundColor="#5f4d41"
      height="100%"
      width="100%"
      textAlign="left"
    >
      <Box
        className="CaptionInnerContainer"
        position="relative"
        backgroundColor="#5f4d41"
        width="100%"
        height="100%"
        paddingTop="3px"
        paddingBottom="4px"
      >
        <Box
          className="CaptionEdgeLeftTop"
          bgImage={BoxFrameEdge}
          position="absolute"
          width="5px"
          height="5px"
          left="-2px"
          top="-2px"
          zIndex={50}
        />
        <Box
          className="CaptionEdgeRightTop"
          bgImage={BoxFrameEdge}
          position="absolute"
          width="5px"
          height="5px"
          top="-2px"
          right="-2px"
          zIndex={50}
        />
        <Box
          className="CaptionBorderTop"
          bgImage={TableHeadlineBorder}
          position="absolute"
          width="100%"
          height="4px"
          left="0"
          top="-1px"
        />
        <Box
          className="CaptionVerticalLeft"
          bgImage={BoxFrameVertical}
          position="absolute"
          height="100%"
          width="3px"
          left="-1px"
          top={0}
        />
        <Text
          className="Text"
          fontFamily="Verdana, Arial, Times New Roman, sans-serif"
          fontSize="10pt"
          fontWeight="700"
          textAlign="left"
          color="#fff"
          padding="3px 14px 2px"
          as="span"
        >
          {title}
        </Text>
        <Box
          className="CaptionVerticalRight"
          bgImage={BoxFrameVertical}
          position="absolute"
          right="-1px"
          top="0"
          width="3px"
          height="100%"
        />
        <Box
          className="CaptionBorderBottom"
          bgImage={TableHeadlineBorder}
          position="absolute"
          width="100%"
          height="4px"
          left="0"
          bottom="-2px"
        />
        <Box
          className="CaptionEdgeLeftBottom"
          bgImage={BoxFrameEdge}
          position="absolute"
          width="5px"
          height="5px"
          left="-2px"
          bottom="-3px"
          zIndex={50}
        />
        <Box
          className="CaptionEdgeRightBottom"
          bgImage={BoxFrameEdge}
          position="absolute"
          width="5px"
          height="5px"
          right="-2px"
          bottom="-3px"
          zIndex={50}
        />
      </Box>
    </Box>
  );
};

export default CaptionContainer;
